import styled from 'styled-components'
import {Box, Paper, Typography} from "@mui/material";
import {FlexContainer, FlexContainerRow} from "./Containers.styled";
import theme from './theme'

// Forum Threads List component

export const ThreadsListContainer = styled(Box)({
    marginTop: "50px",
    display: 'flex',
    // padding: '1rem',
    backgroundColor: theme.palette.common.white,
    justifyContent: 'space-around',
    flexDirection: 'column',
})

export const ThreadBox = styled(Box)(({ theme }) => ({

}))

export const ThreadsListBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
}))

export const ThreadListWrapper = styled(Box)({
    cursor: 'pointer',
    padding: '1rem',
    transition: 'background-color 0.5s ease', // Add a smooth transition effect
    '&:hover': {
        backgroundColor: theme.palette.common.lightgray,
    },
    '&.active': {
        backgroundColor: theme.palette.common.lightgray,
    }
})

export const ThreadBoxHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    marginTop: '0.5rem',
}))

export const ThreadBoxBottom = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem'
}))

export const ThreadListHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-evenly',
    gap: '10rem',
    alignItems: 'center',
    marginBottom: '0.5rem'
}))

export const TruncatedText = styled(Typography)({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

// Forum Thread component

export const ThreadContainer = styled(Box)({
    marginTop: "50px",
})

export const ThreadHeader = styled(Box)({
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    padding: '1rem'
})

export const ThreadPaper = styled(Paper)({
    backgroundColor: theme.palette.common.white,
    padding: '1rem',
    [theme.breakpoints.down('md')] : {
        padding: 0,
    },
    display: 'flex',
    flexDirection: 'column',
})

export const ForumFlexContainer = styled(Box)({
    display: 'flex',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        height: '100%',
    },
    gap: '0rem',
    height: '100vh',
    overflow: 'hidden',
})

export const CommentContainer = styled(FlexContainer)({
    padding: '1rem',
    margin: '1rem',
    border: `1px solid ${theme.palette.common.lightgray}`,
    borderRadius: '5px',
    backgroundColor: theme.palette.common.white,
})

export const ThreadStarterCommentContainer = styled(CommentContainer)({
    border: `1px solid ${theme.palette.secondary.main}`,
})

export const AuthorBox = styled(Box)({
    minWidth: '110px',
    textAlign: 'center'
})

export const AddCommentBox = styled(Box)({
    marginTop: '1rem',
    display: 'flex',
    alignSelf: 'flex-end'
})

export const ForumThreadsListBox = styled(Box)({
    width: '40%',
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
    overflow: 'auto'
})

export const ForumThreadBox = styled(Box)({
    width: '60%',
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
    overflow: 'auto'
})