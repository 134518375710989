import React, {useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
    Box,
    Button,
    Divider,
    Typography,
    Modal, TextField, InputLabel, Input,
} from '@mui/material';
import {
    ThreadListHeader,
    ThreadBoxHeader,
    ThreadBox,
    ThreadBoxBottom,
    ThreadsListBox,
    ThreadsListContainer,
    TruncatedText,
    ThreadListWrapper
} from './styles/Forum.styled';
import {addForumThread, selectThread} from '../slices/forumSlice';
import {FlexContainer} from "./styles/Containers.styled";
import {addThread} from "../utils/api/forum";
import formatDate from "../utils/formatDate";
import styled from "styled-components";
import theme from "./styles/theme";

const StyledModalBox = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 400,
    backgroundColor: 'white',
    boxShadow: 24,
    padding: '0px 36px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '0.5rem',
    [theme.breakpoints.down('md')]: {
        width: 300,
    }
})

export default function ForumThreadsList({ threads }) {
    const [t, i18n] = useTranslation('ns1');
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const threadTitleRef = useRef(null);
    const threadTextRef = useRef(null)

    const { user } = useSelector((state) => state.auth)

    const AddThreadModal = ({ isOpen, onClose }) => {
        return (
            <Modal open={isOpen} onClose={onClose}>
                <StyledModalBox>
                    <Typography textAlign="center" variant="h6">Add New Thread</Typography>
                    <TextField inputRef={threadTitleRef} label="Thread title" sx={{ mb: 1 }}></TextField>
                    <TextField inputRef={threadTextRef} label="Thread text" rows={6} maxRows={10} multiline></TextField>
                    <FlexContainer sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Button sx={{ width: '150px', color: (theme) => theme.palette.common.black, backgroundColor: (theme) => theme.palette.primary.light }} onClick={() => handleSaveThread(user)} variant="contained">
                            Save
                        </Button>
                        <Button sx={{ width: '150px', color: (theme) => theme.palette.common.black }} onClick={onClose} variant="contained" color="secondary">
                            Cancel
                        </Button>
                    </FlexContainer>
                </StyledModalBox>
            </Modal>
        );
    };

    const handleAddNewThread = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSaveThread = (user) => {
        const thread = {
            _id: 'temporary_id',
            title: threadTitleRef.current?.value,
            user: user,
            comments: [],
            text: threadTextRef.current?.value,
            createdAt: formatDate(new Date()),
        }

        addThread(thread)
            .then((res) => {
                thread._id = res.newThread._id
                dispatch(addForumThread(thread))
                dispatch(selectThread(thread))
                setIsModalOpen(false);
            })
            .catch(error => alert(error))

    };

    const { forumSelectedThread } = useSelector((state) => state.forum)

    return (
        <ThreadsListContainer>
            <ThreadListHeader>
                <Typography variant="h6">{t('forum_threads')}</Typography>
                <Button onClick={handleAddNewThread} variant="contained" color="secondary">
                    {t('forum_add_thread')}
                </Button>
            </ThreadListHeader>
            <ThreadsListBox>
                {threads?.map((thread) => {
                    return (
                        <ThreadListWrapper
                            key={uuidv4()}
                            onClick={() => dispatch(selectThread(thread))}
                            className={thread.title === forumSelectedThread?.title ? 'active' : ''}
                        >
                            <ThreadBox>
                                <ThreadBoxHeader>
                                    <Typography color="secondary" fontWeight="bold">
                                        {thread.title}
                                    </Typography>
                                    <Typography color="gray">{formatDate(thread.createdAt)}</Typography>
                                    <TruncatedText textAlign="justify">{thread.text}</TruncatedText>
                                </ThreadBoxHeader>
                            </ThreadBox>
                            <ThreadBoxBottom>
                                <Typography fontWeight="bold">{thread.user?.username}</Typography>
                                <Typography fontWeight="bold">
                                    {
                                            thread.comments?.length + ' ' + t('forum_comments')
                                    }
                                </Typography>
                            </ThreadBoxBottom>
                            <Divider sx={{ mt: 2 }} />
                        </ThreadListWrapper>
                    );
                })}
            </ThreadsListBox>
            {/* Render the AddThreadModal component */}
            <AddThreadModal isOpen={isModalOpen} onClose={handleCloseModal} />
        </ThreadsListContainer>
    );
}
