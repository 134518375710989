import React, { useState, useEffect } from "react";
import {CarouselRoot, SlidesContainer, Slide, SliderContent, ProgressBarStyled, SliderContentMobile} from "./styles/ImageCarousel.styled";
import { v4 as uuidv4 } from 'uuid';
import { HeadingText, Text } from "./styles/Typography.styled";
import { motion } from "framer-motion";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";

export default function ImageCarousel({ slides, slideChangeTime }) {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [progress, setProgress] = useState(100);

    const theme = useTheme();
    const isMobileLg = useMediaQuery(theme.breakpoints.down('lg'));

    const goToSlide = (index) => {
        setCurrentSlide(index);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress(100);
            setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
        }, slideChangeTime * 1000);

        const progressInterval = setInterval(() => {
            setProgress((prevProgress) => prevProgress - 1);
        }, slideChangeTime * 10);

        return () => {
            clearInterval(interval);
            clearInterval(progressInterval);
        };
    }, [slides.length]);

    const activeSlide = slides[currentSlide];

    const [t, i18n] = useTranslation('ns1');

    const motionImageStyle = {
        cursor: "pointer",
        height: isMobileLg ? '200px' : '380px',
        width: '100vw',
        display: 'block'
    }

    return (
        <CarouselRoot>
            <motion.div
                key={currentSlide}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 2 }}
            >
                <Box>
                    <motion.img
                        src={activeSlide.imageUrl}
                        onClick={() => goToSlide(currentSlide)}
                        alt={activeSlide.title}
                        style={motionImageStyle}
                    />
                </Box>
                <ProgressBar progress={progress} seconds={slideChangeTime} />
                { isMobileLg &&
                    <SliderContentMobile>
                        <HeadingText variant="h6" sx={{ textAlign: 'center' }}>{t(activeSlide.title)}</HeadingText>
                        <Text sx={{ textAlign: 'center'}}>{t(activeSlide.description)}</Text>
                    </SliderContentMobile>
                }
            </motion.div>
        { !isMobileLg &&
            <SliderContent>
                <HeadingText variant="h5" sx={{ mb: 2, textAlign: 'center' }}>{t(activeSlide.title)}</HeadingText>
                <Text sx={{ textAlign: 'justify' }}>{t(activeSlide.description)}</Text>
            </SliderContent>
        }
        </CarouselRoot>
    );
}

const ProgressBar = ({ progress, seconds }) => {
    return (
        <ProgressBarStyled style={{ width: `${progress}%`}} progress={progress} seconds={seconds}></ProgressBarStyled>
    );
};