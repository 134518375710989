import React, {useEffect, useState} from 'react'
import TopBar from "../components/TopBar.jsx";
import ResourcesMap from "../components/ResourcesMap.jsx";
import AddResourceForm from "../components/AddResourceForm.jsx";
import Footer from "../components/Footer.jsx";
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {styled} from "@mui/system";
import {getMapFeatures} from "../utils/api/resources";

const AddResouresBox = styled(Box)({
    backgroundImage: "linear-gradient(to bottom, rgba(35, 110, 245, 0.80), rgba(35, 110, 245, 0.90)), url(/public/assets/sliderImage2.jpg)",
    width: '100%',
    backgroundColor: (theme) => theme.palette.secondary.light,
    padding: '1rem 0',
})


export default function InteractiveMap() {
    const { t } = useTranslation('ns1')
    const [features, setFeatures] = useState([]);

    useEffect(() => {
        const fetchFeatures = async () => {
            const res = await getMapFeatures();
            setFeatures(res.featuresArr)
            console.log(res.featuresArr)
        }
        fetchFeatures();
    }, [])

    return (
        <>
            <TopBar/>
            <ResourcesMap adminMode={false} features={features}/>
            <AddResouresBox>
                <Typography sx={{ padding: '2rem 6rem 1rem 6rem', color: 'white' }} textAlign="center">{t('add_resources_text')}</Typography>
                <AddResourceForm/>
            </AddResouresBox>
            <Footer/>
        </>
    )
}