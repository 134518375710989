import React from 'react'
import {useTranslation} from "react-i18next";
import {Button, Typography} from "@mui/material";
import styled from "@mui/material/styles/styled";
import TopBar from "../../components/TopBar.jsx";
import {ManualsRoot, ManualsTextBox} from "../../components/styles/ManualsPages.styled";
import RenderTextIntoParagraphs from "../../utils/insertLineBreaks.jsx";
import Footer from "../../components/Footer.jsx";

const DownloadButton = styled(Button)({
    backgroundColor: '#4caf50',
    color: 'white',
    '&:hover': {
        backgroundColor: '#388e3c',
    },
    margin: 'auto',
    marginTop: '20px',
    marginBottom: '20px',
})

const ManualsRootExtended = styled(ManualsRoot)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
})

export default function MentorshipProgramme() {
    const [t, i18n] = useTranslation('ns1');

    const handleDownloadResources = () => {
        const link = document.createElement('a');
        link.href = '/public/resources/mentorship_programme.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <>
            <TopBar/>
            <ManualsRootExtended>
                <Typography sx={{ mt: 2 }} variant="h6" align="center" gutterBottom>
                    {t('mentorship_programme_title')}
                </Typography>
                <ManualsTextBox>
                    <RenderTextIntoParagraphs translationKey="mentorship_programme_description" paragraphsBeforeLineBreak={4}/>
                </ManualsTextBox>
                <DownloadButton onClick={handleDownloadResources}>{t('download_resources')}</DownloadButton>
            </ManualsRootExtended>
            <Footer/>
        </>
    )
}