const listTranslations = async (language) => {
    try {
        const fetchUrl = `http://37.9.171.147:5000/translations/get?language=${language}`;
        const response = await fetch(fetchUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })

        if (!response.ok) {
            response.message = 'Get translations failed'
        }

        return await response.json();
    } catch (error) {
        // Handle and log errors here
        throw error;
    }
};

export { listTranslations };
