const login = async (email, password) => {
    try {
        const fetchUrl = `http://37.9.171.147:5000/users/login`;
        const response = await fetch(fetchUrl, {
            method: 'POST',
            body: JSON.stringify(
                {
                    email,
                    password,
                }),
            headers: {
                'Content-Type': 'application/json'
            }
        })

        if (!response.ok) {
            response.message = 'Login failed'
        }

        return await response.json();
    } catch (error) {
        console.log('user login error', error)
        throw error;
    }
};

const register = async (username, password, role, email, country) => {
    try {
        const fetchUrl = `http://37.9.171.147:5000/users/register`;
        const response = await fetch(fetchUrl, {
            method: 'POST',
            body: JSON.stringify(
                {
                    username,
                    password,
                    role,
                    email,
                    country,
                }),
            headers: {
                'Content-Type': 'application/json'
            }
        })

        if (!response.ok) {
            response.message = 'Register failed'
        }

        return await response.json();
    } catch (error) {
        console.log('user register error', error)
        throw error;
    }
}

const getUsers = async () => {
    try {
        const fetchUrl = `http://37.9.171.147:5000/users/get`;
        const response = await fetch(fetchUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })

        if (!response.ok) {
            response.message = 'Get users failed'
        }

        return await response.json();
    } catch (error) {
        console.log('get users error', error)
        throw error;
    }
}

const editUserDetails = async (id, username, email, country, avatarUrl, url) => {
    try {
        const fetchUrl = `http://37.9.171.147:5000/users/edit/${id}`;
        const response = await fetch(fetchUrl, {
            method: 'PUT',
            body: JSON.stringify(
                {
                    username,
                    email,
                    country,
                    avatarUrl,
                    url
                }),
            headers: {
                'Content-Type': 'application/json'
            }
        })

        if (!response.ok) {
            response.message = 'Edit user failed'
        }

        return await response.json();
    } catch (error) {
        console.log('edit user error', error)
        throw error;
    }
}

export { login, register, getUsers, editUserDetails }