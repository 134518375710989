import React, {useEffect, useState} from 'react';
import {NewsFooter, NewsImage, NewsTextContainer, RecentNewsContainer, NewsContainer} from "./styles/News.styled";
import {HeadingText} from "./styles/Typography.styled";
import {listNews} from "../utils/api/news";
import {FlexContainer} from "./styles/Containers.styled";
import formatDate from "../utils/formatDate";
import {ButtonStyled} from "./styles/UserButtons.styled";
import {useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";

export default function RecentNews() {
    const [news, setNews] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [progress, setProgress] = useState(100);
    const { t } = useTranslation('ns1');

    const navigate = useNavigate()

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress(100); // Reset the progress on each interval
            setCurrentSlide((prevSlide) => (prevSlide + 1) % news.length);
        }, 3000); // Change slide every 5 seconds

        const progressInterval = setInterval(() => {
            setProgress((prevProgress) => prevProgress - 1);
        }, 50); // Update progress every 50 milliseconds

        // Clean up the intervals on component unmount
        return () => {
            clearInterval(interval);
            clearInterval(progressInterval);
        };
    }, [news.length]);

    useEffect(() => {
        const fetchNews = async () => {
            const { news } = await listNews();
            setNews(news);
        }
        fetchNews();
    }, [])

    const handleReadMoreClick = (urlPath) => {
        navigate('media-corner/news/' + urlPath)
    }

    const activeSlide = news[currentSlide];

    return (
        <RecentNewsContainer>
            <HeadingText variant="h5" sx={{ mb: 3 }}>{t('recent_news_heading')}</HeadingText>
            <NewsContainer sx={{ flexDirection: 'row' }}>
                <NewsImage src={activeSlide?.imgPath} alt={activeSlide?.imgPath} />
                <FlexContainer sx={{ ml: 2 }}>
                    <HeadingText variant="h6" sx={{ mb: 1, mt: 2, textAlign: 'center' }}>{t(activeSlide?.heading)}</HeadingText>
                    <NewsTextContainer>{t(activeSlide?.previewText)}</NewsTextContainer>
                    <NewsFooter>
                        <p>{formatDate(activeSlide?.createdAt)}</p>
                        <ButtonStyled variant="contained" color="primary" onClick={() => handleReadMoreClick(activeSlide.urlPath)}>
                            Read More
                        </ButtonStyled>
                    </NewsFooter>
                </FlexContainer>
            </NewsContainer>
        </RecentNewsContainer>
    )
}