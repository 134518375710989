import React, { useEffect } from 'react';
import TopBar from '../components/TopBar.jsx';
import Footer from '../components/Footer.jsx';
import {
    Box,
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getUserStats } from '../utils/api/game';
import { getSessions } from '../utils/api/session';
import RandomPlayersTable from "../components/RandomPlayersTable.jsx";
import formatDate from "../utils/formatDate";
import theme from "../components/styles/theme";

// Style for the Stat Cards
export const StatCardsContainer = styled(Box)({
    display: 'flex',
    marginTop: '2rem',
    justifyContent: 'space-around',
    width: '100%',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '2rem',
    }
});

export const StatCard = styled(Paper)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'white !important',
    width: '240px',
    height: '240px',
});

// Style for the Sessions Table
export const StyledTable = styled(Table)({
    '& .MuiTableCell-head': {
        backgroundColor: '#f5f5f5',
        color: '#000',
        fontWeight: 'bold',
    },
    '& .MuiTableCell-body': {
        fontSize: '0.9rem',
    },
    '& .MuiTableRow-root:nth-child(even)': {
        backgroundColor: '#202125',
    },
    '& .MuiTableRow-root:nth-child(odd)': {
        backgroundColor: '#27282C',
    }
});

export const StyledTableCell = styled(TableCell)({
    fontWeight: 'bold !important',
    color: 'white !important',
    border: 'none !important',
});

export const StyledTableCellHeader = styled(TableCell)({
    background: 'black !important',
    color: 'white !important',
    border: 'none !important',
})

export const TablesWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '32px',
    background: '#27282C',
    padding: '32px',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'center',
    }
})

export const StyledTableContainer = styled(TableContainer)({
    minWidth: '600px',
    maxWidth: '700px',
    [theme.breakpoints.down('md')]: {
        minWidth: '100%',
        marginTop: '2rem'
    }
})

export default function SimulationGameDashboard() {
    const { t } = useTranslation('ns1');
    const { user } = useSelector((state) => state.auth);
    const [sessionsList, setSessionsList] = React.useState([]);
    const [sessionsPlayed, setSessionsPlayed] = React.useState(0);
    const [badgesEarned, setBadgesEarned] = React.useState(0);
    const [timePlayed, setTimePlayed] = React.useState(0);

    useEffect(() => {
        const fetchUserStats = async function () {
            const userId = user._id;
            const { gameStats } = await getUserStats(userId);
            setSessionsPlayed(gameStats.sessionsPlayed);
            setBadgesEarned(gameStats.badges);
            setTimePlayed(Math.round(gameStats.timePlayed));
        };
        const fetchSessions = async function () {
            const { sessionsList } = await getSessions();
            const latestSessions = [...sessionsList]
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                .slice(0, 10);
            setSessionsList(latestSessions);
        };


        fetchUserStats();
        fetchSessions();
    }, []);

    return (
        <>
            <TopBar isGame={true} />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 'auto' }}>
                <Typography variant="h5" component="h5" gutterBottom textAlign="center">
                    {t('simulation_game_dashboard_title')}
                </Typography>
                <Box sx={{ width: '100%', background: 'url(/public/assets/bg/6.png)' }}>
                    <StatCardsContainer>
                        <StatCard sx={{ backgroundColor: '#236ef5', padding: '1.5rem' }}>
                            <MilitaryTechIcon sx={{ width: '90px', height: '90px' }} />
                            <Typography variant="h5" component="h5" gutterBottom textAlign="center" fontWeight="bold">
                                {t('simulation_game_sessions_played')}
                            </Typography>
                            <Typography fontWeight="bold" variant="h4" gutterBottom>
                                {sessionsPlayed}
                            </Typography>
                        </StatCard>
                        <StatCard sx={{ backgroundColor: '#FC6A03', padding: '1.5rem' }}>
                            <SportsEsportsIcon sx={{ width: '90px', height: '90px' }} />
                            <Typography variant="h5" component="h5" gutterBottom textAlign="center" fontWeight="bold">
                                {t('simulation_game_earned_badges')}
                            </Typography>
                            <Typography fontWeight="bold" variant="h4" gutterBottom>
                                {badgesEarned}
                            </Typography>
                        </StatCard>
                        <StatCard sx={{ backgroundColor: '#00d5c0', padding: '1.5rem' }}>
                            <AccessTimeIcon sx={{ width: '90px', height: '90px' }} />
                            <Typography variant="h5" component="h5" gutterBottom textAlign="center" fontWeight="bold">
                                {t('simulation_game_time_played')}
                            </Typography>
                            <Typography fontWeight="bold" variant="h4" gutterBottom>
                                {timePlayed}
                            </Typography>
                        </StatCard>
                    </StatCardsContainer>
                    <TablesWrapper>
                        <RandomPlayersTable/>
                        <StyledTableContainer component={Paper}>
                            <StyledTable>
                                <TableHead>
                                    <TableRow sx={{ height: '75px' }}>
                                        <StyledTableCellHeader>Date</StyledTableCellHeader>
                                        <StyledTableCellHeader>Mission</StyledTableCellHeader>
                                        <StyledTableCellHeader>Earned Badges</StyledTableCellHeader>
                                        <StyledTableCellHeader>Played Time</StyledTableCellHeader>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sessionsList?.map((session) => (
                                        <TableRow sx={{ height: '75px' }} key={session._id}>
                                            <StyledTableCell>{formatDate(session.createdAt)}</StyledTableCell>
                                            <StyledTableCell sx={{ fontSize: '1.5rem !important' }}>{session.missionNumber}</StyledTableCell>
                                            <StyledTableCell sx={{ fontSize: '1.5rem !important' }}>{session.earnedBadges}</StyledTableCell>
                                            <StyledTableCell sx={{ fontSize: '1.5rem !important' }}>{Math.round(session.timePlayed)}<p style={{ fontSize: '1rem', display: 'inline' }}> min</p></StyledTableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </StyledTable>
                        </StyledTableContainer>
                    </TablesWrapper>
                </Box>

            </Box>
            <Footer isGame={true} />
        </>
    );
}
