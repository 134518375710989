import { styled } from '@mui/material/styles';
import {Box, Typography} from "@mui/material";

export const HeadingText = styled(Typography)({
    fontWeight: 'bold',
});

export const Text = styled(Typography)({

});

export const TextBottomMargin = styled(Text)({
    marginBottom: '1rem',
})

export const Slide = styled('img')({

});

