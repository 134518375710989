import {Alert, AlertTitle, Box, Button, Checkbox, TextField, Typography} from "@mui/material";
import React, {useState, useEffect} from "react";
import {FlexContainerRow} from "../components/styles/Containers.styled";
import {useDispatch, useSelector} from "react-redux";
import {FormControlLabel} from "@mui/material"
import {addQuestion} from "../slices/scenarioBuilderSlice";
import {styled} from "@mui/system";
import theme from "../components/styles/theme";

const FlexContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '2rem',
    padding: '1rem',
    backgroundColor: theme.palette.background.paper,
})

const HighlightedText = styled(Box)({
    backgroundColor: "lightgrey",
    padding: "0.5rem",
    borderRadius: "0.25rem",
    margin: "1rem 0",
});

const StyledTextField = styled(TextField)({
    '& .MuiInputBase-root': {
        width: '400px',
        [theme.breakpoints.down('md')]: {
            width: 'auto',
        }
    }
})


export function MultipleChoiceQuestion({ question, edit, editInitialValues }) {

    const dispatch = useDispatch();
    const [options, setOptions] = useState(Array(9).fill(''));
    const [correctAnswers, setCorrectAnswers] = useState(Array(9).fill(false));
    const [error, setError] = useState(false);
    const [alertText, setAlertText] = useState('');

    useEffect(() => {
        if (edit) {
            console.log(editInitialValues)
            setOptions(editInitialValues.answers);
            setCorrectAnswers(editInitialValues.correctAnswers.map((answer) => editInitialValues.answers.indexOf(answer) !== -1));
        }
    }, []);

    const handleInputChange = (index, value) => {
        const newOptions = [...options];
        newOptions[index] = value;
        setOptions(newOptions);
    };

    const handleCheckboxChange = (index) => {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[index] = !newCorrectAnswers[index];
        setCorrectAnswers(newCorrectAnswers);
    };

    const handleSave = () => {
        if(!correctAnswers.some((answer) => answer)) {
            setError(true);
            setAlertText('Please select at least one correct answer');
            return;
        }

        const userAnswers = options.filter((option) => option !== '')
        const selectedCorrectAnswers = options.filter((option, idx) => option && correctAnswers[idx]);
        const newQuestion = {
            id: question.id,
            question: question.question,
            questionType: 'multiple_choice',
            answers: userAnswers,
            correctAnswers: selectedCorrectAnswers,
        };

        dispatch(addQuestion(newQuestion));
        setError(false);
        setAlertText('Answers have been saved!');
    };

    return (
        <FlexContainer>
            <Typography textAlign="center">{question.question}</Typography>
            <Typography>Add your answers to the above question</Typography>
            {[...Array(9)].map((_, index) => (
                <FlexContainerRow key={index}>
                    <StyledTextField
                        label={`Option ${index + 1}`}
                        value={options[index]}
                        onChange={(e) => handleInputChange(index, e.target.value)}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                color="primary"
                                checked={correctAnswers[index]}
                                onChange={() => handleCheckboxChange(index)}
                                disabled={!options[index]}
                            />
                        }
                        label={`Correct`}
                    />
                </FlexContainerRow>
            ))}
            <Button variant='contained' onClick={handleSave}>Save Question</Button>
            {
                alertText && (
                    <Alert sx={{ mt: 2 }} severity={error ? 'error' : 'success'}>
                        <AlertTitle>{error ? 'Error' : 'Success'}</AlertTitle>
                        <Typography>{alertText}</Typography>
                    </Alert>
                )
            }
        </FlexContainer>
    );
}

///////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////

const BlankWordBox = styled(Box)({
    padding: 16,
    margin: 16,
    [theme.breakpoints.down('md')]: {
        display: 'flex',
    },
})


export function FillInTheBlanksQuestion({ question }) {
    const dispatch = useDispatch();
    const [originalQuestionText, setOriginalQuestionText] = useState("");
    const [displayedQuestionText, setDisplayedQuestionText] = useState("");
    const [blankWord, setBlankWord] = useState("");
    const [blankWords, setBlankWords] = useState([]);
    const [error, setError] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [isDone, setIsDone] = useState(false);

    useEffect(() => {
        if (question) {
            setOriginalQuestionText(question.question);
            setDisplayedQuestionText(question.question);
        }
    }, [question]);

    const handleAddBlankWord = () => {
        if (blankWord === "") {
            setError(true);
            setAlertText("Blank word cannot be empty");
            return;
        }

        setBlankWords([...blankWords, blankWord]);
        const highlightedText = displayedQuestionText.replace(new RegExp(`\\b${blankWord}\\b`, "g"), "_______");
        setDisplayedQuestionText(highlightedText);
        setBlankWord("");
    };

    const handleSaveQuestion = () => {
        if (originalQuestionText === "" || blankWords.length === 0) {
            setError(true);
            setAlertText("Please enter the question text and add at least one blank word");
            return;
        }

        let formattedQuestionText = originalQuestionText;
        blankWords.forEach((word, index) => {
            formattedQuestionText = formattedQuestionText.replace(new RegExp(`\\b${word}\\b`, "g"), `{${index}}`);
        });

        const newQuestion = {
            question: originalQuestionText,
            formattedQuestion: formattedQuestionText,
            questionType: "fill_in_the_blanks",
            correctAnswers: blankWords,
        };

        dispatch(addQuestion(newQuestion));
        setError(false);
        setAlertText("Question has been saved!");
        setBlankWords([]);
        setIsDone(true);
    };

    return (
        <FlexContainer>
            <HighlightedText>
                <Typography variant="h6">{displayedQuestionText}</Typography>
            </HighlightedText>
            <BlankWordBox sx={{ p: 2, mx: 1}}>
                <TextField
                    label="Blank Word"
                    value={blankWord}
                    onChange={(e) => setBlankWord(e.target.value)}
                    variant="outlined"
                />
                <Button sx={{ ml: 2 }} variant="contained" onClick={handleAddBlankWord}>
                    Add Blank Word
                </Button>
            </BlankWordBox>
            <Typography>Blank Words:</Typography>
            <Box>
                {blankWords.map((word, index) => (
                    <Typography key={index}>{index + 1}. {word}</Typography>
                ))}
            </Box>
            <Button variant="contained" color="primary" onClick={handleSaveQuestion}>
                Save Question
            </Button>
            {alertText && (
                <Alert sx={{ mt: 2 }} severity={error ? "error" : "success"}>
                    <AlertTitle>{error ? "Error" : "Success"}</AlertTitle>
                    <Typography>{alertText}</Typography>
                </Alert>
            )}
        </FlexContainer>
    );
}

const FlexBox = styled(Box)({
    [theme.breakpoints.down('md')]: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        gap: '0.5rem',
    }
})

export function FillInTheBlanksDropdownQuestion({ question }) {
    const dispatch = useDispatch();
    const [originalQuestionText, setOriginalQuestionText] = useState("");
    const [displayedQuestionText, setDisplayedQuestionText] = useState("");
    const [selectedWord, setSelectedWord] = useState("");
    const [blankWordOptions, setBlankWordOptions] = useState("");
    const [correctAnswers, setCorrectAnswers] = useState([]);
    const [options, setOptions] = useState([]);
    const [error, setError] = useState(false);
    const [alertText, setAlertText] = useState("");

    useEffect(() => {
        if (question) {
            setOriginalQuestionText(question.question);
            setDisplayedQuestionText(question.question);
        }
    }, [question]);

    const handleAddBlankWord = () => {
        const optionItems = blankWordOptions.split(',');
        if (optionItems.length < 2 || selectedWord === "") {
            setError(true);
            setAlertText("Please enter the word to replace and add at least two options for the blank word");
            return;
        }

        setCorrectAnswers([...correctAnswers, selectedWord]);
        setOptions([...options, optionItems]);

        const highlightedText = displayedQuestionText.replace(new RegExp(`\\b${selectedWord}\\b`, "g"), `\\{${correctAnswers.length}\\}`);
        setDisplayedQuestionText(highlightedText);

        setSelectedWord("");
        setBlankWordOptions("");
    };

    const handleSaveQuestion = () => {
        if (originalQuestionText === "" || correctAnswers.length === 0) {
            setError(true);
            setAlertText("Please enter the question text and add at least one set of blank words");
            return;
        }

        const newQuestion = {
            id: new Date().getTime(),
            question: originalQuestionText,
            formattedQuestion: displayedQuestionText,
            questionType: "fill_in_the_blanks_dropdown",
            correctAnswers,
            options,
        };

        dispatch(addQuestion(newQuestion));
        setError(false);
        setAlertText("Question has been saved!");
        setCorrectAnswers([]);
        setOptions([]);
    };

    return (
        <FlexBox>
            <Typography variant="h6">{displayedQuestionText}</Typography>
            <Box sx={{ p: 2, mx: 1 }}>
                <TextField
                    label="Word to Replace"
                    value={selectedWord}
                    onChange={(e) => setSelectedWord(e.target.value)}
                    variant="outlined"
                />
                <TextField
                    label="Blank Word Options"
                    placeholder="Option1,Option2,Option3"
                    value={blankWordOptions}
                    onChange={(e) => setBlankWordOptions(e.target.value)}
                    variant="outlined"
                />
                <Button sx={{ ml: 2, mt: 2 }} variant="contained" onClick={handleAddBlankWord}>
                    Add Blank Word
                </Button>
            </Box>
            <FlexBox>
                <Box>
                    <Typography>Correct Answers:</Typography>
                    <Box>
                        {correctAnswers.map((word, index) => (
                            <Typography key={index}>{index + 1}. {word}</Typography>
                        ))}
                    </Box>
                </Box>
                <Button variant="contained" color="primary" onClick={handleSaveQuestion}>
                    Save Question
                </Button>
            </FlexBox>
            {alertText && (
                <Alert sx={{ mt: 2 }} severity={error ? "error" : "success"}>
                    <AlertTitle>{error ? "Error" : "Success"}</AlertTitle>
                    <Typography>{alertText}</Typography>
                </Alert>
            )}
        </FlexBox>
    );
}





