import React, {useEffect} from 'react';
import Footer from "../../components/Footer.jsx";
import TopBar from "../../components/TopBar.jsx";

import {
    VCBackground,
    VCFlexBox,
    VCFlexButtonsContainer,
    VCRoot,
    VCTitle
} from "../../components/styles/virtualClassroom/VirtualClassroom.styled";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Box, Button, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import Calendar from "../../components/Calendar.jsx";
import theme from "../../components/styles/theme";

export default function VCWatchAndEngage() {
    const { t } = useTranslation('ns1');
    const { isAuthenticated } = useSelector((state) => state.auth)

    return (
        <VCBackground>
            <TopBar isGame={true}/>
            <VCRoot sx={{ width: '1000px', height: '500px', [theme.breakpoints.down('sm')]: {
                    width: '100%', // example breakpoint for small screens
                } }}>
                <VCTitle variant="h4" gutterBottom sx={{ marginTop: '10px' }}>{t('watch_and_engage')}</VCTitle>
                <Box>
                    {isAuthenticated ? <VCWatchAndEngageAuthenticated/> : <VCWatchAndEngageUnauthenticated/>}
                </Box>
                <Calendar />
            </VCRoot>
            <Footer/>
        </VCBackground>
    )
}

function VCWatchAndEngageAuthenticated() {
    const [t, i18n] = useTranslation('ns1');
    const navigate = useNavigate();

    return (
        <>
            <VCFlexButtonsContainer>
                {/*<Button*/}
                {/*    type="submit"*/}
                {/*    variant="contained"*/}
                {/*    color="secondary"*/}
                {/*    onClick={() => navigate('/interact/watch-and-engage/join-event')}*/}
                {/*>*/}
                {/*    {t('join_event')}*/}
                {/*</Button>*/}
                <Button
                    type="submit"
                    variant="outlined"
                    color="secondary"
                    onClick={() => navigate('/interact/watch-and-engage/create-event')}
                >
                    {t('create_event')}
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate('/interact/watch-and-engage/list-edit-events')}
                >
                    {t('list_edit_events')}
                </Button>
            </VCFlexButtonsContainer>
        </>
    )
}

function VCWatchAndEngageUnauthenticated() {
    const [t, i18n] = useTranslation('ns1');
    const navigate = useNavigate();

    return (
        <>
            <Typography textAlign="center" variant="h5">{t('watch_and_engage_unauthenticated')}</Typography>
            <VCFlexButtonsContainer>
                <Button type="submit" variant="contained" color="secondary" onClick={() => navigate('/login')}>{t('login')}</Button>
                <Button type="submit" variant="contained" color="secondary" onClick={() => navigate('/network/why-to-join')}>{t('register')}</Button>
                {/*// TODO: add calendar for display to non registered users*/}
            </VCFlexButtonsContainer>
        </>
    )
}