import React from 'react'
import TopBar from "../components/TopBar.jsx";

export default function Home() {
    return (
        <>
            <TopBar/>
            <h2>Home here</h2>
        </>
    )
}