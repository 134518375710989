// //
//
//
// DiGiTheca

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Footer from "../../components/Footer.jsx";
import TopBar from "../../components/TopBar.jsx";
import { VCBackground } from "../../components/styles/virtualClassroom/VirtualClassroom.styled";
import Pagination from '@mui/material/Pagination';
import { useNavigate } from "react-router-dom";
import { listVCEvents, listVCResources } from '../../utils/api/vc';

// Styled components
// ... [reuse the styled components from the existing page]

export default function VCPastEventsAndResourcesList() {
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const navigate = useNavigate();

    useEffect(() => {
        fetchItems();
    }, []);

    const fetchItems = async () => {
        try {
            const events = await listVCEvents();
            const resources = await listVCResources();
            let combinedItems = [...events.vcEvents, ...resources.vcResources];

            // Filter for past events and approved resources
            combinedItems = combinedItems.filter(item =>
                (item.eventType && new Date(item.date) < new Date()) || // Past events
                (!item.eventType && item.approved) // Approved resources
            );

            setItems(combinedItems);
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };

    const handleCardClick = (item) => {
        if (!item.eventType) { // If it's a resource
            navigate(`/interact/watch-and-engage/view-resource/${item._id}`);
        }
    };

    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <>
            <TopBar isGame={true} />
            <VCBackground>
                <VCListEventsContainer>
                    <FilteredItemsContainer>
                        {currentItems.map((item) => (
                            <StyledCard key={item._id} onClick={() => handleCardClick(item)}>
                                <StyledCardContent>
                                    <Typography variant="h5" sx={{ fontSize: '18px' }}>{item.name}</Typography>
                                    <Typography color="textSecondary">{item.eventType ? new Date(item.date).toLocaleDateString() : 'Resource'}</Typography>
                                    <Typography variant="body2">{item.description}</Typography>
                                </StyledCardContent>
                            </StyledCard>
                        ))}
                    </FilteredItemsContainer>
                    <StyledPagination
                        count={Math.ceil(items.length / itemsPerPage)}
                        page={currentPage}
                        onChange={paginate}
                        color="primary"
                        shape="rounded"
                        size="large"
                        showFirstButton
                        showLastButton
                    />
                </VCListEventsContainer>
            </VCBackground>
            <Footer isGame={true} />
        </>
    );
}
