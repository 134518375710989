import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, Pagination, FormControl, InputLabel, Select, MenuItem, TextField, Button, Modal } from '@mui/material';
import { styled } from "@mui/system";
import { listResources } from "../utils/api/resources";
import TopBar from "../components/TopBar.jsx";
import Footer from "../components/Footer.jsx";
import formatDate from "../utils/formatDate";
import theme from "../components/styles/theme";

const ResourceCardContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
    },
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    marginBottom: '2rem',
});

const ResourceCard = styled(Paper)({
    margin: '1rem',
    padding: '1rem',
    height: '280px',
    width: '460px',
    [theme.breakpoints.down('md')]: {
        width: 'auto'
    },
    flex: '0 1 calc(33.333% - 2rem)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&:hover': {
        boxShadow: '0 0 3px rgba(0,0,0,0.8)',
        transform: 'scale(1.03)',
        transition: 'all 0.2s ease-in-out',
    }
});

const FiltersBox = styled(Box)({
    marginTop: '50px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
    },
    alignItems: 'center',
});

const StyledFormControl = styled(FormControl)({
    margin: '1rem',
    width: '200px',
});

export default function Resources() {
    const [resources, setResources] = useState([]);
    const [filteredResources, setFilteredResources] = useState([]);
    const [page, setPage] = useState(1);
    const [type, setType] = useState("");
    const [category, setCategory] = useState("");
    const [country, setCountry] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalDescription, setModalDescription] = useState("");
    const itemsPerPage = 9; // A multiple of 3, since we display 3 cards per row

    // Modal open/close handlers
    const handleModalOpen = (description) => {
        setModalDescription(description);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        const fetchResources = async () => {
            const res = await listResources()
            setResources(res.resources);
            setFilteredResources(res.resources);
        };
        fetchResources();
    }, []);

    useEffect(() => {
        let tempResources = [...resources];

        if (searchTerm) {
            tempResources = tempResources.filter(resource => resource.title.toLowerCase().includes(searchTerm.toLowerCase()));
        }

        if (type) {
            tempResources = tempResources.filter(resource => resource.type === type);
        }

        if (category) {
            tempResources = tempResources.filter(resource => resource.category === category);
        }

        if (country) {
            tempResources = tempResources.filter(resource => resource.country === country);
        }

        setFilteredResources(tempResources);
    }, [type, category, country, searchTerm, resources]);


    const uniqueTypes = [...new Set(resources.map(resource => resource.type))];
    const uniqueCategories = [...new Set(resources.map(resource => resource.category))];
    const uniqueCountries = [...new Set(resources.map(resource => resource.country))];

    const resetFilters = () => {
        setType("");
        setCategory("");
        setCountry("");
        setSearchTerm("");
    };

    const modalBody = (
        <Box
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
            }}
        >
            <Typography id="modal-description" variant="body2">
                {modalDescription}
            </Typography>
            <Button variant="contained" color="secondary" onClick={handleModalClose}>Close</Button>
        </Box>
    );

    return (
        <>
            <TopBar />
            <FiltersBox>
                <TextField sx={{ [theme.breakpoints.down('md')]: { width: '200px' } }} label="Search for a title" variant="outlined" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                <StyledFormControl variant="outlined">
                    <InputLabel id="type-select-label">Type</InputLabel>
                    <Select value={type} onChange={(e) => setType(e.target.value)} labelId="type-select-label" label="Type">
                        {uniqueTypes.map(t => <MenuItem key={t} value={t}>{t}</MenuItem>)}
                    </Select>
                </StyledFormControl>
                <StyledFormControl variant="outlined">
                    <InputLabel id="category-select-label">Category</InputLabel>
                    <Select value={category} onChange={(e) => setCategory(e.target.value)} labelId="category-select-label" label="Category">
                        {uniqueCategories.map(c => <MenuItem key={c} value={c}>{c}</MenuItem>)}
                    </Select>
                </StyledFormControl>
                <StyledFormControl variant="outlined">
                    <InputLabel id="country-select-label">Country</InputLabel>
                    <Select value={country} onChange={(e) => setCountry(e.target.value)} labelId="country-select-label" label="Country">
                        {uniqueCountries.map(c => <MenuItem key={c} value={c}>{c}</MenuItem>)}
                    </Select>
                </StyledFormControl>
                <Button variant="contained" color="secondary" onClick={resetFilters}>Reset Filters</Button>
            </FiltersBox>
            <ResourceCardContainer>
                {
                    filteredResources.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((resource) => {
                        const truncatedDescription = resource.description.length > 150
                            ? resource.description.substring(0, 150) + "..."
                            : resource.description;

                        return (
                            <ResourceCard key={resource._id} elevation={3}>
                                <Box>
                                    <Typography variant="body2">{formatDate(resource.createdAt)}</Typography>
                                    <Typography variant="h6" gutterBottom>{resource.title}</Typography>
                                    <Typography variant="body2" gutterBottom>
                                        {truncatedDescription}
                                        {resource.description.length > 150 && (
                                            <span>
                                                <a
                                                    href="#"
                                                    style={{ fontWeight: 'bold', textDecoration: 'underline' }}
                                                    onClick={(event) => { event.preventDefault(); handleModalOpen(resource.description)}}
                                                >
                                                    See more
                                                </a>
                                            </span>
                                        )}
                                    </Typography>
                                    <Typography variant="body2"><strong>Country:</strong> {resource.country}</Typography>
                                    <Typography variant="body2"><strong>Category:</strong> {resource.category}</Typography>
                                    <Typography variant="body2"><strong>Type:</strong> {resource.type}</Typography>
                                </Box>
                                <Button variant="contained" onClick={() => window.open(resource.link, "_blank")}>View More</Button>
                            </ResourceCard>
                        );
                    })
                }
            </ResourceCardContainer>
            <Pagination sx={{ margin: '2rem auto', display: 'flex', justifyContent: 'center' }} count={Math.ceil(filteredResources.length / itemsPerPage)} page={page} onChange={(event, value) => setPage(value)} />
            <Modal open={modalOpen} onClose={handleModalClose}>
                {modalBody}
            </Modal>
            <Footer />
        </>
    );
}
