import React from 'react';
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import TopBarMobile from "./TopBarMobile.jsx";
import TopBarDesktop from "./TopBarDesktop.jsx";

export default function TopBar({ isGame = false }){
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        isMobile ? <TopBarMobile isGame={isGame}/> : <TopBarDesktop isGame={isGame} />
    )
}