import styled from 'styled-components';
import { Box } from '@mui/material'
import theme from "../theme";

export const GameContainer = styled(Box)({
    marginTop: '50px',
    display: 'flex',
    justifyContent: 'center',
    height: 'calc(100vh - 50px)',
    [theme.breakpoints.down('md')]: {
        height: '100%'
    },
    // width: '950px',
    margin: '50px auto 0px auto',
    paddingTop: '1rem',
    flexDirection: 'column',
})

export const GameButtonsContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    gap: '10rem',
    [theme.breakpoints.down('md')]: {
        gap: '3rem'
    },
    margin: '0.5rem 2rem',
    alignItems: 'center'
})
export const GameBackgroundVideo = styled('video')({
    zIndex: '-1',
    position: 'absolute',
    width: '100%',
    height: 'calc(100% - 50px)',
    objectFit: 'cover',
    opacity: '0.90',
    [theme.breakpoints.down('md')]: {
        height: '100%'
    }
})