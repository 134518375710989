import React from 'react'
import TopBar from "../components/TopBar.jsx";
import LoginForm from "../components/LoginForm.jsx";
import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";

export default function LoginPage() {
    const [t, i18n] = useTranslation('ns1');

    return (
        <>
            <TopBar/>
            <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '1rem', margin: 'auto', maxWidth: '380px' }}>
                <Typography sx={{ marginBottom: 2 }} variant="h4" component="h4">{t('login_title')}</Typography>
                <LoginForm/>
            </Box>
        </>
    )
}