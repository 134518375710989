import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Alert, AlertTitle, Box, Button, MenuItem, TextField } from '@mui/material';
import { register } from '../utils/api/auth';
import { countries } from '../utils/countriesList';
import { v4 as uuidv4 } from 'uuid';
import { FlexContainerRow } from './styles/Containers.styled';
import { assignCountryByIp } from '../utils/assignCountryByIp';
import { useTranslation } from 'react-i18next';
import {useSelector} from "react-redux";

const registerSchema = yup.object({
    email: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
    password: yup.string('Enter your password').min(8, 'Password should be of minimum 8 characters length').required('Password is required'),
    retype_Password: yup.string('Retype your password').min(8, 'Password should be of minimum 8 characters length').required('Password is required'),
    name: yup.string('Enter your name').required('Name is required'),
    role: yup.string('Enter your role').required('Role is required'),
    country: yup.string('Enter your country').required('Country is required'),
});

const renderTextField = (fieldName, formik) => {
    const label = fieldName
        .replace(/_/g, ' ') // Replace underscores with spaces
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');

    return (
        <TextField
            id={fieldName}
            name={fieldName}
            label={label}
            sx={{ width: '50%' }}
            type={['password', 'retype_Password'].includes(fieldName) ? 'password' : 'text'}
            value={formik.values[fieldName]}
            onChange={formik.handleChange}
            error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
            helperText={formik.touched[fieldName] && formik.errors[fieldName]}
        />
    );
};

const renderSelectField = (fieldName, formik, label, options) => {
    return (
        <TextField
            id={fieldName}
            name={fieldName}
            label={label}
            select
            value={formik.values[fieldName]}
            sx={{ width: '50%' }}
            onChange={(e) => formik.setFieldValue(fieldName, e.target.value)}
            error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
            helperText={formik.touched[fieldName] && formik.errors[fieldName]}
        >
            {options.map((option) => (
                <MenuItem key={uuidv4()} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
};

const RegisterForm = () => {
    const [status, setStatus] = useState('idle');
    const [errorMessage, setErrorMessage] = useState('');
    const { isAuthenticated } = useSelector((state) => state.auth);

    const [t, i18n] = useTranslation('ns1');

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            retype_Password: '',
            name: '',
            role: '',
            country: '',
        },
        validationSchema: registerSchema,
        onSubmit: async (values) => {
            setStatus('submitting');
            const res = await register(values.name, values.password, values.role, values.email, values.country);
            if (res.success) {
                setStatus('success');
                redirectToLogin(5);
            } else {
                setStatus('error');
                setErrorMessage(res.message);
            }
        },
    });

    useEffect(() => {
        const assignCountry = async () => {
            const country = await assignCountryByIp(countries);
            formik.setFieldValue('country', country.label);
        };
        assignCountry();
    }, []);

    const redirectToLogin = (sec) => {
        setTimeout(() => {
            window.location.href = '/login';
        }, sec * 1000);
    };

    const handleGoBack = () => {
        setStatus('idle');
    };

    const renderFormContent = () => {
        switch (status) {
            case 'idle':
                return (
                    <form onSubmit={formik.handleSubmit}>
                        <FlexContainerRow>
                            {renderTextField('name', formik)}
                            {renderSelectField('role', formik, 'Role', [
                                { label: 'Personal', value: 'personal' },
                                { label: 'Organisation', value: 'organisation' },
                            ])}
                        </FlexContainerRow>
                        <FlexContainerRow>
                            {renderTextField('email', formik)}
                            {renderSelectField('country', formik, 'Country', countries.map((country) => ({ label: country.label, value: country.label })))}
                        </FlexContainerRow>
                        <FlexContainerRow>
                            {renderTextField('password', formik)}
                            {renderTextField('retype_Password', formik)}
                        </FlexContainerRow>
                        <Button sx={{ mt: 1 }} color="primary" variant="contained" fullWidth type="submit" disabled={isAuthenticated}>
                            Register
                        </Button>
                    </form>
                );
            case 'success':
                return (
                    <Alert sx={{ width: '75%' }} severity="success">
                        <AlertTitle> {t('register_success_title')} </AlertTitle>
                        {t('register_success_message')}
                    </Alert>
                );
            case 'error':
                return (
                    <Box sx={{ width: '75%' }}>
                        <Alert severity="error">
                            <AlertTitle sx={{ fontWeight: 'bold' }}> {t('register_error_title')} </AlertTitle>
                            {errorMessage}
                        </Alert>
                        <Button sx={{ mt: 1 }} color="secondary" variant="contained" fullWidth type="submit" onClick={handleGoBack}>
                            Go back
                        </Button>
                    </Box>
                );
            default:
                return null;
        }
    };

    return <>{renderFormContent()}</>;
};

export default RegisterForm;
