import { createSlice } from '@reduxjs/toolkit';

export const scenarioBuilderSlice = createSlice({
    name: 'builder',
    initialState: {
        mission: {

        },
    },
    reducers: {
        setBuilderMission: (state, action) => {
            state.mission = action.payload;
        },
        addQuestion: (state, action) => {
            console.log(action.payload)
            const { question } = action.payload;
            if(!state.mission.questions) {
                state.mission.questions = [];
            }
            if(!state.mission.questions.find(q => q.question === question)) {
                state.mission.questions.push(action.payload);
            } else {
                state.mission.questions = state.mission.questions.map(q => {
                    if(q.question === question) {
                        return action.payload;
                    }
                    return q;
                });
            }
        },
    },
});

export const { setBuilderMission, addQuestion } = scenarioBuilderSlice.actions;
export default scenarioBuilderSlice.reducer;
