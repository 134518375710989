import React, {useEffect} from 'react'
import TopBar from "../components/TopBar.jsx";
import Footer from "../components/Footer.jsx";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {Box, Typography} from "@mui/material";
import {getUserMissions} from "../utils/api/mission";
import {useSelector} from "react-redux";
import Missions from "../components/game/Missions.jsx";

const MyMissionsWrapper = styled(Box)({
    marginTop: '50px',
})

const MyMissionsTitle = styled(Typography)({

})

export default function SimulationGameMyMissions() {
    const { t } = useTranslation('ns1');
    const [missions, setMissions] = React.useState([])
    const [unapprovedMissions, setUnapprovedMissions] = React.useState([])
    const { user } = useSelector((state) => state.auth)

    useEffect(() => {
        const fetchSubmittedMissions = async () => {
            const { missions } = await getUserMissions(user._id)
            const approvedMissions = missions.filter(mission => mission.missionApproved)
            setMissions(approvedMissions)
            const unapprovedMissions = missions.filter(mission => !mission.missionApproved)
            setUnapprovedMissions(unapprovedMissions)
        }
        fetchSubmittedMissions()
    }, [])

    return (
        <>
            <TopBar isGame={true}/>
            <MyMissionsWrapper>
                {/*<MyMissionsTitle variant="h5">{t('my_missions')}</MyMissionsTitle>*/}
                {
                    user._id ?
                        <>
                            <Typography textAlign="center" gutterBottom variant="h4">Your unapproved missions</Typography>
                            <Missions missions={unapprovedMissions} componentType='user' />
                            <Typography textAlign="center" gutterBottom variant="h4">Your approved missions</Typography>
                            <Missions missions={missions} />
                        </>
                        :
                        <Typography textAlign="center" gutterBottom variant="h4">You must be logged in to see your missions!</Typography>
                }
            </MyMissionsWrapper>
            <Footer isGame={true}/>
        </>
    )
}