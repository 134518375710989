import { createSlice } from '@reduxjs/toolkit'

export const resourceSlice = createSlice({
    name: 'resource',
    initialState: {
        mapCoordinates: [],
    },
    reducers: {
        setCoordinates: (state, action) => {
            state.mapCoordinates = action.payload
        },
    },
})

export const { setCoordinates } = resourceSlice.actions
export default resourceSlice.reducer
