export const getThreads = async () => {
    try {
        const fetchUrl = `http://37.9.171.147:5000/forum/threads/get`;
        const response = await fetch(fetchUrl, {
            method: 'GET',
        })

        if (!response.ok) {
            response.message = 'Get threads failed'
        }

        return await response.json();
    } catch (error) {
        // Handle and log errors here
        throw error;
    }
}

export const addThread = async (thread) => {
    try {
        const fetchUrl = `http://37.9.171.147:5000/forum/thread/add`;
        const response = await fetch(fetchUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(thread)
        })

        if (!response.ok) {
            response.message = 'Add thread failed'
        }

        return await response.json();
    } catch (error) {
        // Handle and log errors here
        throw error;
    }
}

export const deleteThread = async (id) => {
    try {
        const fetchUrl = `http://37.9.171.147:5000/forum/thread/delete/${id}`;
        const response = await fetch(fetchUrl, {
            method: 'DELETE',
        })

        if (!response.ok) {
            response.message = 'Delete thread failed'
        }

        return await response.json();
    } catch (error) {
        // Handle and log errors here
        throw error;
    }
}

const editThread = async (id, title, text) => {
    try {
        const fetchUrl = `http://37.9.171.147:5000/forum/thread/edit/${id}`;
        const response = await fetch(fetchUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ title, text })
        })

        if (!response.ok) {
            response.message = 'Edit thread failed'
        }

        return await response.json();
    } catch (error) {
        // Handle and log errors here
        throw error;
    }
}

export const addComment = async (threadId, commentBody) => {
    try {
        const fetchUrl = `http://37.9.171.147:5000/forum/comment/add`;
        const response = await fetch(fetchUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ threadId, commentBody })
        })

        console.log(threadId, commentBody)

        if (!response.ok) {
            response.message = 'Add comment failed'
        }

        return await response.json();
    } catch (error) {
        // Handle and log errors here
        throw error;
    }
}