export function validateMissionQuestions(mission) {
    // Check if mission or mission.questions is undefined or null to avoid errors
    if (!mission || !mission.questions) {
        return false;
    }

    // Destructure the questionType from the first question in the mission
    // Assumes that the questionType is consistent across all questions in the mission
    const { questionType } = mission.questions[0];

    switch (questionType) {
        case 'ordered_list':
            return mission.questions.every((question) =>
                Array.isArray(question.correctAnswers) && question.correctAnswers.length === 0
            );
        default:
            return mission.questions.every((question) =>
                (Array.isArray(question.correctAnswers) && question.correctAnswers.length === 0) ||
                (Array.isArray(question.answers) && question.answers.length === 0)
            );
    }
}
