import React, { useState, useEffect } from 'react';
import {Formik, Form, Field, useField} from 'formik';
import { TextField, Button, Checkbox, FormControlLabel, Box, Alert } from '@mui/material';
import { useParams } from "react-router-dom";
import { getVCEventById, getVCResourceById, updateVCEvent, updateVCResource } from '../../utils/api/vc';
import TopBar from "../../components/TopBar.jsx";
import Footer from "../../components/Footer.jsx";
import {VCBackground, VCRoot} from "../../components/styles/virtualClassroom/VirtualClassroom.styled";
import { styled } from '@mui/material/styles';

const VCBackgroundStyled = styled(VCBackground)({
    padding: '20px',
})

const Wrapper = styled(Box)({
    marginTop: '50px',
    // margin: '0px 200px',
})

const StyledForm = styled(Form)({
    margin: 'auto',
    width: '400px',
})

export default function VCEditComponent() {
    const { id } = useParams();
    const [content, setContent] = useState(null);
    const [alertInfo, setAlertInfo] = useState({ show: false, severity: '', message: '' });
    const [initialValues, setInitialValues] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let fetchedContent;
                try {
                    const { vcEvent } = await getVCEventById(id);
                    setContent({ ...fetchedContent, isEvent: true });
                    setInitialValues({
                        name: vcEvent.name,
                        date: vcEvent.date,
                        description: vcEvent.description,
                        eventBody: vcEvent.eventBody,
                        consent: false
                    })
                } catch (eventError) {
                    const { vcResource } = await getVCResourceById(id);
                    console.log(vcResource);
                    setInitialValues({
                        name: vcResource.name,
                        description: vcResource.description,
                        consent: false,
                        resources: vcResource.resources
                    })
                    setContent({ ...fetchedContent, isEvent: false });
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [id]);

    const onSubmit = async (values, actions) => {
        try {
            if (content.isEvent) {
                await updateVCEvent(id, values);
                console.log(values)
            } else {
                await updateVCResource(id, values);
                // set error if there's any resources that are empty
                if(values.resources.some(resource => resource === '')) {
                    setAlertInfo({ show: true, severity: 'error', message: 'Error updating data. Please ensure all resource links are filled in.' });
                    return;
                }
                console.log(values)

            }
            actions.setSubmitting(false);
            setAlertInfo({ show: true, severity: 'success', message: 'Update successful!' });
        } catch (error) {
            actions.setSubmitting(false);
            setAlertInfo({ show: true, severity: 'error', message: 'Error updating data.' });
        }
    };

    if (!content) {
        return <div>Loading...</div>;
    }

    const ResourceField = ({ label, ...props }) => {
        const [field, , helpers] = useField(props);
        return (
            <TextField
                {...field}
                label={label}
                fullWidth
                margin="normal"
                onChange={(e) => {
                    helpers.setValue(e.target.value);
                }}
            />
        );
    };

    return (
        <Wrapper>
            <TopBar isGame={true} />
            <VCBackgroundStyled>
                <Formik initialValues={initialValues} onSubmit={onSubmit}>
                    {({ isSubmitting, setFieldValue, values }) => (
                        <StyledForm>
                            {content.isEvent ? (
                                <Box>
                                    <Field as={TextField} name="name" label="Event Name" fullWidth margin="normal" />
                                    <Field as={TextField} name="date" label="Date of Event" type="date" fullWidth margin="normal" InputLabelProps={{ shrink: true }} />
                                    <Field as={TextField} name="description" label="Description" fullWidth margin="normal" multiline />
                                    <Field as={TextField} name="eventBody" label="Event Body (URL)" fullWidth margin="normal" />
                                </Box>
                            ) : (
                                <Box>
                                    <Field as={TextField} name="name" label="Name" fullWidth margin="normal" />
                                    <Field as={TextField} name="description" label="Description" fullWidth margin="normal" multiline />
                                    {values.resources.map((resource, index) => (
                                        <ResourceField
                                            key={index}
                                            name={`resources[${index}]`}
                                            label={`Resource Link #${index + 1}`}
                                        />
                                    ))}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => setFieldValue('resources', [...values.resources, ''])}
                                    >
                                        Add Another Resource
                                    </Button>
                                </Box>
                            )}
                            {alertInfo.show && <Alert severity={alertInfo.severity}>{alertInfo.message}</Alert>}
                            <Button sx={{ width: '100px' }} type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                                Update
                            </Button>
                        </StyledForm>
                    )}
                </Formik>
            </VCBackgroundStyled>
            <Footer isGame={true} />
        </Wrapper>
    );
}