// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/assets/bg/6.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fc .fc-daygrid-day-frame{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.fc .fc-daygrid-day-top{color:#495057}.fc .fc-event{background-color:#007bff;border-color:#007bff;color:#fff;font-size:.85em;cursor:pointer}.fc .fc-toolbar-title{font-size:1.5em;color:#fff}.fc-event-time{display:none}.fc-daygrid-event-dot{display:none}.fc-event-title{margin-left:5px;white-space:break-spaces}", "",{"version":3,"sources":["webpack://./src/components/Calendar.css"],"names":[],"mappings":"AACA,0BACI,kDAAA,CAGJ,wBACI,aAAA,CAGJ,cACI,wBAAA,CACA,oBAAA,CACA,UAAA,CACA,eAAA,CACA,cAAA,CAGJ,sBACI,eAAA,CACA,UAAA,CAGJ,eACI,YAAA,CAGJ,sBACI,YAAA,CAGJ,gBACI,eAAA,CACA,wBAAA","sourcesContent":["/* MyCalendar.css */\n.fc .fc-daygrid-day-frame {\n    background: url(\"/public/assets/bg/6.png\");\n}\n\n.fc .fc-daygrid-day-top {\n    color: #495057;\n}\n\n.fc .fc-event {\n    background-color: #007bff;\n    border-color: #007bff;\n    color: white;\n    font-size: 0.85em;\n    cursor: pointer;\n}\n\n.fc .fc-toolbar-title {\n    font-size: 1.5em;\n    color: #fff;\n}\n\n.fc-event-time {\n    display: none;\n}\n\n.fc-daygrid-event-dot {\n    display: none;\n}\n\n.fc-event-title {\n    margin-left: 5px;\n    white-space: break-spaces;\n}\n\n/*.fc-col-header-cell {*/\n/*    width: 100px; !* Adjust this value to change the column width *!*/\n/*}*/\n\n/*.fc-daygrid-day-frame {*/\n/*    width: 100px; !* Adjust this value to change the day frame width *!*/\n/*}*/"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
