// App.js
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from "../../styles/theme";
import {Box, FormControl, InputLabel, MenuItem, Select, Typography} from "@mui/material";
import {addBadgesEarned, addQuestionAnswer} from "../../../slices/gameSlice";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import CancelIcon from '@mui/icons-material/Cancel';

const MobileContainer = styled(Box)({
    display: 'none',
    [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem 2rem',
    }
});

const MobileItem = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    width: '100%',
    alignItems: 'center',
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  width: 700px;
  border: 1px solid #ccc;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  flex-wrap: wrap;
  min-height: ${(props) => (props.itemsCount <= 3 ? '300px' : '500px')}
`;

const Item = styled.div`
  width: 200px;
  height: 200px;
  //margin: 5px;
  margin-bottom: 5px;
  background-color: ${(props) => (props.placed ? theme.palette.primary.main : theme.palette.primary.main)};
  font-weight: ${(props) => (props.placed ? 'bold' : 'normal')};
color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  flex-direction: column;
  background-image: url(${(props) => props.imgSrc});
`;

const PyramidSlot = styled(Box)`
  width: 200px;
      height: 200px;
  margin-bottom: 5px;
  border: ${(props) => (props.placed ? 'none' : '1px dashed gray')};
  background-color: ${(props) => (props.placed ? 'none' : theme.palette.secondary.main)};
  position: relative;
  &:hover {
    & > div {
      display: flex !important;
    }
  }
`;

const PyramidRow = styled.div`
  display: flex;
  justify-content: center;
`;

const ItemImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

const BoardContainer = styled(Box)({
    marginTop: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        display: 'none'
    }
})

function compareOrder(items, slots) {
    const renamedSlots = slots.map(({ id, value }) => ({ id, value }));

    for (let i = 0; i < items.length; i++) {
        if (items[i].value !== renamedSlots[i].value) {
            return false;
        }
    }
    return true;
}

function OrderedListQuestion({ question }) {
    const { t } = useTranslation('ns1');
    const dispatch = useDispatch();
    const [items, setItems] = useState(
        question.correctAnswers.map(({ value, imgSrc }, index) => ({
            value,
            imgSrc,
            placed: false,
        }))
    );
    const [slots, setSlots] = useState(
        Array.from({ length: items.length }, (_, i) => ({
            id: i + 1,
            value: null,
            imgSrc: null,
        }))
    );
    const awardBadges = () => {
        const itemsOrder = question.correctAnswers.map((question, index) => ({ value: question.value, imgSrc: question.imgSrc, placed: false }))
        console.log('itemsOrder', itemsOrder)
        if (compareOrder(itemsOrder, slots)) {
            dispatch(addBadgesEarned(1));
        }
    }

    useEffect(() => {
        const allQuestionsAnswered = slots.every(slot => slot.value !== null)
        if(allQuestionsAnswered) {
            const questionAnswer = {
                question: question.question,
                answers: slots.map((slot) => slot.value),
                correctAnswers: question.orderedListItems
            }
            dispatch(addQuestionAnswer(questionAnswer))
            awardBadges()
        }
    }, [question, slots]);

    useEffect(() => {
        // shuffle items on component mount
        setItems((items) => {
            let shuffledItems = [...items];
            for (let i = shuffledItems.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [shuffledItems[i], shuffledItems[j]] = [
                    shuffledItems[j],
                    shuffledItems[i],
                ];
            }
            return shuffledItems;
        })
    }, []);

    useEffect(() => {
        console.log('slts', slots)
    }, [slots]);

    const onDrop = (e, slotId) => {
        const itemValue = e.dataTransfer.getData('itemValue');
        const itemIndex = items.findIndex((item) => item.value === itemValue);
        const slotIndex = slots.findIndex((slot) => slot.id === slotId);

        // Check if the slot is empty
        if (slotIndex > -1 && slots[slotIndex].value === null) {
            if (itemIndex > -1) {
                let updatedItems = [...items];
                updatedItems[itemIndex].placed = true;

                let updatedSlots = [...slots];
                updatedSlots[slotIndex].value = itemValue;
                updatedSlots[slotIndex].imgSrc = items[itemIndex].imgSrc;

                setItems(updatedItems);
                setSlots(updatedSlots);
            }
        }
    };

    const onDragStart = (e, value) => {
        e.dataTransfer.setData('itemValue', value);
    };

    const onDragOver = (e) => {
        e.preventDefault();
    };

    const returnItem = (itemValue) => {
        const itemIndex = items.findIndex((item) => item.value === itemValue);
        const slotIndex = slots.findIndex((slot) => slot.value === itemValue);

        if (itemIndex > -1 && slotIndex > -1) {
            let updatedItems = [...items];
            updatedItems[itemIndex].placed = false;

            let updatedSlots = [...slots];
            updatedSlots[slotIndex].value = null;

            setItems(updatedItems);
            setSlots(updatedSlots);
        }
    };

    const handleMobileSelectChange = (item, event) => {
        const index = event.target.value - 1; // index starts from 0
        let updatedSlots = [...slots];
        updatedSlots[index].value = item.value;
        setSlots(updatedSlots);
    };

    return (
        <Wrapper>
            <Typography variant="h6" textAlign="center" color="common.orange" fontWeight="bold" gutterBottom>{t(`question_guidance_${question.questionType}_text`)}</Typography>
            <Typography variant="h6" textAlign="center" color="white" fontWeight="bold" gutterBottom>{question.question}</Typography>
            <BoardContainer>
                <Container itemsCount={items.length}>
                    {items.map((item) =>
                        !item.placed ? (
                            <Item
                                key={item.value}
                                draggable={true}
                                onDragStart={(e) => onDragStart(e, item.value)}
                                imgSrc={`http://37.9.171.147:5000/public${item.imgSrc}`}
                            >
                                <Box sx={{ height: '30px', background: 'rgba(0, 0, 0, 0.4)', width: '100%' }}>
                                    <Typography textAlign="center" sx={{ pt: '3px' }}>{item.value}</Typography>
                                </Box>
                            </Item>
                        ) : null
                    )}
                </Container>
                <Box>
                    <img src="public/assets/game/dragndrop.png" alt="arrow" style={{ width: '118px', height: '73px', margin: '0 2rem' }} />
                    <Typography variant="h6" textAlign="center" color="white" fontWeight="bold">Drag and drop</Typography>
                </Box>
                <Container onDrop={(e) => onDrop(e, null)} onDragOver={(e) => onDragOver(e)}>
                    {slots.map((slot, index) => (
                        <PyramidRow key={index}>
                            <PyramidSlot
                                value={slot.value}
                                onDrop={(e) => onDrop(e, slot.id)}
                                onDragOver={(e) => onDragOver(e)}
                                placed={slot.value}
                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            >
                                {slot.value ? (
                                    <>
                                        <Box sx={{ display: 'none', position: 'absolute', top: 0, right: 0, zIndex: 2 }}>
                                            <CancelIcon sx={{ color: 'error.main', cursor: 'pointer', width: '36px', height: '36px' }} onClick={() => returnItem(slot.value)}/>
                                        </Box>
                                        <Item
                                            placed
                                            draggable={true}
                                            onDragStart={(e) => onDragStart(e, slot.value)}
                                            onClick={() => returnItem(slot.value)}
                                            imgSrc={`http://37.9.171.147:5000/public${slot.imgSrc}`}
                                        >
                                            <Box sx={{ height: '30px', background: 'rgba(0, 0, 0, 0.4)', width: '100%' }}>
                                                <Typography textAlign="center" sx={{ pt: '3px' }}>{slot.value}</Typography>
                                            </Box>
                                        </Item>
                                    </>
                                ) : (
                                    <Typography variant="h6" color="textSecondary"> {index + 1} </Typography>
                                )}
                            </PyramidSlot>
                        </PyramidRow>
                    ))}
                </Container>
            </BoardContainer>
            <MobileContainer>
                {items.map((item, index) => (
                    <MobileItem key={index}>
                        <Box>
                            <img src={'public/assets/bg/1.png'} alt="mobile item" style={{ width: '150px', height: '150px' }} />
                            <Box sx={{ height: '30px', background: 'rgba(0, 0, 0, 0.4)', width: '100%' }}>
                                <Typography textAlign="center" sx={{ pt: '3px' }}>{item.value}</Typography>
                            </Box>
                        </Box>
                        <FormControl variant="outlined" style={{ minWidth: '120px' }}>
                            <InputLabel sx={{ color: 'white' }}>Order</InputLabel>
                            <Select
                                label="Order"
                                value={slots.findIndex(slot => slot.value === item.value) + 1}
                                onChange={(e) => handleMobileSelectChange(item, e)}
                                sx={{ color: 'white' }}
                            >
                                {Array.from({ length: items.length }, (_, i) => i + 1).map((num) => (
                                    <MenuItem key={num} value={num}>{num}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </MobileItem>
                ))}
            </MobileContainer>
        </Wrapper>
    );
}

export default OrderedListQuestion;
