import React from 'react'
import {useTranslation} from "react-i18next";
import TopBar from "../components/TopBar.jsx";
import Footer from "../components/Footer.jsx";
import {Box, Typography} from "@mui/material";
import styled from 'styled-components'
import RenderTextIntoParagraphs from "../utils/insertLineBreaks.jsx";

const Root = styled(Box)({
    marginTop: '50px',
})

const Wrapper = styled(Box)({
    margin: '0 400px',
    display: 'flex',
    justifyContent: 'center'
})

export default function TermsAndConditions() {
    const { t } = useTranslation('ns1')

    return (
        <>
            <TopBar/>
            <Root>
                <Typography variant="h4" textAlign="center" sx={{ padding: '2rem 6rem 1rem 6rem' }}>{t('terms_and_conditions')}</Typography>
                <Wrapper>
                    <Box>
                        <RenderTextIntoParagraphs translationKey="terms_and_conditions1" paragraphsBeforeLineBreak={2}/>
                        <Typography variant="h6" fontWeight="bold" gutterBottom>{t('terms_and_conditions_title1')}</Typography>
                        <Typography sx={{ fontSize: '15px' }} gutterBottom>{t('terms_and_conditions2')}</Typography>
                        <Typography sx={{ fontSize: '15px' }}>• {t('terms_and_conditions3')}</Typography>
                        <Typography sx={{ fontSize: '15px' }}>• {t('terms_and_conditions4')}</Typography>
                        <Typography sx={{ fontSize: '15px' }}>• {t('terms_and_conditions5')}</Typography>
                        <Typography sx={{ fontSize: '15px' }}>• {t('terms_and_conditions6')}</Typography>
                        <Typography sx={{ fontSize: '15px' }}>• {t('terms_and_conditions7')}</Typography>
                        <Typography sx={{ fontSize: '15px' }}>• {t('terms_and_conditions8')}</Typography>
                        <Typography sx={{ fontSize: '15px' }} gutterBottom>{t('terms_and_conditions9')}</Typography>
                        <Typography sx={{ fontSize: '15px' }}>• {t('terms_and_conditions10')}</Typography>
                        <Typography sx={{ fontSize: '15px' }}>• {t('terms_and_conditions11')}</Typography>
                        <Typography sx={{ fontSize: '15px' }}>• {t('terms_and_conditions12')}</Typography>
                        <Typography sx={{ fontSize: '15px' }}>• {t('terms_and_conditions13')}</Typography>
                        <RenderTextIntoParagraphs translationKey="terms_and_conditions14" paragraphsBeforeLineBreak={1}/>
                        <Typography variant="h6" fontWeight="bold" gutterBottom>{t('terms_and_conditions_title2')}</Typography>
                        <RenderTextIntoParagraphs translationKey="terms_and_conditions15" paragraphsBeforeLineBreak={2}/>
                        <Typography variant="h6" fontWeight="bold" gutterBottom>{t('terms_and_conditions_title3')}</Typography>
                        <RenderTextIntoParagraphs translationKey="terms_and_conditions16" paragraphsBeforeLineBreak={2}/>
                        <Typography sx={{ fontSize: '15px' }}>• {t('terms_and_conditions17')}</Typography>
                        <Typography sx={{ fontSize: '15px' }}>• {t('terms_and_conditions18')}</Typography>
                        <Typography sx={{ fontSize: '15px' }}>• {t('terms_and_conditions19')}</Typography>
                        <Typography sx={{ fontSize: '15px' }}>• {t('terms_and_conditions20')}</Typography>
                        <Typography sx={{ fontSize: '15px' }}>• {t('terms_and_conditions21')}</Typography>
                        <Typography sx={{ fontSize: '15px' }}>• {t('terms_and_conditions22')}</Typography>
                        <Typography sx={{ fontSize: '15px' }} gutterBottom>• {t('terms_and_conditions23')}</Typography>
                        <RenderTextIntoParagraphs translationKey="terms_and_conditions24" paragraphsBeforeLineBreak={2}/>
                        <Typography sx={{ fontSize: '15px' }}>• {t('terms_and_conditions25')}</Typography>
                        <Typography sx={{ fontSize: '15px' }}>• {t('terms_and_conditions26')}</Typography>
                        <Typography sx={{ fontSize: '15px' }}>• {t('terms_and_conditions27')}</Typography>
                        <Typography sx={{ fontSize: '15px' }}>• {t('terms_and_conditions28')}</Typography>
                        <Typography sx={{ fontSize: '15px' }} gutterBottom>• {t('terms_and_conditions29')}</Typography>
                        <RenderTextIntoParagraphs translationKey="terms_and_conditions30" paragraphsBeforeLineBreak={1}/>
                        <Typography variant="h6" fontWeight="bold" gutterBottom>{t('terms_and_conditions_title4')}</Typography>
                        <RenderTextIntoParagraphs translationKey="terms_and_conditions31" paragraphsBeforeLineBreak={2}/>
                        <Typography variant="h6" fontWeight="bold" gutterBottom>{t('terms_and_conditions_title5')}</Typography>
                        <RenderTextIntoParagraphs translationKey="terms_and_conditions32" paragraphsBeforeLineBreak={2}/>
                        <Typography variant="h6" fontWeight="bold" gutterBottom>{t('terms_and_conditions_title6')}</Typography>
                        <RenderTextIntoParagraphs translationKey="terms_and_conditions33" paragraphsBeforeLineBreak={2}/>
                        <Typography variant="h6" fontWeight="bold" gutterBottom>{t('terms_and_conditions_title7')}</Typography>
                        <RenderTextIntoParagraphs translationKey="terms_and_conditions34" paragraphsBeforeLineBreak={2}/>
                        <Typography sx={{ fontSize: '15px' }}>• {t('terms_and_conditions35')}</Typography>
                        <Typography sx={{ fontSize: '15px' }}>• {t('terms_and_conditions36')}</Typography>
                        <Typography sx={{ fontSize: '15px' }}>• {t('terms_and_conditions37')}</Typography>
                        <Typography variant="h6" fontWeight="bold" gutterBottom>{t('terms_and_conditions_title8')}</Typography>
                        <RenderTextIntoParagraphs translationKey="terms_and_conditions39" paragraphsBeforeLineBreak={2}/>
                        <Typography variant="h6" fontWeight="bold" gutterBottom>{t('terms_and_conditions_title9')}</Typography>
                        <RenderTextIntoParagraphs translationKey="terms_and_conditions40" paragraphsBeforeLineBreak={2}/>
                    </Box>
                </Wrapper>
            </Root>
            <Footer/>
        </>
    )
}