// react fn component example
import React from 'react';
import {useTranslation} from "react-i18next";
import TopBar from "../../components/TopBar.jsx";
import Footer from "../../components/Footer.jsx";
import {
    VCBackground,
    VCFlexButtonsContainer,
    VCRoot
} from "../../components/styles/virtualClassroom/VirtualClassroom.styled";
import { Formik, Form, Field } from 'formik';
import {
    TextField,
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    FormControlLabel,
    Typography, Box
} from '@mui/material';
import { useState } from 'react';
import { createVCEvent, createVCResource } from '../../utils/api/vc'
import Alert from '@mui/material/Alert';
import {useSelector} from "react-redux";
import Disclaimer from "../../components/Disclaimer.jsx";
import styled from "styled-components";
import theme from "../../components/styles/theme";

const StyledVCRoot = styled(VCRoot)({
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '12px'
    }
})

export default function VCCreateEvent() {
    const { t } = useTranslation('ns1');

    return (
        <VCBackground>
            <TopBar isGame={true}/>
            <StyledVCRoot>
                <Typography variant="h5" textAlign="center" gutterBottom sx={{ mt: 2 }}>{t('create_event_heading')}</Typography>
                <EventResourceForm />
            </StyledVCRoot>
            <Footer />
        </VCBackground>
    )
}

const EventResourceForm = () => {
    const [formType, setFormType] = useState('event');
    const [alertInfo, setAlertInfo] = useState({ show: false, severity: '', message: '' });
    const { user } = useSelector((state) => state.auth);
    const { t } = useTranslation('ns1');

    const handleFormTypeChange = (event) => {
        setFormType(event.target.value);
    };

    const initialValuesEvent = {
        eventName: '',
        eventDate: '',
        description: '',
        eventBody: '',
        consent: false
    };

    const initialValuesResource = {
        name: '',
        description: '',
        consent: false,
        resources: ['']
    };

    const onSubmitEvent = async (values, actions) => {
        try {
            const request = {
                name: values.eventName,
                date: values.eventDate,
                description: values.description,
                eventBody: values.eventBody,
                consent: values.consent,
                eventType: 'meet',
                ownerId: user._id,
            }
            console.log(values);
            await createVCEvent(request);
            actions.setSubmitting(false);
            setAlertInfo({ show: true, severity: 'success', message: t('event_to_be_approved_text') });
            setTimeout(() => setAlertInfo({ show: false, severity: '', message: '' }), 5000);
            // clear values
            const newValues = { ...values };
            newValues.resources = [''];
            newValues.eventName = '';
            newValues.eventDate = '';
            newValues.description = '';
            newValues.eventBody = '';
            newValues.consent = false;
            actions.resetForm({ values: newValues });
        } catch (error) {
            actions.setSubmitting(false);
            setAlertInfo({ show: true, severity: 'error', message: 'Error creating event.' });
            setTimeout(() => setAlertInfo({ show: false, severity: '', message: '' }), 5000);
        }
    };

    const onSubmitResource = async (values, actions) => {
        try {
            const request = {
                name: values.name,
                date: new Date(),
                description: values.description,
                eventBody: values.eventBody,
                consent: values.consent,
                type: 'user',
                resources: values.resources,
                ownerId: user._id,
            }
            console.log(values)
            await createVCResource(request);
            actions.setSubmitting(false);
            setAlertInfo({ show: true, severity: 'success', message: t('resource_to_be_approved_text') });
            setTimeout(() => setAlertInfo({ show: false, severity: '', message: '' }), 5000);
            // clear values
            const newValues = { ...values };
            newValues.resources = [''];
            newValues.name = '';
            newValues.description = '';
            newValues.consent = false;
            newValues.eventBody = '';
            newValues.date = '';
            newValues.type = '';
            actions.resetForm({ values: newValues });
        } catch (error) {
            actions.setSubmitting(false);
            setAlertInfo({ show: true, severity: 'error', message: 'Error publishing resource.' });
            setTimeout(() => setAlertInfo({ show: false, severity: '', message: '' }), 5000);
        }
    };

    return (
        <>
            <FormControl fullWidth>
                <InputLabel id="form-type-select-label">Choose Action</InputLabel>
                <Select
                    labelId="form-type-select-label"
                    id="form-type-select"
                    value={formType}
                    label="Choose Action"
                    onChange={handleFormTypeChange}
                >
                    <MenuItem value="event">Organise an Online Event</MenuItem>
                    <MenuItem value="resource">Publish</MenuItem>
                </Select>
            </FormControl>

            {formType === 'event' && (
                <Formik initialValues={initialValuesEvent} onSubmit={onSubmitEvent}>
                    {({ isSubmitting }) => (
                        <Form>
                            <Field as={TextField} name="eventName" label="Event Name" fullWidth margin="normal" />
                            <Field as={TextField} name="eventDate" label="Date of Event" type="date" fullWidth margin="normal" InputLabelProps={{ shrink: true }} />
                            <Field as={TextField} name="description" label="Description" fullWidth margin="normal" multiline />
                            <Field as={TextField} name="eventBody" label="Event Body (URL)" fullWidth margin="normal" />
                            {alertInfo.show && <Alert severity={alertInfo.severity}>{alertInfo.message}</Alert>}
                            <VCFlexButtonsContainer sx={{ justifyContent: 'space-between !important' }}>
                                <Box>
                                    <Field as={Checkbox} type="checkbox" name="consent" />
                                    <label>Consent to terms and conditions</label>
                                </Box>
                                <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                                    Submit
                                </Button>
                            </VCFlexButtonsContainer>
                        </Form>
                    )}
                </Formik>
            )}

            {formType === 'resource' && (
                <Formik initialValues={initialValuesResource} onSubmit={onSubmitResource}>
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Field as={TextField} name="name" label="Name" fullWidth margin="normal" />
                            <Field as={TextField} name="description" label="Description" fullWidth margin="normal" multiline />
                            {values.resources.map((resource, index) => (
                                <TextField
                                    key={index}
                                    value={resource}
                                    onChange={e => {
                                        const newResources = [...values.resources];
                                        newResources[index] = e.target.value;
                                        setFieldValue('resources', newResources);
                                    }}
                                    label={`Resource Link #${index + 1}`}
                                    fullWidth
                                    margin="normal"
                                />
                            ))}
                            <Box>
                                <Field as={Checkbox} type="checkbox" name="consent" />
                                <label>Consent to terms and conditions</label>
                            </Box>
                            {alertInfo.show && <Alert severity={alertInfo.severity}>{alertInfo.message}</Alert>}
                            <VCFlexButtonsContainer sx={{ justifyContent: 'space-between !important' }}>
                                <Button
                                    type="button"
                                    onClick={() => setFieldValue('resources', [...values.resources, ''])}
                                    variant="contained"
                                >
                                    Add Another Resource
                                </Button>
                                <Button type="submit" variant="contained" color="primary">
                                    Submit
                                </Button>
                            </VCFlexButtonsContainer>
                        </Form>
                    )}
                </Formik>
            )}
            <Disclaimer color="white" disclaimerKey="disclaimer_create_event" />
        </>
    );
};
