import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Box, Card, CardContent, Typography, Button } from '@mui/material';
import styled from 'styled-components';
import { getMaterialById } from '../../utils/api/vc'; // Update with actual import
import FileDownloadIcon from '@mui/icons-material/FileDownload'; // Import appropriate icons for each type
import TopBar from "../../components/TopBar.jsx";
import Footer from "../../components/Footer.jsx";
import {VCBackground} from "../../components/styles/virtualClassroom/VirtualClassroom.styled";
import VideoCallIcon from '@mui/icons-material/VideoCall';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import SettingsIcon from '@mui/icons-material/Settings';

const MaterialBox = styled(Box)`
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  
`;

const ResourceCard = styled(Card)`
    //width: 300px;
  flex-wrap: wrap;
    padding: 5px;
`;

const Wrapper = styled(Box)`
    height: 100vh;
    margin-top: 50px;
`;

const FlexBox = styled(Box)`
  display: flex;
  justify-content: space-between;
    align-items: center;
`;

const StyledCardContent = styled(CardContent)`
    padding: 5px !important; 
    
`;

export const VCViewMaterial = () => {
    const { id } = useParams();
    const [material, setMaterial] = useState(null);

    useEffect(() => {
        const fetchMaterial = async () => {
            const { material } = await getMaterialById(id); // Update with actual API call
            console.log(material)
            setMaterial(material);
        };

        fetchMaterial();
    }, [id]);

    const getResourceIcon = (type) => {
        // Return appropriate icon based on type
        // Example:
        switch (type) {
            case 'pdf': return <PictureAsPdfIcon />;
            case 'video': return <VideoCallIcon />;
            case 'audio': return <AudioFileIcon />;
            case 'image': return <ImageIcon />;
            case 'other': return <SettingsIcon />;
            default: return <FileDownloadIcon />;
        }
    };

    return (
        <Wrapper>
            <VCBackground sx={{ height: '50%' }}>
                <TopBar isGame={true} />
                <Typography variant="h4" gutterBottom textAlign="center" sx={{ marginTop: '10px' }}>{material?.name}</Typography>
                <MaterialBox>
                    {material && (
                        <>
                            {/*<img src={material.icon} alt={material.name} style={{ width: '100px', height: 'auto' }} />*/}
                            {material.resources.map((resource, index) => (
                                <Box sx={{ width: '200px' }}>
                                    <Typography variant="h6" textAlign="center">{resource.name}</Typography>
                                    <ResourceCard key={index}>
                                        <StyledCardContent>
                                            <FlexBox>
                                                {getResourceIcon(resource.type)}
                                                <Button href={resource.link} variant="contained" target="_blank">Download</Button>
                                            </FlexBox>
                                        </StyledCardContent>
                                    </ResourceCard>
                                </Box>
                            ))}
                        </>
                    )}
                </MaterialBox>
            </VCBackground>
            <Footer isGame={true} />
        </Wrapper>
    );
};

export default VCViewMaterial;