import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import {Card, CardActionArea, CardContent, Typography, Box, styled, Button} from '@mui/material';
import { listMaterials } from '../../utils/api/vc';
import { useNavigate } from "react-router-dom";
import TopBar from "../../components/TopBar.jsx";
import Footer from "../../components/Footer.jsx";
import {VCBackground} from "../../components/styles/virtualClassroom/VirtualClassroom.styled";

const StyledCard = styled(Card)({
    margin: '20px',
    padding: '5px',
    width: '300px',
    // height: '200px',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
});

const StyledBox = styled(VCBackground)({
    height: '100vh',
    marginTop: '50px',
})

const StyledImg = styled('img')({
    width: '100px',
    height: '100px',
    margin: 'auto',
    display: 'block',
});

const VCMaterialsList = () => {
    const { t } = useTranslation('ns1');
    const [materials, setMaterials] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMaterials = async () => {
            try {
                const { materials } = await listMaterials();
                setMaterials(materials);
                console.log(materials)
            } catch (error) {
                console.error(error);
            }
        };
        fetchMaterials();
    }, []);

    return (
        <StyledBox>
            <TopBar isGame={true} />
            <Button variant="contained" color="secondary" onClick={() => navigate('/interact/explore/digitheca')} sx={{ mt: '20px', ml: '20px' }}>{t('digitheca')}</Button>
            <Box sx={{ pt: 1, display: 'flex', flexWrap: 'wrap' }}>
                {materials && materials.map((material) => (
                    <StyledCard key={material._id} onClick={() => navigate(`/interact/explore/view-material/${material._id}`)}>
                        <CardActionArea>
                            <CardContent>
                                <StyledImg src={material.icon} alt={material.name} />
                                <Typography variant="h5" sx={{ color: 'black', textAlign: 'center', fontWeight: 'bold', marginTop: '5px' }}>{material.name}</Typography>
                            </CardContent>
                        </CardActionArea>
                    </StyledCard>
                ))}
            </Box>
            <Footer isGame={true} />
        </StyledBox>
    );
};

export default VCMaterialsList;
