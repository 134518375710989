import React, {useState} from 'react'
import LeftNavigation from "../../components/admin/LeftNavigation.jsx";
import {AdminRoot} from "../../components/styles/AdminPanel.styled";
export default function AdminPanelHome() {

    return (
        <div>
            <LeftNavigation/>
            <AdminRoot>
                Admin Panel Home
            </AdminRoot>
        </div>
    )
}

