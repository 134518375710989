import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const Disclaimer = ({ disclaimerKey, color }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { t } = useTranslation('ns1');
    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <Box>
            <Box display="flex" alignItems="center" onClick={handleToggle} sx={{ cursor: 'pointer', userSelect: 'none' }}>
                <IconButton sx={{ '& .MuiSvgIcon-root': { color: `${color} !important` } }} size="small">
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
                <Typography variant="body1">{t('disclaimer_preview_text')}</Typography>
            </Box>
            {isExpanded && (
                <Typography variant="body2" sx={{ marginTop: 2 }}>
                    {t(disclaimerKey)}
                </Typography>
            )}
        </Box>
    );
};

export default Disclaimer;
