import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import React, { useEffect, useState } from 'react';
import { getTeam } from '../utils/api/team';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/system';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, A11y } from 'swiper';
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";

const SwiperSlideStyled = styled(SwiperSlide)(({ theme, imgpath }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'normal',
    justifyContent: 'flex-end',
    width: '20%',
    height: '100%',
    background: `linear-gradient(to bottom right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    color: theme.palette.common.white,
    backgroundImage: `url(${imgpath})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    margin: '0 auto',
    padding: '0',
    // Add more styles as needed
}));

const ProfileImage = styled('img')({
    position: 'absolute',
    zIndex: '-1000',
});

const FirstName = styled(Typography)({
    fontSize: '1.5rem',
    fontWeight: 'bold',
})

const TeamContainer = styled(Box)({
    padding: '1rem',
    background: 'rgba(0, 0, 0, 0.5)',
})

export const TeamSlider = () => {
    const [teamMembers, setTeamMembers] = useState([]);
    const [t, i18n] = useTranslation('ns1');

    useEffect(() => {
        const fetchTeamMembers = async () => {
            const { teamMembers } = await getTeam();
            setTeamMembers(teamMembers);
        };
        fetchTeamMembers();
    }, []);

    return (
        <Swiper
            modules={[Navigation, Pagination, A11y]}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            style={{ width: '80%', height: '350px' }} // Apply styles directly to the Swiper container
            breakpoints={{
                1520: {
                    slidesPerView: 5,
                },
                1360: {
                    slidesPerView: 4,
                },
                920: {
                    slidesPerView: 3,
                },
                800: {
                    slidesPerView: 2,
                },
            }}
        >
            {teamMembers.map((teamMember) => (
                <SwiperSlideStyled imgpath={teamMember.imgPath} key={uuidv4()}>
                    <TeamContainer>
                        <FirstName>{t(teamMember.firstName)}</FirstName>
                        <Typography sx={{ mb: 2 }}>{t(teamMember.lastName)}</Typography>
                        <Typography>{t(teamMember.jobTitle)}</Typography>
                        <Typography sx={{ fontSize: '13px' }}>at {t(teamMember.companyName)}</Typography>
                    </TeamContainer>
                </SwiperSlideStyled>
            ))}
            ...
        </Swiper>
    );
};
