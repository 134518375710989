import React, { useEffect, useState } from 'react';
import { Typography, Box, TextField, Select, MenuItem } from "@mui/material";
import { useDispatch } from "react-redux";
import { addAnswerToQuestion, addBadgesEarned, addQuestionAnswer } from "../../../slices/gameSlice";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import theme from "../../styles/theme";

const QuestionWrapper = styled(Box)({
    width: '680px',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '1.5rem',
    }
})

function FillInTheBlanksDropdown({ question }) {
    const { t } = useTranslation('ns1');
    const [userAnswers, setUserAnswers] = useState(Array(question.correctAnswers.length).fill(''));
    const dispatch = useDispatch();
    const userAnswersRef = React.useRef([])
    const [answerObj, setAnswerObj] = useState({});

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const awardBadges = () => {
        const correctAnswersCount = question.correctAnswers.filter((answer, index) => {
            return answer === userAnswersRef.current[index];
        }).length;
        dispatch(addBadgesEarned(correctAnswersCount));
    };

    useEffect(() => {
        const questionAnswer = {
            question: question.question,
            answers: [],
        };
        dispatch(addQuestionAnswer(questionAnswer));

        return () => {
            awardBadges();
        };
    }, [question]);

    const handleInputChange = (index, value) => {
        const updatedAnswers = [...userAnswers];
        updatedAnswers[index] = value;

        setUserAnswers(updatedAnswers);
        userAnswersRef.current = updatedAnswers;

        const answerObj = {
            title: question.question,
            answer: updatedAnswers
        };
        setAnswerObj(answerObj)
        console.log(answerObj)
    };

    const renderTextWithBlanks = () => {
        const splitByPunctuation = (text) => text.split(/([!.,;?])/).filter(Boolean);
        const initialSegments = question.question.split(/(\s+)/);
        const segments = [];

        initialSegments.forEach(segment => {
            if (/\s/.test(segment)) {
                segments.push(segment);
            } else {
                segments.push(...splitByPunctuation(segment));
            }
        });

        const formattedSegments = segments.reduce((acc, segment) => {
            const index = question.correctAnswers.indexOf(segment);
            if (index !== -1) {
                acc.push(`{${index}}`);
            } else {
                acc.push(segment);
            }
            return acc;
        }, []);

        return formattedSegments.map((segment, index) => {
            const match = segment.match(/{(\d+)}/);
            if (match) {
                const answerIndex = parseInt(match[1]);
                const optionsWithCorrectAnswer = [...question.options[answerIndex]];
                if (!optionsWithCorrectAnswer.includes(question.correctAnswers[answerIndex])) {
                    optionsWithCorrectAnswer.push(question.correctAnswers[answerIndex]);
                }
                const shuffledOptions = shuffleArray(optionsWithCorrectAnswer);
                return (
                    <Select
                        key={index}
                        value={userAnswers[answerIndex] || ''}
                        onChange={(e) => handleInputChange(answerIndex, e.target.value)}
                        variant="outlined"
                        sx={{ margin: '0 4px', height: '33px', width: '150px', color: 'white' }}
                    >
                        {shuffledOptions.map((option, idx) => (
                            <MenuItem key={idx} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                );
            } else {
                return segment;
            }
        });
    };

    return (
        <QuestionWrapper
            display="flex"
            flexDirection="column"
            alignItems="center"
        >
            <Typography variant="h6" textAlign="center" color="common.orange" fontWeight="bold" gutterBottom>{t(`question_guidance_${question.questionType}_text`)}</Typography>
            {/*<Typography variant="h6" textAlign="center" color="white" fontWeight="bold" gutterBottom>{question.question}</Typography>*/}
            <Typography variant="h6" textAlign="justify" sx={{ lineHeight: 2.5, fontSize: '1.15rem', color: 'white' }}>
                {renderTextWithBlanks()}
            </Typography>
        </QuestionWrapper>
    );
}

export default FillInTheBlanksDropdown;
