import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {Alert, AlertTitle, Box, Button, TextField} from "@mui/material";
import {FormRoot} from "./styles/FormComponents.styled";
import {login} from "../utils/api/auth";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {login as loginAction} from "../slices/authSlice";
import {useNavigate} from "react-router-dom";

const loginSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
});

const LoginForm = () => {
    const [status, setStatus] = useState('idle');
    const [errorMessage, setErrorMessage] = useState('');
    const [timeOutId, setTimeOutId] = useState(null);

    const dispatch = useDispatch();
    const [t, i18n] = useTranslation('ns1');
    const navigate = useNavigate();

    useEffect(() => {
        return () => clearTimeout(timeOutId);
    }, [timeOutId])

    const formik = useFormik({
        initialValues: {
            email: '9kaloyan.angelkov@gmail.com', // Add initial value for email
            password: '12345678kK', // Add initial value for password
        },
        validationSchema: loginSchema,
        onSubmit: async (values) => {
            setStatus('submitting');
            const res = await login(values.email, values.password);
            if (res.success) {
                dispatch(loginAction(res.user))
                setStatus('success');
                const id = setTimeout(() => {
                    formik.resetForm();
                    navigate('/')

                }, 2000);
                setTimeOutId(id)
            } else {
                setStatus('error');
                setErrorMessage(res.message);
            }
        },
    });

    const handleGoBack = () => setStatus('idle')

    const renderFormContent = () => {
        switch (status) {
            case 'idle':
                return (
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            fullWidth
                            id="email"
                            name="email"
                            label="Email"
                            value={formik.values?.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                            sx={{ marginBottom: 1 }}
                        />
                        <TextField
                            fullWidth
                            id="password"
                            name="password"
                            label="Password"
                            type="password"
                            value={formik.values?.password}
                            onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                            sx={{ marginBottom: 1 }}

                        />
                        <Button color="primary" variant="contained" fullWidth type="submit">
                            Sign in
                        </Button>
                    </form>
                );
            case 'success':
                return (
                    <Alert sx={{ width: '75%' }} severity="success">
                        <AlertTitle> {t('login_success_title')} </AlertTitle>
                        {t('login_success_message')}
                    </Alert>
                );
            case 'error':
                return (
                    <Box sx={{ width: '75%' }}>
                        <Alert severity="error">
                            <AlertTitle sx={{ fontWeight: 'bold' }}> {t('login_error_title')} </AlertTitle>
                            {errorMessage}
                        </Alert>
                        <Button sx={{ mt: 1 }} color="secondary" variant="contained" fullWidth type="submit" onClick={handleGoBack}>
                            {t('go_back_text')}
                        </Button>
                    </Box>
                );
            default:
                return null;
        }
    }

    return <>{renderFormContent()}</>;
}

export default LoginForm;
