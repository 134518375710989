import React, { useState } from "react";
import { Page, Document, pdfjs } from "react-pdf";
import { styled } from "@mui/material/styles";
import { Button, IconButton, Typography } from "@mui/material";
import { NavigateBefore, NavigateNext, GetApp } from "@mui/icons-material";
import { v4 as uuid } from "uuid";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import {FlexContainer} from "./styles/Containers.styled";
import theme from "./styles/theme";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const StyledPDFViewer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
    },
    marginTop: '2rem',
    justifyContent: 'space-around',
})

const SinglePDFPreview = ({ pdf, pdfPath }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [numPages, setNumPages] = useState(null);

    const handleDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, numPages));
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleDownload = () => {
        window.open(`${pdfPath}${pdf}`,'_blank')
    };

    const isDownMd = theme.breakpoints.down('md');

    return (
        <React.Fragment key={uuid()}>
            <Document loading={<div styles={{ width: '500', height: '600' }}>Loading...</div>} file={`${pdfPath}${pdf}`} onLoadSuccess={handleDocumentLoadSuccess}>
                <Page pageNumber={currentPage} width={isDownMd ? 250 : 500} height={isDownMd ? 500 : 600}>
                    <FlexContainer flexDirection="row" justifyContent="space-between" sx={{ mt: 1, mb: 4 }}>
                        <Button variant="contained" startIcon={<GetApp />} onClick={handleDownload}>
                            Download
                        </Button>
                        <FlexContainer flexDirection="row" alignItems="center">
                            <IconButton onClick={handlePrevPage} disabled={currentPage <= 1}>
                                <NavigateBefore />
                            </IconButton>
                            <Typography variant="body1">{currentPage}/{numPages}</Typography>
                            <IconButton onClick={handleNextPage} disabled={currentPage >= numPages}>
                                <NavigateNext />
                            </IconButton>
                        </FlexContainer>
                    </FlexContainer>
                </Page>
            </Document>
        </React.Fragment>
    )
}

const ShowPdfPreview = ({ pdfFiles, pdfPath = '/public/pdf/' }) => {
    return (
        <StyledPDFViewer>
            {pdfFiles?.map((pdf) => (
                <SinglePDFPreview key={pdf} pdf={pdf} pdfPath={pdfPath} />
            ))}
        </StyledPDFViewer>
    );
};

export default ShowPdfPreview;
