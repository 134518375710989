import React from 'react';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { addMaterial } from '../../utils/api/vc'; // Update with your actual import
import styled from 'styled-components';
import LeftNavigation from "../../components/admin/LeftNavigation.jsx";
import { AdminRoot } from "../../components/styles/AdminPanel.styled";
import { HeadingText } from "../../components/styles/Typography.styled";

const validationSchema = Yup.object({
    name: Yup.string().required('Name is required').max(500, 'Name cannot be longer than 500 characters'),
    description: Yup.string().required('Description is required'),
    icon: Yup.string().required('Icon URL is required'),
    resources: Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('Resource name is required').max(50, 'Resource name cannot be longer than 50 characters'),
            link: Yup.string().required('Resource link is required'),
            type: Yup.string().required('Resource type is required').oneOf(['video', 'audio', 'image', 'pdf', 'other'], 'Invalid resource type')
        })
    )
});

const StyledForm = styled.form`
     width: 400px;
`

const AdminAddMaterial = () => {
    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
            icon: '',
            resources: [{ name: '', link: '', type: '' }]
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                await addMaterial(values);
                alert('Material added successfully');
            } catch (error) {
                alert('Failed to add material');
            }
        },
    });

    return (
        <div>
            <LeftNavigation />
            <AdminRoot>
                <HeadingText variant="h5">Add New Material</HeadingText>
                <FormikProvider value={formik}>
                    <StyledForm onSubmit={formik.handleSubmit}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                            <TextField
                                label="Name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                            />
                            <TextField
                                label="Description"
                                name="description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                            />
                            <TextField
                                label="Icon URL"
                                name="icon"
                                value={formik.values.icon}
                                onChange={formik.handleChange}
                                error={formik.touched.icon && Boolean(formik.errors.icon)}
                                helperText={formik.touched.icon && formik.errors.icon}
                            />

                            <FieldArray
                                name="resources"
                                render={arrayHelpers => (
                                    <div>
                                        {formik.values.resources.map((resource, index) => (
                                            <Box key={index} sx={{ marginBottom: 2 }}>
                                                <TextField
                                                    label={`Resource #${index + 1} Name`}
                                                    name={`resources[${index}].name`}
                                                    value={resource.name}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.resources?.[index]?.name && Boolean(formik.errors.resources?.[index]?.name)}
                                                    helperText={formik.touched.resources?.[index]?.name && formik.errors.resources?.[index]?.name}
                                                    fullWidth
                                                />
                                                <TextField
                                                    label={`Resource #${index + 1} Link`}
                                                    name={`resources[${index}].link`}
                                                    value={resource.link}
                                                    onChange={formik.handleChange}
                                                    error={formik.touched.resources?.[index]?.link && Boolean(formik.errors.resources?.[index]?.link)}
                                                    helperText={formik.touched.resources?.[index]?.link && formik.errors.resources?.[index]?.link}
                                                    fullWidth
                                                />
                                                <FormControl fullWidth margin="normal">
                                                    <InputLabel>Type</InputLabel>
                                                    <Select
                                                        label="Type"
                                                        name={`resources[${index}].type`}
                                                        value={resource.type}
                                                        onChange={formik.handleChange}
                                                        error={formik.touched.resources?.[index]?.type && Boolean(formik.errors.resources?.[index]?.type)}
                                                    >
                                                        <MenuItem value="video">Video</MenuItem>
                                                        <MenuItem value="audio">Audio</MenuItem>
                                                        <MenuItem value="image">Image</MenuItem>
                                                        <MenuItem value="pdf">PDF</MenuItem>
                                                        <MenuItem value="other">Other</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <Button
                                                    type="button"
                                                    color="error"
                                                    variant="contained"
                                                    onClick={() => arrayHelpers.remove(index)}
                                                >
                                                    Remove Resource
                                                </Button>
                                            </Box>
                                        ))}
                                        <Button
                                            type="button"
                                            color="primary"
                                            variant="contained"
                                            onClick={() => arrayHelpers.push({ name: '', link: '', type: '' })}
                                            sx={{ marginTop: 2 }}
                                        >
                                            Add Resource
                                        </Button>
                                    </div>
                                )}
                            />

                            <Button color="primary" variant="contained" type="submit" sx={{ marginTop: 2 }}>Submit</Button>
                        </Box>
                    </StyledForm>
                </FormikProvider>
            </AdminRoot>
        </div>
    );
};

export default AdminAddMaterial;
