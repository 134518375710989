import React, {useEffect} from 'react'
import TopBar from "../components/TopBar.jsx";
import LoginForm from "../components/LoginForm.jsx";
import {Alert, Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {logout} from "../slices/authSlice";
import {useNavigate} from "react-router-dom";

export default function LogoutPage() {
    const [t, i18n] = useTranslation('ns1');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            dispatch(logout())
            navigate('/')
        }, 2000);
    }, [])

    return (
        <>
            <TopBar/>
            <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '1rem', margin: 'auto', width: '500px' }}>
                <Typography sx={{ marginBottom: 2 }} variant="h4" component="h4">{t('logout')}</Typography>
                <Alert>
                    {t('logout_message')}
                </Alert>
            </Box>
        </>
    )
}