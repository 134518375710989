import React, {useEffect} from 'react';
import Footer from "../../components/Footer.jsx";
import TopBar from "../../components/TopBar.jsx";
import {
    VCFlexBox,
    VCFlexButtonsContainer,
    VCRoot,
    VCTitle
} from "../../components/styles/virtualClassroom/VirtualClassroom.styled";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Box, Button, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

export default function VCExplore() {
    const { t } = useTranslation('ns1');
    const { isAuthenticated } = useSelector((state) => state.auth)

    return (
        <>
            <TopBar/>
            <VCRoot>
                <VCTitle variant="h4" gutterBottom>{t('explore')}</VCTitle>
            </VCRoot>
            <Footer/>
        </>
    )
}