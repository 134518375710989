import React, {useEffect} from 'react';
import TopBar from "../components/TopBar.jsx";
import {CenteredContainer} from "../components/styles/Containers.styled";
import {HeadingText} from "../components/styles/Typography.styled";
import {
    NewsImage,
    NewsPaper,
    NewsParagraph,
    NewsTextContainer,
    NewsTitle,
    NewsFooter,
    ImageContainer,
    NewsContainer
} from "../components/styles/News.styled";
import {ButtonStyled} from "../components/styles/UserButtons.styled";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import {getNewsByUrlPath, listNews} from "../utils/api/news";
import {v4 as uuidv4} from 'uuid';
import formatDate from "../utils/formatDate";
import Footer from "../components/Footer.jsx";
import {useTranslation} from "react-i18next";

export default function News() {
    const navigate = useNavigate()
    const [news, setNews] = React.useState([]);
    const [t, i18n] = useTranslation('ns1');

    React.useEffect(() => {
        async function getNews() {
            const { news } = await listNews();
            console.log(news);
            setNews(news);
        }
        getNews();
    }, [])

    const handleReadMoreClick = (urlPath) => {
        navigate(urlPath)
    }

    return (
        <>
            <TopBar/>
            <CenteredContainer sx={{ mt: '50px' }}>
                <HeadingText variant="h5" sx={{ mb: 1 }}>News</HeadingText>
                {
                    news.map((newsItem) => {
                        return (
                            <NewsPaper key={uuidv4()} elevation={2}>
                                <NewsContainer>
                                    <NewsImage src={newsItem.imgPath} alt="News Image"/>
                                    <NewsTextContainer>
                                        <NewsTitle variant="h6" sx={{ mb: 1 }}>{t(newsItem.heading)}</NewsTitle>
                                        <NewsParagraph>
                                            {t(newsItem.previewText)}
                                        </NewsParagraph>
                                        <NewsFooter>
                                            <p>{formatDate(newsItem.createdAt)}</p>
                                            <ButtonStyled variant="contained" color="primary" onClick={() => handleReadMoreClick(newsItem.urlPath)}>Read more</ButtonStyled>
                                        </NewsFooter>
                                    </NewsTextContainer>
                                </NewsContainer>
                            </NewsPaper>
                        )
                    })
                }
            </CenteredContainer>
            <Footer/>
        </>
    )
}

export function NewsDetails() {
    const { urlPath } = useParams();
    const [news, setNews] = React.useState([]);
    const [t, i18n] = useTranslation('ns1');

    useEffect(() => {
        async function getNews() {
            const { news }  = await getNewsByUrlPath(urlPath);
            console.log(news[0]);
            setNews(news[0]);
        }
        getNews();
    }, [])

    return (
        <>
            <TopBar/>
            <CenteredContainer sx={{ m: '2rem 8rem' }}>
                <HeadingText variant="h5" sx={{ mb: 1, mt: 5}}>{t(news.heading)}</HeadingText>
                    <NewsContainer sx={{ alignItems: 'center', flexDirection: 'column' }}>
                        <NewsImage sx={{ height: '70%', width: '70%' }} src={news.imgPath} alt="News Image"/>
                        <NewsTextContainer>
                            <NewsParagraph sx={{ textAlign: 'center' }}>
                                {t(news.previewText)}
                            </NewsParagraph>
                            <NewsParagraph sx={{ textAlign: 'justify' }}>
                                {t(news.description)}
                            </NewsParagraph>
                            <NewsFooter sx={{ justifyContent: 'flex-end' }}>
                                <strong>{formatDate(news.createdAt)}</strong>
                            </NewsFooter>
                        </NewsTextContainer>
                    </NewsContainer>
            </CenteredContainer>
        </>
    );
}