import Paper from '@mui/material/Paper';
import {styled} from "@mui/system";
import {TextField, Typography} from "@mui/material";
import theme from "./theme";

export const UserDetailsPaper = styled(Paper)(({ theme }) => ({
    padding: '2rem',
    margin: '1rem',
    gap: '0.5rem',
    width: '450px',
    [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
    },
    height: '725px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
}))

export const UserDetailsHeadingText = styled(Typography)({
    textTransform: 'uppercase'
})

export const UserDetailsText = styled(Typography)({
    fontWeight: 'bold'
})

export const UserDetailsTextField = styled(TextField)({
    width: '100%'
})