export const createQuestions = (data) => {
    const questions = [];

    for (let i = 0; i < data.length; i++) {
        const [key, value] = data[i];

        // Check if the current element represents a question
        if (key.startsWith('question_')) {
            // Extract the index of the question from the key
            const questionIndex = key.split('_')[1];

            // Find the corresponding question type using the extracted index
            const questionTypeKey = `question_type_${questionIndex}`;
            const questionTypePair = data.find(([k]) => k === questionTypeKey);

            // Construct the question object if the corresponding type was found
            if (questionTypePair) {
                const question = {
                    question: value,
                    questionType: questionTypePair[1],
                    answers: [],
                    correctAnswers: [],
                };

                // Add the question object to the result array
                questions.push(question);

                // Skip over the questionTypePair to avoid processing it again
                i++;
            }
        }
    }

    return questions;
};
