import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {getVCEventById, joinVCEvent, leaveVCEvent} from "../../utils/api/vc";
import {Box, Button, Paper, Typography} from '@mui/material';
import styled from 'styled-components';
import TopBar from "../../components/TopBar.jsx";
import Footer from "../../components/Footer.jsx";
import {useSelector} from "react-redux";
import {VCBackground} from "../../components/styles/virtualClassroom/VirtualClassroom.styled";
import Disclaimer from "../../components/Disclaimer.jsx";

const EventContainer = styled(Paper)`
    margin: 50px auto 20px auto;
    padding: 20px;
   width: 450px;
`;

const EventTitle = styled(Typography)`
    margin-bottom: 20px;
`;

const EventBody = styled(Typography)`
    white-space: pre-line;
`;

const FlexBox = styled(Box)`
    display: flex;
  flex-direction: column;
`;

export const VCViewEvent = () => {
    const { t } = useTranslation('ns1');
    const { id } = useParams();
    const [vcEvent, setVCEvent] = useState(null);
    const [isSubscribed, setIsSubscribed] = useState(false); // Placeholder for subscription status
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { vcEvent } = await getVCEventById(id);
                setVCEvent(vcEvent);
                // Check if the user is subscribed to the event
                // setIsSubscribed(checkSubscriptionStatus(vcEvent));
            } catch (error) {
                console.error('Error fetching event:', error);
            }
        };
        fetchData();
    }, [id]);

    const handleJoinEvent = async () => {
        console.log('Joining event:', vcEvent?.name);
        // Join the event
        const eventId = vcEvent?._id;
        const userId = user?._id;
        if(!userId) return alert('You are not logged in!')

        await joinVCEvent(eventId, userId);
        setIsSubscribed(true);
    };

    const handleUnjoinEvent = async () => {
        console.log('Unjoining event:', vcEvent?.name);
        // Unjoin the event
        const eventId = vcEvent?._id;
        const userId = user?._id;

        await leaveVCEvent(eventId, userId);
        setIsSubscribed(false);
    }

    useEffect(() => {
        const checkSubscriptionStatus = () => {
            // Check if the user is subscribed to the event
            const userId = user?._id;
            const subscribedUsers = vcEvent?.registeredUsers;
            return subscribedUsers?.includes(userId);
        }
        setIsSubscribed(checkSubscriptionStatus(vcEvent));
    }, [vcEvent]);

    return (
        <VCBackground>
            <TopBar isGame={true}/>
            <EventContainer>
                <EventTitle variant="h4">{vcEvent?.name}</EventTitle>
                <FlexBox>
                    <Typography variant="subtitle1" fontWeight="bold">{t('event_description')}:</Typography>
                    <Typography variant="body1">{vcEvent?.description}</Typography>
                </FlexBox>
                <FlexBox>
                    <Typography variant="subtitle1" fontWeight="bold">{t('event_date')}:</Typography>
                    <Typography variant="body1">{new Date(vcEvent?.date).toLocaleDateString()}</Typography>
                </FlexBox>
                <FlexBox>
                    <Typography variant="subtitle1" fontWeight="bold">{t('event_url')}:</Typography>
                    <EventBody variant="body1">{vcEvent?.eventBody}</EventBody>
                </FlexBox>
                <Box mt={2}>
                    {isSubscribed ? (
                        <Button variant="contained" color="secondary" onClick={handleUnjoinEvent}>
                            {t('unregister_for_event')}
                        </Button>
                    ) : (
                        <Button variant="contained" color="primary" onClick={handleJoinEvent}>
                            {t('register_for_event')}
                        </Button>
                    )}
                </Box>
                <Disclaimer color="black" disclaimerKey="disclaimer_join_event" />
            </EventContainer>
            <Footer isGame={true}/>
        </VCBackground>
    );
};
