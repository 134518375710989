import React, {useEffect, useRef, useState} from 'react'
import {
    ThreadContainer,
    ThreadHeader,
    ThreadBody,
    ThreadPaper,
    CommentContainer,
    ThreadStarterCommentContainer, AuthorBox, AddCommentBox
} from "./styles/Forum.styled";
import {Box, Button, Divider, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {FlexContainer} from "./styles/Containers.styled";
import {useSelector} from "react-redux";
import {getSelectedThread} from "../slices/forumSlice";
import {v4 as uuidv4} from "uuid";
import {addComment, deleteThread} from "../utils/api/forum";
import { AddCommentComponent } from '../pages/Forum.jsx'

export default function ForumThread({ onDeleteThread }) {
    const [t, i18n] = useTranslation('ns1')
    const { forumSelectedThread } = useSelector((state) => state.forum)
    const { isAuthenticated, user } = useSelector((state) => state.auth)
    const [showCommentBox, setShowCommentBox] = useState(false)

    const commentsList = forumSelectedThread?.comments

    useEffect(() => {
        setShowCommentBox(false) // close comment box on thread change
    }, [forumSelectedThread])

    return (
        !forumSelectedThread ? <NoThreadSelected /> : (
            <ThreadContainer>
                <ThreadHeader>
                    <Typography variant="h6" fontWeight="bold">{forumSelectedThread.title}</Typography>
                    <Typography fontWeight="500">{forumSelectedThread.user?.username} @ {forumSelectedThread.comments?.length + ' ' + t('forum_comments')}</Typography>
                </ThreadHeader>
                <ThreadPaper>
                    {
                        user.role === 'admin' &&
                        <Button sx={{ ml: 2, alignSelf: 'flex-start' }}
                                variant="contained"
                                color="secondary"
                                onClick={() => onDeleteThread(forumSelectedThread._id)}
                        >
                            {t('delete_thread')}
                        </Button>
                    }
                    <ThreadStarterCommentContainer flexDirection="row">
                        <AuthorBox sx={{ textAlign: 'center' }} >
                            <img style={{ width: '80px' }} src={forumSelectedThread.user?.avatarUrl} alt="avatar" />
                            <Typography fontWeight="bold">{forumSelectedThread.user?.username}</Typography>
                        </AuthorBox>
                        <Box>
                            <Typography>{forumSelectedThread.text}</Typography>
                        </Box>
                    </ThreadStarterCommentContainer>
                    <Divider />
                    <FlexContainer flexDirection="column">
                        {
                            commentsList?.map((comment) => (
                                <CommentContainer flexDirection="row" key={uuidv4()}>
                                    <AuthorBox sx={{ textAlign: 'center' }}>
                                        <img style={{ width: '80px' }} src={comment?.authorAvatarUrl} alt="avatar" />
                                        <Typography>{comment.authorName}</Typography>
                                    </AuthorBox>
                                    <Box>
                                        <Typography>{comment.text}</Typography>
                                    </Box>
                                </CommentContainer>
                            ))
                        }
                    </FlexContainer>
                    <AddCommentBox>
                        {
                            (isAuthenticated && !showCommentBox) && <Button sx={{ mr: 2, alignSelf: 'flex-end' }} variant="contained" onClick={() => setShowCommentBox(true)}>{t('add_comment')}</Button>
                        }
                        {
                            (!isAuthenticated) && <Typography variant="h6">{t('login_to_comment')}</Typography>
                        }
                        {
                            showCommentBox && (
                                <AddCommentComponent
                                    threadId={forumSelectedThread._id}
                                    user={user}
                                    showCommentBox={showCommentBox}
                                />
                            )
                        }
                    </AddCommentBox>
                </ThreadPaper>
            </ThreadContainer>
        )
    )
}

const NoThreadSelected = () => {

    return (
        <FlexContainer sx={{ marginTop: '50px' }} flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="h6">No thread selected</Typography>
        </FlexContainer>
    )
}