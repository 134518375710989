import React from 'react'
import {useTranslation} from "react-i18next";
import TopBar from "../components/TopBar.jsx";
import Footer from "../components/Footer.jsx";
import {Typography} from "@mui/material";

export default function PrivacyPolicy() {
    const { t } = useTranslation('ns1')
    return (
        <>
            <TopBar/>
            <Typography variant="h4" textAlign="center" sx={{ padding: '2rem 6rem 1rem 6rem', marginTop: '50px' }}>{t('privacy_policy')}</Typography>
            <Typography textAlign="center">{t('privacy_policy_text')}</Typography>
            <Footer/>
        </>
    )
}