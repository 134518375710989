import React, { useEffect, useState, useRef } from 'react';
import { Card, CardContent, TextField, Button, Box, Typography, IconButton } from '@mui/material';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { addQuestion } from "../../slices/scenarioBuilderSlice";
import CloseIcon from '@mui/icons-material/Close';
import {uploadMissionImage} from "../../utils/api/mission";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledCard = styled(Card)`
  width: 200px;
  margin: 10px;
`;

const CardCollection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1rem;
  width: 1000px;
`;

const ImagePreview = styled.img`
  margin: 1rem 0;
  width: 167px;
  height: 153px;
`;

const SingleCard = ({ value, imgSrc, onDescriptionChange, onImgSrcChange, cardNum, onDelete }) => {
    const fileInputRef = useRef(null);

    const handleFileUpload = async (e) => {
        const image = e.target.files[0]
        console.log(image)
        uploadMissionImage(image).then(res => {
            const { imagePath } = res
            onImgSrcChange(imagePath)
        }).catch(err => {
            alert('ERROR WHILE UPLOADING IMAGE!!!')
            alert(err)
        })
    };

    return (
        <StyledCard>
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography textAlign="center" fontWeight="bold">{cardNum}</Typography>
                    <IconButton aria-label="delete" onClick={onDelete} sx={{ padding: 0 }}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <TextField
                    label="Caption"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={value}
                    onChange={onDescriptionChange}
                />
                <input
                    type="file"
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={handleFileUpload}
                />
                <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={() => fileInputRef.current.click()}
                >
                    Upload Image
                </Button>
                {imgSrc && <ImagePreview src={`http://37.9.171.147:5000/public${imgSrc}`} alt="Uploaded Preview" />}
            </CardContent>
        </StyledCard>
    );
};

const OrderedListQuestionForm = ({ question, initialValues }) => {
    const [questions, setQuestions] = useState(
        initialValues || Array.from({ length: 4 }, () => ({ value: '', imgSrc: '' }))
    );
    const [disabled, setDisabled] = useState(false);
    const dispatch = useDispatch();

    const updateQuestion = (index, field, value) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index][field] = value;
        setQuestions(updatedQuestions);
    };

    const addNewCard = () => {
        if (questions.length >= 12) {
            setDisabled(true);
            return;
        }
        setQuestions([...questions, { value: '', imgSrc: '' }]);
    };

    const deleteCard = (indexToDelete) => {
        const filteredQuestions = questions.filter((_, index) => index !== indexToDelete);
        setQuestions(filteredQuestions);
        if (filteredQuestions.length < 12) {
            setDisabled(false);
        }
    };

    const submitQuestion = () => {
        const allFilled = questions.every((question) => question.value && question.imgSrc);

        if (allFilled) {
            const newQuestion = {
                question: question.question,
                questionType: 'ordered_list',
                answers: [],
                correctAnswers: questions,
            };
            dispatch(addQuestion(newQuestion));
            console.log(newQuestion);
        } else {
            alert('Please fill all the cards!');
        }
    };

    return (
        <>
            <Typography variant="h5" gutterBottom textAlign="center"> Enter sdasd </Typography>
            <Container>
                <CardCollection>
                    {questions.map((question, index) => (
                        <SingleCard
                            key={index}
                            cardNum={index + 1}
                            value={question.value}
                            imgSrc={question.imgSrc}
                            onDescriptionChange={(e) => updateQuestion(index, 'value', e.target.value)}
                            onImgSrcChange={(filePath) => updateQuestion(index, 'imgSrc', filePath)}
                            onDelete={() => deleteCard(index)}
                        />
                    ))}
                </CardCollection>
            </Container>
            <Box display="flex" justifyContent="space-evenly" alignItems="center" width="100%">
                <Button variant="contained" color="primary" onClick={addNewCard} disabled={disabled}>
                    Add New Card
                </Button>
                <Button variant="contained" color="secondary" onClick={submitQuestion}>
                    Submit Question
                </Button>
            </Box>
        </>
    );
};

export default OrderedListQuestionForm;
