import React from 'react'
import styled from 'styled-components'
import { Text } from './styles/Typography.styled'
import {Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import formatDate from "../utils/formatDate";
import theme from "./styles/theme";

const UserCard = styled(Box)({
    display: 'flex',
    justifyContent: 'space-around',
    padding: '1rem',
    marginTop: '80px',
    width: '900px',
    backgroundColor: '#f5f5f5',
    flexDirection: 'row',
    borderRadius: '10px',
    boxShadow: '0 0 6px rgba(0,0,0,0.5)',
    alignItems: 'center',
    '&:hover': {
        boxShadow: '0 0 6px rgba(0,0,0,0.8)',
        transform: 'scale(1.03)',
        transition: 'all 0.3s ease-in-out',
    },
    [theme.breakpoints.down('md')]: {
        width: '300px',
        flexDirection: 'column',
        margin: '90px 50px'
    }
})

const UserCardText = styled(Text)({
    fontSize: '1rem',
    fontWeight: 'bold',
    marginRight: '1rem',
})

const UserCardText2 = styled(Text)({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '120px',
})

const UserCardBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    margin: '1rem',
})

export default function UserProfile({ user }) {
    const { t } = useTranslation('ns1')
    console.log(user)
    return (
        <>
            <UserCard>
                <Box>
                    <img src={user.avatarUrl} alt="Avatar" style={{width: '100px', height: '100px', borderRadius: '50%'}} />
                </Box>
                <Box sx={{ display: 'flex', width: '600px',     [theme.breakpoints.down('md')]: {
                        width: '150px',
                        flexDirection: 'column',
                    } }}>
                    <UserCardBox>
                        <UserCardText>{t('username')}:</UserCardText>
                        <UserCardText2>{user.username}</UserCardText2>
                    </UserCardBox>
                    <UserCardBox>
                        <UserCardText>{t('role')}:</UserCardText>
                        <UserCardText2 sx={{ textTransform: 'capitalize' }}>{user.role}</UserCardText2>
                    </UserCardBox>
                    <UserCardBox>
                        <UserCardText>{t('country')}:</UserCardText>
                        <UserCardText2>{user.country}</UserCardText2>
                    </UserCardBox>
                    <UserCardBox>
                        <UserCardText>{t('joined_on')}:</UserCardText>
                        <UserCardText2>{formatDate(user.createdAt)}</UserCardText2>
                    </UserCardBox>
                </Box>
            </UserCard>
        </>
    )
}