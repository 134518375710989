import {styled} from "@mui/material/styles";
import {Box, Paper, Typography} from "@mui/material";
import {HeadingText} from "./Typography.styled";

export const NewsPaper = styled(Paper)(({ theme }) => ({
    width: '60%',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
    },
    minWidth: '300px',
    padding: '1rem',
    display: 'flex',
    marginBottom: 20,
}))

export const NewsParagraph = styled(Typography)({
    fontSize: '1.0rem',
    padding: '0 1rem',
    fontWeight: '400',
    lineHeight: '1.5',
    letterSpacing: '0.00938em',
    textAlign: 'justify',
    margin: '1rem 0',
});

export const NewsImage = styled('img')({
    width: '275px',
    height: '275px',
    borderRadius: '0.5rem',
});

export const NewsTextContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
});

export const NewsTitle = styled(HeadingText)(({ theme }) => ({
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
        marginTop: '1rem'
    },
}))

export const NewsFooter = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0rem 1rem',
    [theme.breakpoints.down('md')]: {
        margin: '0rem',
    }
}));

export const ImageContainer = styled(Box)({
    height: '100%',
})

export const NewsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    width: '100%',
}))

// Recent News Component START

export const RecentNewsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '2rem auto',
    width: '55%',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        padding: '2rem',
        margin: '0rem',
    },
}))