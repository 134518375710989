import React, { useEffect, useState } from 'react';
import { Typography, TextField, Box } from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {addAnswerToQuestion, addBadgesEarned, addQuestionAnswer} from "../../../slices/gameSlice";
import {useTranslation} from "react-i18next";
import styled from 'styled-components'
import theme from "../../styles/theme";

const QuestionWrapper = styled(Box)({
    width: '680px',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        padding: '1.5rem',
    }
})

function FillInTheBlanks({ question }) {
    const { t } = useTranslation('ns1');
    const [userAnswers, setUserAnswers] = useState(Array(question.correctAnswers.length).fill(''));
    const dispatch = useDispatch();
    const userAnswersRef = React.useRef([]);

    const awardBadges = () => {
        const correctAnswers = question.correctAnswers;
        console.log(correctAnswers)
        console.log(userAnswersRef.current)
        const correctAnswersCount = correctAnswers.filter((answer, index) => {
            return answer === userAnswersRef.current[index];
        }).length
        dispatch(addBadgesEarned(correctAnswersCount));
    }

    useEffect(() => {
        const questionAnswer = {
            question: question.question,
            answers: [],
        }
        dispatch(addQuestionAnswer(questionAnswer))

        return () => {
            awardBadges()
        }
    }, [question]);

    const handleInputChange = (index, value) => {
        const updatedAnswers = [...userAnswers];
        updatedAnswers[index] = value;

        setUserAnswers(updatedAnswers);
        console.log(updatedAnswers)
        userAnswersRef.current = updatedAnswers;

        const answerObj = {
            title: question.question,
            answer: updatedAnswers.map((answer) => answer)
        }
        dispatch(addAnswerToQuestion(answerObj));
    };

    const renderTextWithBlanks = () => {
        let formattedQuestionText = question.question;
        question.correctAnswers.forEach((answer, index) => {
            formattedQuestionText = formattedQuestionText.replace(new RegExp(`\\b${answer}\\b`, "g"), `{${index}}`);
        });
        const segments = formattedQuestionText.split(/({\d+})/);
        return segments.map((segment, index) => {
            const match = segment.match(/{(\d+)}/);
            if (match) {
                const answerIndex = parseInt(match[1]);
                return (
                    <TextField
                        key={index}
                        value={userAnswers[answerIndex] || ''}
                        onChange={(e) => handleInputChange(answerIndex, e.target.value)}
                        variant="outlined"
                        size="small"
                        sx={{ margin: '0 4px', '& .MuiInputBase-root': { height: '33px', width: '120px', color: 'white' } }}
                    />
                );
            } else {
                return segment;
            }
        });
    };

    return (
        <QuestionWrapper
            display="flex"
            flexDirection="column"
            alignItems="center"
        >
            <Typography variant="h6" textAlign="center" color="common.orange" fontWeight="bold" gutterBottom>{t(`question_guidance_${question.questionType}_text`)}</Typography>
            {/*<Typography variant="h6" textAlign="center" color="white" fontWeight="bold" gutterBottom>{question.question}</Typography>*/}
            <Typography variant="h6" textAlign="justify" sx={{ lineHeight: 2.5, fontSize: '1.15rem', color: 'white' }}>{renderTextWithBlanks()}</Typography>
        </QuestionWrapper>
    );
}

export default FillInTheBlanks;
