import styled from "styled-components";
import {Box, Card, CardContent, Typography} from "@mui/material";

export const StyledCard = styled(Card)`
    background-color: ${props => props.selected ? "#FC6A03" : "#fff"} !important;
    color: ${props => props.selected ? "#fff" : "#000"} !important;
    margin: 10px;
    cursor: pointer;
`;

export const MCRoot = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '950px',
    margin: '0 auto',
})

export const CardsContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    gap: '2rem',
})

export const StyledCardContent = styled(CardContent)({
    textAlign: 'center',
    width: '220px',
    height: '110px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
})

export const AnswerText = styled(Typography)(({ selected }) => ({
    fontWeight: selected ? 'bold !important' : 'normal',
}))