import React, {useEffect, useRef, useState} from 'react'
import TopBar from "../components/TopBar.jsx";
import ForumThreadsList from "../components/ForumThreadsList.jsx";
import ForumThread from "../components/ForumThread.jsx";
import {Box, Button, TextField, Typography} from "@mui/material";
import {ForumFlexContainer, ForumThreadBox, ForumThreadsListBox} from "../components/styles/Forum.styled";
import {addComment, deleteThread, getThreads} from "../utils/api/forum";
import {FlexContainer} from "../components/styles/Containers.styled";
import {useTranslation} from "react-i18next";
import {getSelectedThread, addThreadComment, setForumThreadsList, selectThread} from "../slices/forumSlice";
import {useDispatch, useSelector} from "react-redux";
import Footer from "../components/Footer.jsx";
import {motion, AnimatePresence} from "framer-motion";
import {v4 as uuid} from "uuid";

export default function Forum() {
    const dispatch = useDispatch();
    const fetchThreads = async () => {
        const { threads } = await getThreads();
        dispatch(setForumThreadsList(threads))
    }

    useEffect(() => {
        fetchThreads()
    }, [])

    const handleDeleteThread = async (id) => {
        await deleteThread(id);
        await fetchThreads()
        const { threads } = await getThreads();
        setForumThreadsList(threads)
        dispatch(selectThread(null))
    }

    const { threadsList } = useSelector(state => state.forum);

    return (
        <>
            <TopBar/>
            <ForumFlexContainer>
                <ForumThreadsListBox>
                    <ForumThreadsList threads={threadsList}/>
                </ForumThreadsListBox>
                <ForumThreadBox>
                    <AnimatePresence mode="wait" initial={false}>
                        <motion.div key={uuid()}
                                    initial={{ opacity: 0, scale: 0.5 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{
                                        // duration: 0.1,
                                        ease: [0, 0.41, 0.71, 1.01]
                                    }}
                        >
                            <ForumThread onDeleteThread={handleDeleteThread} />
                        </motion.div>
                    </AnimatePresence>
                </ForumThreadBox>
            </ForumFlexContainer>
            <Footer/>
        </>
    )
}

export const AddCommentComponent = ({ threadId, user, showCommentBox }) => {
    const [t, i18n] = useTranslation('ns1')
    const commentTextRef = useRef(null)
    const dispatch = useDispatch()
    const { threadsList } = useSelector(state => state.forum);

    const handleAddComment = () => {
        const commentText = commentTextRef.current?.value
        const commentBody = {
            text: commentText,
            authorName: user.username,
            authorId: user._id,
            authorAvatarUrl: user.avatarUrl
        }
        dispatch(addThreadComment(commentBody))
        addComment(threadId, commentBody)
        const commentingThread = threadsList.filter(thread => thread._id === threadId)[0]
    }

    return (
        <FlexContainer flexDirection="column">
            <Typography variant="h6">{t('add_comment')}</Typography>
            <TextField rows={6} multiline inputRef={commentTextRef} />
            <FlexContainer flexDirection="row" justifyContent="flex-end">
                <Button sx={{ mr: 2 }} variant="outlined" onClick={() => showCommentBox = false}>{t('cancel')}</Button>
                <Button variant="contained" onClick={() => handleAddComment()}>{t('add')}</Button>
            </FlexContainer>
        </FlexContainer>
    )
}