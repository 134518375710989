import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#236ef5',
        },
        secondary: {
            main: '#00d5c0',
            contrastText: "#fff",
        },
        common: {
            black: '#000',
            lightblack: '#040c2d',
            lightgray: '#dcdcdc',
            white: '#fff',
            orange: '#FC6A03',
        }
    },
    breakpoints: {
        values: {
            xs: 400,
            sm: 600,
            md: 720,
            lg: 1120,
            xl: 1400,
        },
    },

    typography: {
        fontFamily: [
            'Montserrat',
            'sans-serif',
        ].join(','),
    },
    components: {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    overflowX: 'hidden'
                },
                body: {
                    backgroundColor: '#fff',
                    padding: '0 !important',
                    overflowX: 'hidden'
                },
                a: {
                    textDecoration: 'none',
                    color: 'inherit',
                }
            },
        }
    },
});

export default theme