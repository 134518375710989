import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { listVCResources } from '../../utils/api/vc';
import { Box, Card, CardContent, Typography, Button } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import styled from 'styled-components';
import TopBar from '../../components/TopBar.jsx';
import Footer from '../../components/Footer.jsx';
import {VCBackground} from "../../components/styles/virtualClassroom/VirtualClassroom.styled";
import {useNavigate} from "react-router-dom";

const FlexContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
  margin-top: 50px;
`;

const ResourceCard = styled(Card)`
    width: 100%;
    max-width: 400px;
`;

const ResourceContainer = styled(Box)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
    justify-content: center;
    width: 100%;
`

export const VCDigitheca = () => {
    const [userResources, setUserResources] = useState([]);
    const { user } = useSelector((state) => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchResources = async () => {
            try {
                const response = await listVCResources();
                const resources = response.vcResources;
                const filteredResources = resources.filter(resource => resource.ownerId === user._id);
                setUserResources(filteredResources);
            } catch (error) {
                console.error('Error fetching resources:', error);
            }
        };

        fetchResources();
    }, [user._id]);

    const handleDownload = (resourceUrl) => {
        // open the resource in a new tab
        console.log(resourceUrl);
window.open(resourceUrl, '_blank');
    };

    return (
        <div>
            <TopBar isGame={true}/>
            <VCBackground>
                <FlexContainer padding={3}>
                    <Typography variant="h4" gutterBottom>User Submitted Resources</Typography>
                    <ResourceContainer>
                        {userResources.map((resource, index) => (
                            <ResourceCard key={index}>
                                <CardContent>
                                    <Typography variant="h5">{resource.name}</Typography>
                                    <Typography variant="body1">{resource.description}</Typography>
                                    {resource.resources.map((resource, index) => (
                                        <Button
                                            startIcon={<GetAppIcon />}
                                            onClick={() => handleDownload(resource)}
                                            variant="contained"
                                            sx={{ marginRight: '10px', marginTop: '10px', '& .MuiButtonBase-root': { color: 'black !important' } }}
                                        >
                                            Download
                                        </Button>
                                    ))}
                                </CardContent>
                            </ResourceCard>
                        ))}
                    </ResourceContainer>

                </FlexContainer>
            </VCBackground>

            <Footer isGame={true}/>
        </div>
    );
};

export default VCDigitheca;