import styled from 'styled-components';
import {Box, Button, Paper} from '@mui/material';
import theme from '../theme';

export const GameRootContainer = styled(Box)({
    marginTop: '50px'
})

export const GameDashboardContainer = styled(Box)(({ imgpath }) => ({
    backgroundImage: `url(${imgpath})`,
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '80vh',
    [theme.breakpoints.down('md')]: {
        height: '100%',
        paddingTop: '4rem',
    },
    padding: '1rem'
}))

export const GameMissionsContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    background: '#27282C',
})

export const PlayButton = styled(Button)({
    marginTop: '1rem !important',
    width: '200px'
})

export const ScenarioBuilderButton = styled(PlayButton)({
    fontWeight: 'bold',
    width: 'fit-content',
})

export const MainButtonsContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        width: 'inherit',
    }
})

export const StatCardsContainer = styled(Box)({
    display: 'flex',
    width: '100%',
    marginTop: '1rem',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem',
        width: 'inherit'
    }
})

export const StatCard = styled(Paper)(({ backgroundColor }) => ({
    display: 'flex',
    backgroundColor: `${backgroundColor} !important`,
    color: 'white !important',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '220px',
    height: '220px',
}))

export const GameDescriptionWrapper = styled(Box)({
    width: '50%',
    [theme.breakpoints.down('md')]: {
        width: '100%'
    }
})