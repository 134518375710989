export const getTeam = async () => {
    try {
        const fetchUrl = `http://37.9.171.147:5000/team/get`;
        const response = await fetch(fetchUrl, {
            method: 'GET',
        })

        if (!response.ok) {
            response.message = 'Get team failed'
        }

        return await response.json();
    } catch (error) {
        // Handle and log errors here
        throw error;
    }
}

export const addTeamMember = async (firstName, lastName, jobTitle, companyName, imgPath) => {
    try {
        const fetchUrl = `http://37.9.171.147:5000/team/add`;
        const response = await fetch(fetchUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ firstName, lastName, jobTitle, companyName, imgPath })
        })

        if (!response.ok) {
            response.message = 'Add team member failed'
        }

        return await response.json();
    } catch (error) {
        // Handle and log errors here
        throw error;
    }
}

export const deleteTeamMember = async (id) => {
    try {
        const fetchUrl = `http://37.9.171.147:5000/team/delete/${id}`;
        const response = await fetch(fetchUrl, {
            method: 'DELETE',
        })

        if (!response.ok) {
            response.message = 'Delete team member failed'
        }

        return await response.json();
    } catch (error) {
        // Handle and log errors here
        throw error;
    }
}

const updateTeamMember = async (id, firstName, lastName, jobTitle, companyName) => {
    try {
        const fetchUrl = `http://37.9.171.147:5000/team/edit/${id}`;
        const response = await fetch(fetchUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ firstName, lastName, jobTitle, companyName })
        })

        if (!response.ok) {
            response.message = 'Update team member failed'
        }

        return await response.json();
    } catch (error) {
        // Handle and log errors here
        throw error;
    }
}