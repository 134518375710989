import styled from "styled-components";
import {Box, Button, Paper, Typography} from "@mui/material";
import theme from "../theme";

export const MissionsContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '1111px',
    [theme.breakpoints.down('md')]: {
        width: '90%',
    },
    margin: '0 auto',
})

export const MissionCard = styled(Paper)({
    padding: '2rem',
    marginBottom: '2rem',
    display: 'flex',
    flexDirection: 'row',
    height: '350px',
    backgroundColor: 'black !important',
    color: 'white !important',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        height: 'auto',
        gap: '1rem',
    }
})

export const MissionDetailsBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: '1rem',
    [theme.breakpoints.down('md')]: {
        marginLeft: '0',
    }
})

export const CenteredText = styled(Typography)({
    textAlign: 'center',
})

export const ButtonsBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        marginTop: '2rem',
    }
})

export const StyledImage = styled('img')({
    height: '290px',
    width: '350px',
    border: (props) => props.index % 3 === 0 ? '2px solid #FC6A03'
        : props.index % 2 === 0 ? '2px solid #00d5c0'
        : '2px solid #236ef5',
    [theme.breakpoints.down('md')]: {
        width: '300px',
        height: '200px'
    },
})

export const MissionDescriptionText = styled(Typography)({
    textAlign: 'justify',
})

export const PlayMissionButton = styled(Button)({
    width: '25%',
    [theme.breakpoints.down('md')]: {
        width: '50%',
        height: '45px',
    }
})

export const MissionAuthorBox = styled(Box)({
    display: 'flex',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
    }
})