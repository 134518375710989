import {styled} from "@mui/material/styles";
import {Box, Modal} from "@mui/material";

export const StyledModal = styled(Modal)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

// Styled Modal Content
export const ModalContent = styled(Box)(() => ({
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '4px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
}));
