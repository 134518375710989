import {Box} from "@mui/material";
import styled from "styled-components";
import theme from "../theme";

export const MissionLobbyContainer = styled(Box)({
    marginTop: '50px',
    display: 'flex',
    // height: 'calc(60vh - 50px)',
    backgroundColor: 'black',
    color: 'white',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
    }
})

export const LeftContainer = styled(Box)({

})

export const RightContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    paddingLeft: '2rem',
    width: '100%',
    background: 'url(/public/assets/bg/3.png)',
    [theme.breakpoints.down('md')]: {
        padding: '2rem',
    }
})

export const MissionLobbyImage = styled('img')({
    height: '400px',
    width: '500px',
    display: 'block',
    [theme.breakpoints.down('md')]: {
        height: '300px',
        width: '100%',
    }
})

export const DescriptionBox = styled(Box)({
    width: '70%',
    [theme.breakpoints.down('md')]: {
        width: '100%',
    }
})