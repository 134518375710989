import {styled} from "@mui/material/styles";
import {Box} from "@mui/material";

export const CenteredContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '2rem 4rem',
    [theme.breakpoints.down('lg')]: {
        margin: '0rem',
        padding: '0rem 1.5rem',
    },
}))

export const CenteredContainerAbout = styled(CenteredContainer)(({ theme }) => ({
    [theme.breakpoints.down('lg')]: {
        margin: '2rem 0rem 0rem 0rem',
        padding: '0rem 1rem',
    },
    margin: '32px 200px',
}))

export const CenteredContainerSocialRow = styled(CenteredContainer)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    height: '150px',
    flexDirection: 'row',
    gap: '120px',
    backgroundImage:
        "linear-gradient(to bottom, rgba(35, 110, 245, 0.70), rgba(35, 110, 245, 0.90)), url(/public/assets/bg/2.png)",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    margin: 0,
    marginBottom: '2rem',
    [theme.breakpoints.down('md')]: {
        gap: '2rem',
        margin: 0,
        marginBottom: '2rem',
    }
}))

export const CenteredContainerPartners = styled(CenteredContainer)(({ theme }) => ({
    gap: '0.5rem',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginBottom: '1rem'
    },
    width: '65%',
    margin: 'auto',
    flexDirection: 'column',
}))

export const FlexContainer = styled(Box)(({ flexDirection = 'column' }) => ({
    display: 'flex',
    flexDirection: flexDirection,
    gap: '0.5rem'
}))

export const FlexContainerRow = styled(FlexContainer)({
    flexDirection: 'row',
    padding: '0.5rem',
    '& .MuiFormLabel-root': {
        color: 'white',
    }
})


export const FlexContainerColumn = styled(FlexContainer)({
    flexDirection: 'column',
    padding: '0.5rem',
})