import React, {useEffect, useState} from 'react'
import LeftNavigation from "../../components/admin/LeftNavigation.jsx";
import {AdminRoot} from "../../components/styles/AdminPanel.styled";
import ResourcesMap from "../../components/ResourcesMap.jsx";
import {Box, Button, Paper, Typography} from "@mui/material";
import {approveResource, deleteResource, getMapFeatures, listResources} from "../../utils/api/resources";
import {FlexContainer} from "../../components/styles/Containers.styled";
import {useSelector} from "react-redux";
import {getCoordinates} from "../../slices/resourceSlice";

export default function AdminPanelResources() {

    console.log('render!')
    const [approvedResources, setApprovedResources] = useState([])
    const [pendingResources, setPendingResources] = useState([])
    const [mapFeature, setMapFeature] = useState(null)
    const [features, setFeatures] = useState([])

    const { mapCoordinates } = useSelector((state) => state.resource)

    useEffect(() => {
        const fetchFeatures = async () => {
            const res = await getMapFeatures();
            setFeatures(res.featuresArr)
            console.log(res.featuresArr)
        }
        const fetchResources = async () => {
            const res = await listResources()
            console.log(res.resources)
            res.resources.filter((resource) => {
                if (resource.approved === false) {
                    setPendingResources(prevState => [...prevState, resource])
                }
            })
            res.resources.filter((resource) => {
                if(resource.approved === true) {
                    setApprovedResources(prevState => [...prevState, resource])
                }
            })
        }
        fetchResources()
        fetchFeatures()
    }, [])

    const handleApproveResource = async (resource) => {
        if(!mapCoordinates.length) return alert('Map coordinates not set!')

        const loadPinIcon = (category) => {
            switch (category) {
                case 'Digital youth work':
                    return '/public/assets/map_pin_blue.png'
                case 'Media in youth work':
                    return '/public/assets/map_pin_red.png'
                case 'Best practice':
                    return '/public/assets/map_pin_yellow.png'
                default:
                    return '/public/assets/map_pin_blue.png' // default blue
            }
        }

        const mapFeature = {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: mapCoordinates,
            },
            properties: {
                title: resource.title,
                description: resource.description,
                category: resource.category,
                type: resource.type,
                country: resource.country,
                link: resource.link,
                src: loadPinIcon(resource.category)
            }
        }
console.log('mapFeature: ', mapFeature)
        await approveResource(resource._id, mapFeature)
        alert('Resource approved, refresh page!')
    }

    const handleRejectResource = async (resourceId) => {
        console.log('Delete resource with id: ', resourceId)
        await deleteResource(resourceId)
        alert('Resource rejected, refresh page!')
    }

    return (
        <div>
            <LeftNavigation/>
            <AdminRoot>
                <Box sx={{ width: '99%', mb: 2 }}>
                    <ResourcesMap adminMode={true} features={features}/>
                </Box>
                <Box>
                    <Typography textAlign="center" variant="h5">Pending approval</Typography>
                    <Paper sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', flexDirection: 'row', gap: '1rem' }}>
                        {
                            pendingResources.map((resource) => {
                                return (
                                    <Box key={resource._id} sx={{ p: 1, m: 1, display: 'flex', flexDirection: 'column', width: '30%', backgroundColor: 'lightgray', maxHeight: '250px', overflow: 'auto' }}>
                                        <Typography gutterBottom fontWeight="bold" textAlign="center" variant="body1">{resource.title}</Typography>
                                        <Typography gutterBottom variant="body1">{resource.description}</Typography>
                                        <Typography gutterBottom variant="body1">{resource.link}</Typography>
                                        <Typography gutterBottom variant="body1">{resource.country}</Typography>
                                        <Typography gutterBottom variant="body1">{resource.category}</Typography>
                                        <Typography gutterBottom variant="body1">{resource.type}</Typography>
                                        <Typography gutterBottom variant="body1" fontWeight="bold">Map coordinates: {mapCoordinates.length ? 'set' : 'not set'}</Typography>
                                        <FlexContainer flexDirection="row" sx={{ mt: 2 }}>
                                            <Button sx={{ width: '50%' }} variant="contained" color="primary" onClick={() => handleApproveResource(resource)}>Approve</Button>
                                            <Button sx={{ width: '50%' }} variant="contained" color="secondary" onClick={() => handleRejectResource(resource._id)}>Reject</Button>
                                        </FlexContainer>
                                    </Box>
                                )
                            })
                        }
                    </Paper>
                </Box>
                <Box>
                    <Typography textAlign="center" variant="h5" sx={{ mt: 2 }}>Approved resources</Typography>
                    <Paper sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', flexDirection: 'row', gap: '1rem' }}>
                        {
                            approvedResources.map((resource) => {
                                return (
                                    <Box key={resource._id} sx={{ p: 1, m: 1, display: 'flex', flexDirection: 'column', width: '45%', backgroundColor: 'lightgray', maxHeight: '250px', overflow: 'auto' }}>
                                        <Typography gutterBottom fontWeight="bold" textAlign="center" variant="body1">{resource.title}</Typography>
                                        <Typography gutterBottom variant="body1">{resource.description}</Typography>
                                        <Typography gutterBottom variant="body1">{resource.link}</Typography>
                                        <Typography gutterBottom variant="body1">{resource.country}</Typography>
                                        <Typography gutterBottom variant="body1">{resource.category}</Typography>
                                        <Typography gutterBottom variant="body1">{resource.type}</Typography>
                                        <Button sx={{ mt: 2 }} variant="contained" color="secondary" onClick={() => handleRejectResource(resource._id)}>Delete</Button>
                                    </Box>
                                )
                            })
                        }
                    </Paper>
                </Box>
            </AdminRoot>
        </div>
    )
}

