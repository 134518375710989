export const listResources = async () => {
    try {
        const fetchUrl = `http://37.9.171.147:5000/resources/get`;
        const response = await fetch(fetchUrl);

        if (!response.ok) {
            response.message = 'List resources failed'
        }

        return await response.json();
    } catch (error) {
        // Handle and log errors here
        throw error;
    }
}

export const addResource = async (title, description, link, country, category, type, mapFeature) => {
    try {
        const fetchUrl = `http://37.9.171.147:5000/resources/add`;
        const response = await fetch(fetchUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                title,
                description,
                link,
                country,
                category,
                type,
                mapFeature
            })
        })

        if (!response.ok) {
            response.message = 'Add thread failed'
        }

        return await response.json();
    } catch (error) {
        // Handle and log errors here
        throw error;
    }
}

export const deleteResource = async (id) => {
    try {
        const fetchUrl = `http://37.9.171.147:5000/resources/delete/${id}`;
        const response = await fetch(fetchUrl, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
        })

        if (!response.ok) {
            response.message = 'Delete resource failed'
        }

        return await response.json();
    } catch (error) {
        // Handle and log errors here
        throw error;
    }
}

export const approveResource = async (resourceId, mapFeature) => {
    try {
        const fetchUrl = `http://37.9.171.147:5000/resources/approve`;
        const response = await fetch(fetchUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                resourceId,
                mapFeature
            })
        })

        if (!response.ok) {
            response.message = 'Approve resource failed'
        }

        return await response.json();
    } catch (error) {
        // Handle and log errors here
        throw error;
    }
}

export const getMapFeatures = async () => {
    try {
        const fetchUrl = `http://37.9.171.147:5000/resources/get/map-features`;
        const response = await fetch(fetchUrl);

        if (!response.ok) {
            response.message = 'Get map features failed'
        }

        return await response.json();
    } catch (error) {
        // Handle and log errors here
        throw error;
    }
}