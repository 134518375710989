import React from 'react';
import { useTranslation } from "react-i18next";
import { v4 as uuid } from 'uuid';
import { Typography } from "@mui/material";

function RenderTextIntoParagraphs({ translationKey, paragraphsBeforeLineBreak = 4 }) {
    const { t } = useTranslation('ns1');

    // Get the text from the translations using the 't' function based on the translationKey
    const text = t(translationKey);

    // Check if the text is defined and not empty
    if (text && text.trim().length > 0) {
        // Split the text into sentences
        const sentences = text.trim().split('. ');

        // Create paragraphs from sentences
        const paragraphs = [];
        for (let i = 0; i < sentences.length; i += paragraphsBeforeLineBreak) {
            const paragraph = sentences.slice(i, i + paragraphsBeforeLineBreak).join('. ');
            paragraphs.push(paragraph);
        }

        // Render paragraphs as JSX elements
        return paragraphs.map((paragraph, index) => <Typography sx={{ fontSize: '15px' }} gutterBottom key={uuid()}>{paragraph}</Typography>);
    } else {
        // If the translationKey is not found or text is empty, return an empty array
        return [];
    }
}

export default RenderTextIntoParagraphs;
