import React, {useState} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {Box, Button, IconButton, TextField, Typography} from '@mui/material';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {UserDetailsPaper, UserDetailsTextField} from "./styles/UserDetails.styled";
import { editUserDetails } from '../utils/api/auth'
import {editUser} from "../slices/authSlice";
import {FileUploadOutlined} from "@mui/icons-material";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const validationSchema = Yup.object({
    username: Yup.string()
        .min(2, 'Too Short!')
        .max(12, 'Too Long!')
        .required('Required'),
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
    country: Yup.string()
        .min(2, 'Too Short!')
        .max(12, 'Too Long!')
        .required('Required'),
    url: Yup.string()
        .url('Invalid URL!')
        .required('Required'),
});

export default function UpdateUserDetails({ user, setPage }) {
    const uploadImageRef = React.useRef();

    const dispatch = useDispatch()
    const { auth } = useSelector((state) => state)

    const [isImageUploaded, setIsImageUploaded] = useState(false);
    const [imgPath, setImgPath] = useState(auth.user.avatarUrl);

    const formik = useFormik({
        initialValues: {
            username: user.username,
            email: user.email,
            country: user.country,
            url: user.url,
        },
        validationSchema,
        onSubmit: (values) => {
            editUserDetails(auth.user._id, values.username, values.email, values.country, imgPath, values.url).then((res) => {
                console.log(res)
                if(res.status === 'success') {
                    dispatch(editUser(res.user))
                    setPage('userDetails')
                } else {
                    console.log("Error updating user details")
                }
            })
        },
    });

    const handleAvatarUpload = async (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await fetch('http://37.9.171.147:5000/users/avatar/upload', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                console.log('Image uploaded successfully!');
                const { imagePath } = await response.json();
                setImgPath(imagePath)
                console.log(imagePath)
                setIsImageUploaded(true)
            } else {
                console.error('Error uploading image:', response.statusText);
                setIsImageUploaded(false)
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    return (
        <UserDetailsPaper elevation={2}>
            {/*<Typography variant="h6" component="h6">*/}
            {/*    Update Details*/}
            {/*</Typography>*/}
            <img style={{ width: '250px', paddingBottom: '1rem' }} src={`http://37.9.171.147:5000/public/${user.avatarUrl}`} alt={user.username} />
            <TextField
                inputRef={uploadImageRef}
                type="file"
                variant="standard"
                onChange={handleAvatarUpload}
                InputProps={{
                    endAdornment: (
                        <IconButton component="label">
                            <FileUploadOutlined />
                            <input
                                styles={{display:"none"}}
                                type="file"
                                hidden
                                onChange={handleAvatarUpload}
                                name="avatar"
                            />
                        </IconButton>
                    ),
                }}
            />
            <StyledForm onSubmit={formik.handleSubmit}>
                <UserDetailsTextField
                    id="username"
                    name="username"
                    label="Username"
                    variant="outlined"
                    margin="normal"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.username && Boolean(formik.errors.username)}
                    helperText={formik.touched.username && formik.errors.username}
                />
                <UserDetailsTextField
                    id="email"
                    name="email"
                    label="Email"
                    variant="outlined"
                    margin="normal"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />
                <UserDetailsTextField
                    id="country"
                    name="country"
                    label="Country"
                    variant="outlined"
                    margin="normal"
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.country && Boolean(formik.errors.country)}
                    helperText={formik.touched.country && formik.errors.country}
                />
                <UserDetailsTextField
                    id="url"
                    name="url"
                    label="Url"
                    variant="outlined"
                    margin="normal"
                    value={formik.values.url}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.url && Boolean(formik.errors.url)}
                    helperText={formik.touched.url && formik.errors.url}
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={!formik.isValid || formik.isSubmitting}
                >
                    Update
                </Button>
            </StyledForm>
        </UserDetailsPaper>
    );
}
