import React, {useEffect} from 'react';
import styled from 'styled-components';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Avatar } from '@mui/material';
import {getUsers} from "../utils/api/auth";
import theme from "../components/styles/theme";

const StyledTableContainer = styled(TableContainer)({
    maxWidth: '800px',
    margin: 'auto',
    minWidth: '700px',
    [theme.breakpoints.down('md')]: {
        minWidth: '100%',
    }
})

const StyledTableCell = styled(TableCell)`
  font-weight: bold !important;
  color: white !important;
  border: none !important;
`;

export const StyledTable = styled(Table)`
    & .MuiTableRow-root:nth-child(even) {
        background-color: #202125;
    },
    & .MuiTableRow-root:nth-child(odd) {
        background-color: #27282C;
    }
`

const getRandomPlayers = (players, num) => {
    const shuffled = players.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
};

const RandomPlayersTable = () => {
    const [players, setPlayers] = React.useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            const { users } = await getUsers();
            // Sorting users by createdAt date
            users.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            console.log(users);
            setPlayers(users);
        };
        fetchUsers();
    }, []);

    const randomPlayers = getRandomPlayers(players, 10);

    return (
        <StyledTableContainer component={Paper}>
            <StyledTable>
                <TableHead>
                    <TableRow sx={{ height: '75px', backgroundColor: 'black !important' }}>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>Badges Earned</StyledTableCell>
                        <StyledTableCell>Time Played</StyledTableCell>
                        <StyledTableCell>Sessions Played</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {randomPlayers.map((player) => (
                        <TableRow sx={{ height: '75px' }} key={player._id}>
                            <StyledTableCell>
                                <Avatar alt={player.username} src={player.avatarUrl} />
                            </StyledTableCell>
                            <StyledTableCell>{player.username}</StyledTableCell>
                            <StyledTableCell sx={{ color: '#FC6A03 !important', fontSize: '1.5rem' }}>{player.gameStats.badges}</StyledTableCell>
                            <StyledTableCell sx={{ color: `${theme.palette.secondary.main} !important`, fontSize: '1.5rem' }}>{Math.round(player.gameStats.timePlayed)}<p style={{ color: 'white', display: 'inline', fontSize: '1rem' }}> min</p></StyledTableCell>
                            <StyledTableCell sx={{ color: `${theme.palette.primary.main} !important`, fontSize: '1.5rem' }}>{player.gameStats.sessionsPlayed}</StyledTableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </StyledTableContainer>
    );
};

export default RandomPlayersTable;
