import React from 'react'
import {useTranslation} from "react-i18next";
import {
    FooterFlexBox,
    FooterFlexContainer,
    FooterLogo,
    FooterRoot,
    FooterText,
    FooterTextHeading,
    FooterBottom,
    FooterTop, FooterTextTop,
} from "./styles/Footer";
import Box from "@mui/material/Box";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import {Link} from "react-router-dom";
import {Divider} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import FooterMobile from "./FooterMobile.jsx";

export default function Footer({ isGame = false }) {
    const [t, i18n] = useTranslation('ns1');

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        isMobile ? <FooterMobile/> : (
            <>
                <FooterTop sx={{
                    backgroundColor: isGame ? 'black' : theme.palette.common.lightgray,
                    color: isGame ? 'white' : 'black',
                }}>
                    <FooterLogo src="/public/assets/eu_flag.png" alt="eu_logo" />
                    <FooterFlexContainer>
                        <FooterTextTop>{t('footer_top_text1')}</FooterTextTop>
                        <FooterTextTop>{t('footer_top_text2')}</FooterTextTop>
                        <FooterTextTop>{t('footer_top_text3')}</FooterTextTop>
                    </FooterFlexContainer>
                </FooterTop>
                <FooterRoot>
                    <Box sx={{ width: '30%' }}>
                        <FooterLogo src="/public/assets/logo.png" alt="logo" />
                        <FooterText sx={{ marginBottom: '14px', width: '55%' }}>{t('footer_left_text')}</FooterText>
                        <FooterFlexBox>
                            <a href="https://www.facebook.com/DiGiYouthProject">
                                <FacebookIcon sx={{ marginRight: '10px'}} />
                            </a>
                            <a href="https://www.instagram.com/digiyouth_com/">
                                <InstagramIcon />
                            </a>
                        </FooterFlexBox>

                    </Box>
                    <FooterFlexBox>
                        <FooterFlexContainer>
                            <FooterTextHeading>{t('network')}</FooterTextHeading>
                            <FooterText>
                                <Link to="/network/why-to-join">{t('why_to_join')}</Link>
                            </FooterText>
                            <FooterText>
                                <Link to="/network/public-profile">{t('public_profiles')}</Link>
                            </FooterText>
                            <FooterText>
                                <Link to="/network/forum">{t('forum')}</Link>
                            </FooterText>
                        </FooterFlexContainer>
                        <FooterFlexContainer>
                            <FooterTextHeading>{t('interact')}</FooterTextHeading>
                            <FooterText>
                                <Link to="/interact/explore">{t('explore')}</Link>
                            </FooterText>
                            <FooterText>
                                <Link to="/interact/watch-and-engage">{t('watch_and_engage')}</Link>
                            </FooterText>
                        </FooterFlexContainer>
                        <FooterFlexContainer>
                            <FooterTextHeading>{t('manuals')}</FooterTextHeading>
                            <FooterText>
                                <Link to="/manuals/work-manual">{t('work_manual')}</Link>
                            </FooterText>
                            <FooterText>
                                <Link to="/manuals/media-manual">{t('media_manual')}</Link>
                            </FooterText>
                        </FooterFlexContainer>
                        <FooterFlexContainer>
                            <FooterTextHeading>{t('simulation_game')}</FooterTextHeading>
                            <FooterText>
                                <Link to="/simulation-game">{t('simulation_game_start_now')}</Link>
                            </FooterText>
                        </FooterFlexContainer>
                        <FooterFlexContainer>
                            <FooterTextHeading>{t('tools')}</FooterTextHeading>
                            <FooterText>
                                <Link to="/tools/framework-curriculum">{t('framework_curriculum')}</Link>
                            </FooterText>
                            <FooterText>
                                <Link to="/tools/mentorship-programme">{t('mentorship_programme')}</Link>
                            </FooterText>
                            <FooterText>
                                <Link to="/tools/policy-recommendations">{t('policy_recommendations')}</Link>
                            </FooterText>
                            <FooterText>
                                <Link to="/tools/methodology">{t('methodology')}</Link>
                            </FooterText>
                        </FooterFlexContainer>
                        <FooterFlexContainer>
                            <FooterTextHeading>{t('best_practices')}</FooterTextHeading>
                            <FooterText>
                                <Link to="/best-practices/interactive-map">{t('interactive_map')}</Link>
                            </FooterText>
                            <FooterText>
                                <Link to="/best-practices/list-of-resources">{t('list_of_resources')}</Link>
                            </FooterText>
                        </FooterFlexContainer>
                        <FooterFlexContainer>
                            <FooterTextHeading>{t('media_corner')}</FooterTextHeading>
                            <FooterText>
                                <Link to="/media-corner/news">{t('news')}</Link>
                            </FooterText>
                        </FooterFlexContainer>
                    </FooterFlexBox>
                </FooterRoot>
                <Divider/>
                <FooterBottom>
                    <FooterText>{t('footer_bottom_left')}</FooterText>
                    <FooterFlexContainer sx={{ flexDirection: 'row' }}>
                        <FooterText sx={{ textDecoration: 'underline' }}>
                            <Link to="/info/privacy-policy">{t('privacy_policy')}</Link>
                        </FooterText>
                        <FooterText sx={{ ml: 2, textDecoration: 'underline' }}>
                           <Link to="/info/terms-and-conditions">{t('terms_and_conditions')}</Link>
                       </FooterText>
                    </FooterFlexContainer>
                </FooterBottom>
            </>
        )
    )
}