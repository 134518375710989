import React, {useEffect, useState} from 'react'
import LeftNavigation from "../../components/admin/LeftNavigation.jsx";
import {getAllMissions} from "../../utils/api/mission";
import Missions from "../../components/game/Missions.jsx";
import {AdminRoot} from "../../components/styles/AdminPanel.styled";

export default function AdminPanelGame() {
    const [missions, setMissions] = useState([])

    useEffect(() => {
        const fetchMissions = async () => {
            const { missions } = await getAllMissions()
            // filter missions by missionApproved
            const approvedMissions = missions.filter(mission => !mission.missionApproved)
            setMissions(approvedMissions)
        }
        fetchMissions()
    }, [])

    return (
        <div>
            <LeftNavigation/>
            <AdminRoot>
                <h1>Missions that aren't approved yet</h1>
                <Missions missions={missions} componentType="admin" />
            </AdminRoot>
        </div>
    )
}

