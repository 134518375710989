import React from 'react';
import {
    TopBarRoot,
    TopBarMidWrapper,
    TopBarLeftWrapper,
    TopBarRightWrapper,
    TopBarContainer,
    MenuOption,
    TopBarLogo,
    LanguageSwitch,
    MenuDivider,
    MenuOptionMobile,
    MenuOptionText
} from './styles/TopBar.styled';
import { bindMenu, bindHover } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import HoverMenu from "material-ui-popup-state/HoverMenu";
import logoImage from '../../public/assets/logo.png';
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import {
    Button,
    Drawer,
    IconButton,
    ListItem, ListItemIcon,
    TextField
} from "@mui/material";
import { v4 as uuidv4 } from 'uuid';
import {useSelector} from "react-redux";

import {ChevronLeft} from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import ForumIcon from '@mui/icons-material/Forum';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import DescriptionIcon from '@mui/icons-material/Description';
import MapIcon from '@mui/icons-material/Map';
import CategoryIcon from '@mui/icons-material/Category';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ProfileIcon from '@mui/icons-material/Person2';
import ExploreIcon from '@mui/icons-material/Explore';
import WatchAndEngageIcon from '@mui/icons-material/FeaturedVideo';
import GamesIcon from '@mui/icons-material/Games';
import BookIcon from '@mui/icons-material/Book';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import PolicyIcon from '@mui/icons-material/Policy';
import SettingsIcon from '@mui/icons-material/Settings';
import {useTheme} from "@mui/material/styles";

const networkMenuItems = [
    { label: "why_to_join", path: '/network/why-to-join', icon: <QuestionMarkIcon /> },
    { label: "public_profile", path: '/network/public-profile', icon: <PersonIcon /> },
    { label: "forum", path: '/network/forum', icon: <ForumIcon /> }
];

const manualsMenuItems = [
    { label: "work_manual", path: '/manuals/work-manual', icon: <FileOpenIcon /> },
    { label: "media_manual", path: '/manuals/media-manual', icon: <DescriptionIcon /> },
];

const bestPracticesMenuItems = [
    { label: "interactive_map", path: '/best-practices/interactive-map', icon: <MapIcon /> },
    { label: "list_of_resources", path: '/best-practices/list-of-resources', icon: <CategoryIcon /> },
];

const mediaCornerMenuItems = [
    { label: "news", path: '/media-corner/news', icon: <NewspaperIcon /> },
];

const authMenuItems = [
    { label: "login", path: '/login', icon: <LoginIcon /> },
];

const adminMenuItems = [
    { label: "admin", path: '/admin', icon: <AdminPanelSettingsIcon /> },
];

const logOutMenuItems = [
    { label: "logout", path: '/logout', icon: <LogoutIcon /> },
    { label: "profile", path: '/profile', icon: <ProfileIcon /> },
];

const interactMenuItems = [
    { label: "explore", path: '/interact/explore', icon: <ExploreIcon /> },
    { label: "watch_and_engage", path: '/interact/watch-and-engage', icon: <WatchAndEngageIcon /> },
]

const simulationGameMenuItems = [
    { label: "play", path: '/simulation-game/play', icon: <GamesIcon /> },
];

const toolsMenuItems = [
    { label: "framework_curriculum", path: '/tools/framework-curriculum', icon: <BookIcon /> },
    { label: "mentorship_programme", path: '/tools/mentorship-programme', icon: <LiveHelpIcon /> },
    { label: "policy_recommendations", path: '/tools/policy-recommendations', icon: <PolicyIcon /> },
    { label: "methodology", path: '/tools/methodology', icon: <SettingsIcon /> },
];

const languageOptions = [
    "en", "bg", "si", "gr", "tr"
];

const drawerWidth = '250px';

export default function TopBarMobile({ isGame }){
    const location = useLocation();
    const [t, i18n] = useTranslation('ns1');
    const { isAuthenticated } = useSelector((state) => state.auth)
    const { user } = useSelector((state) => state.auth)
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();

    const getList = (menuItemsArr) => (
        <div onClick={() => setOpen(false)}>
            {menuItemsArr.map((item) => (
                <Link key={uuidv4()} to={item.path}>
                    <MenuOptionMobile>
                        {item.icon}
                        <MenuOptionText>{t(item.label)}</MenuOptionText>
                    </MenuOptionMobile>
                </Link>
            ))}
        </div>
    );

    const renderMenuOption = (item) => {
        return (
            <Link key={uuidv4()} to={item.path}>
                <MenuOption>
                    {t(item.label)}
                </MenuOption>
            </Link>
        )
    }

    return (
        <TopBarRoot
            sx={{
                backgroundColor: isGame ? 'lightgray' : theme.palette.common.white,
                color: isGame ? theme.palette.common.white : theme.palette.common.black,
        }}
        >
            <TopBarContainer>
                <TopBarLeftWrapper>
                    <MenuIcon onClick={() => setOpen(true)}/>
                    <Drawer
                        anchor="left"
                        open={open}
                        onClose={() => setOpen(false)}
                        sx={{ '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' } }}
                    >
                        <MenuDivider />
                        <Link to={'/'}>
                            <MenuOptionMobile>
                                <HomeIcon />
                                <MenuOptionText>{t('home')}</MenuOptionText>
                            </MenuOptionMobile>
                        </Link>
                        <MenuDivider />
                        <MenuOption disabled>{t('network')}</MenuOption>
                        <MenuDivider />
                        {getList(networkMenuItems)}
                        <MenuDivider />
                        <MenuOption disabled>{t('manuals')}</MenuOption>
                        {getList(manualsMenuItems)}
                        <MenuDivider />
                        <MenuOption disabled>{t('tools')}</MenuOption>
                        {getList(toolsMenuItems)}
                        <MenuDivider />
                        <MenuOption disabled>{t('simulation_game')}</MenuOption>
                        <MenuDivider />
                        {getList(simulationGameMenuItems)}
                        <MenuOption disabled>{t('best_practices')}</MenuOption>
                        <MenuDivider />
                        {getList(bestPracticesMenuItems)}
                        <MenuDivider />
                        <MenuOption disabled>{t('media_corner')}</MenuOption>
                        <MenuDivider />
                        {getList(mediaCornerMenuItems)}
                        <MenuDivider />
                        <MenuOption disabled>{t('interact')}</MenuOption>
                        {getList(interactMenuItems)}
                        <MenuDivider />
                        {isAuthenticated ? getList(logOutMenuItems) : getList(authMenuItems)}
                        {user.role === 'admin' && getList(adminMenuItems)}
                    </Drawer>
                </TopBarLeftWrapper>
                <TopBarMidWrapper>
                    <React.Fragment>
                        <TopBarLogo src={logoImage} alt="logo" />
                    </React.Fragment>
                </TopBarMidWrapper>
                <TopBarRightWrapper>
                    <LanguageSwitch
                        disableClearable
                        options={languageOptions}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        value={i18n.language}
                        onChange={(event, newValue) => i18n.changeLanguage(newValue)}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </TopBarRightWrapper>
            </TopBarContainer>
        </TopBarRoot>
    )
}