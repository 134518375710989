import styled from "styled-components";
import {Box, Paper} from "@mui/material";
import theme from "../theme";

export const MissionEndCardsContainer = styled(Box)({
    display: 'flex',
    marginTop: '2rem',
    gap: '5rem',
    justifyContent: 'center',
    '& .MuiPaper-root': {
        width: '200px',
        height: '200px',
        padding: '1rem',
    },
    [theme.breakpoints.down('md')]: {
        gap: '1rem',
        margin: '1rem'
    }
})

export const MissionEndCard = styled(Paper)({
    display: 'flex',
    flexDirection: 'column',
})