import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import {Drawer, ListItem, ListItemIcon, ListItemText} from '@mui/material'
import {styled} from "@mui/material/styles";
import HomeIcon from '@mui/icons-material/Home';
import TranslateIcon from '@mui/icons-material/Translate';
import EditIcon from '@mui/icons-material/Edit';
import GamesIcon from '@mui/icons-material/Games';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PeopleIcon from '@mui/icons-material/People';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';

export const NAVIGATION_WIDTH = 250;

const menuItems = [
    { name: "Home", icon: <HomeIcon />, link: "/admin" },
    { name: "Translations", icon: <TranslateIcon />, link: "/admin/translations" },
    { name: "Resources", icon: <EditIcon />, link: "/admin/resources" },
    { name: "Simulation Game", icon: <GamesIcon />, link: "/admin/game" },
    { name: "News", icon: <NewspaperIcon />, link: "/admin/news" },
    { name: "Team", icon: <PeopleIcon />, link: "/admin/team" },
    { name: "Virtual Classroom", icon: <LocalLibraryIcon />, link: "/admin/virtual-classroom" },
    { name: "Materials" , icon: <LocalLibraryIcon />, link: "/admin/materials"},
    { name: "Back to Website", icon: <ArrowBackIcon />, link: "/"},
];

export const DrawerNavigation = styled(Drawer)(({ theme }) => ({
    height: '50px',
    backgroundColor: theme.palette.common.white,
    width: '100%',
}))

export default function LeftNavigation() {
    const navigate = useNavigate();

    const getMenuItems = () => (
        <div style={{ width: NAVIGATION_WIDTH }}>
            {menuItems.map((item, index) => (
                <ListItem button key={index} onClick={() => navigate(item.link)}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.name} />
                </ListItem>
            ))}
        </div>
    );

    return (
        <div>
            <DrawerNavigation variant="permanent" open={true} anchor={"left"}>
                {getMenuItems()}
            </DrawerNavigation>
        </div>
    )
}