import React, {useEffect} from 'react';
import {Box, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {MissionEndCard, MissionEndCardsContainer} from "../styles/simulationGame/MissionEnd.styled";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {StatCard} from "../styles/simulationGame/SimulationGamePage.styled";
import {setPartialGameStats} from "../../utils/api/game";
import {addSession} from "../../utils/api/session";
import CheckLinks from "../CheckLinks.jsx";
import {useNavigate} from "react-router-dom";

function MissionEnd() {
    const dispatch = useDispatch();
    const { t } = useTranslation('ns1');
    const { badgesEarned } = useSelector(state => state.game);
    const { timePlayed } = useSelector(state => state.game);
    const { selectedMission } = useSelector(state => state.game);
    const { user } = useSelector(state => state.auth)
    const navigate = useNavigate()

    const checkLinksButtons = [
        { label: 'Forum', onClick: () => navigate('/network/forum'), variant: 'contained', color: 'secondary' },
        { label: 'Media Manual', onClick: () => navigate('/manuals/media-manual'), variant: 'contained', color: 'secondary' },
        { label: 'Work Manual', onClick: () => navigate('/manuals/work-manual'), variant: 'contained', color: 'secondary' },
    ];


    if(badgesEarned === 0) {
        // do nothing, no badges earned
    } else {
        console.log('missionend badges earned user:', user)
        const [minutes, seconds] = timePlayed.split(":").map(Number);
        const totalMinutes = seconds / 60
        const userId = user._id
        const gameStats = {
            badges: badgesEarned,
            sessionsPlayed: 1,
            timePlayed: totalMinutes,
        }
        setPartialGameStats(userId, gameStats)
        addSession(badgesEarned, 0, totalMinutes, new Date(), selectedMission.sequentialId)
    }

    return (
        <>
            <Typography variant="h5" textAlign="center" gutterBottom sx={{ color: '#FC6A03', textTransform: 'uppercase', fontWeight: 'bold' }}>
                Mission {selectedMission?.sequentialId}
            </Typography>
            <Box sx={{ width: '70%', margin: '0 auto' }}>
                <Typography variant="h5" textAlign="center" gutterBottom fontWeight="bold">{selectedMission?.title}</Typography>
                <Typography variant="h6" textAlign="center" gutterBottom>{t('mission_complete_text')}</Typography>
                <Typography variant="h6" textAlign="center">{t('mission_complete_text2')}</Typography>
                <Typography variant="h6" textAlign="center">{t('mission_complete_text3')}</Typography>
            </Box>
            <MissionEndCardsContainer>
                <StatCard backgroundColor="#FC6A03" elevation={5}>
                    <MilitaryTechIcon sx={{ width: '90px', height: '90px'}}/>
                    <Typography variant="h5" component="h5" gutterBottom textAlign="center" fontWeight="bold" textTransform="uppercase">
                        {t('badges')}
                    </Typography>
                    <Typography fontWeight="bold" variant="h4" gutterBottom>{badgesEarned}</Typography>
                </StatCard>
                <StatCard backgroundColor="#00d5c0" elevation={5}>
                    <AccessTimeIcon sx={{ width: '90px', height: '90px'}}/>
                    <Typography variant="h5" component="h5" gutterBottom textAlign="center" fontWeight="bold" textTransform="uppercase">
                        {t('simulation_game_time_played')}
                    </Typography>
                    <Typography fontWeight="bold" variant="h4" gutterBottom>{timePlayed}</Typography>
                </StatCard>
            </MissionEndCardsContainer>
            <CheckLinks buttonDetails={checkLinksButtons} />
        </>
    );
}

export default MissionEnd;