import React, { useEffect, useState } from 'react';
import { useDrop, useDrag, DndProvider } from 'react-dnd';
import { HTML5Backend } from "react-dnd-html5-backend";
import { Typography, Box } from '@mui/material';
import { useDispatch } from 'react-redux';
// Import your Redux actions here, for example, addWordToBlank, removeWordFromBlank

const ItemType = {
    WORD: 'WORD'
};

const DraggableWord = ({ word }) => {
    const [, ref] = useDrag({
        type: ItemType.WORD,
        item: { word }
    });
    return (
        <div ref={ref} style={{ padding: '8px', border: '1px solid black', margin: '4px' }}>
            {word}
        </div>
    );
};

const DroppableBlank = ({ acceptWord }) => {
    const [, ref] = useDrop({
        accept: ItemType.WORD,
        drop: (item) => acceptWord(item.word),
    });
    return (
        <div ref={ref} style={{ padding: '16px', border: '1px dashed gray', width: '100px', textAlign: 'center' }}>
            Drop here
        </div>
    );
};

const DragTheWords = ({ question }) => {
    const [droppedWord, setDroppedWord] = useState(null);
    const dispatch = useDispatch();

    const acceptWord = (word) => {
        setDroppedWord(word);
        // Dispatch your Redux actions here, for example:
        // dispatch(addWordToBlank({ title: question.question, word: word }));
    };

    useEffect(() => {
        // Reset the state when the question changes
        setDroppedWord(null);
        // Add more reset logic if needed, similar to what you do in MultipleChoice component
    }, [question]);

    return (
        <DndProvider backend={HTML5Backend}>
            <Typography variant="h6" textAlign="center">{question.question}</Typography>
            <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant="body1">Blueberries are</Typography>
                <DroppableBlank acceptWord={acceptWord} />
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                {question.answers.map((word, index) => (
                    <DraggableWord key={index} word={word} />
                ))}
            </Box>
        </DndProvider>
    );
};

export default DragTheWords;
