export default function formatDate(dateString) {
    const date = new Date(dateString);
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour12: true
    };

    return date.toLocaleDateString(undefined, options);
}