import React from 'react';
import TopBar from "../components/TopBar.jsx";
import LoginForm from "../components/LoginForm.jsx";
import {HeadingText, Text, TextBottomMargin} from "../components/styles/Typography.styled";
import {useTranslation} from "react-i18next";
import {LeftBox, MainWrapper, RightBox} from "../components/styles/FormComponents.styled";
import Footer from "../components/Footer.jsx";
import RegisterForm from "../components/RegisterForm.jsx";

export default function WhyToJoin() {
    const [t, i18n] = useTranslation('ns1');

    return (
        <>
            <TopBar/>
            <MainWrapper>
                <LeftBox>
                    <HeadingText sx={{ marginBottom: '1rem', textAlign: 'center' }} variant="h6">{t('why_to_join')}?</HeadingText>
                    <TextBottomMargin>{t('why_to_join_paragraph1')}</TextBottomMargin>
                    <TextBottomMargin>{t('why_to_join_paragraph2')}</TextBottomMargin>
                    <TextBottomMargin>{t('why_to_join_paragraph3')}</TextBottomMargin>
                    <TextBottomMargin>{t('why_to_join_paragraph4')}</TextBottomMargin>
                </LeftBox>
                <RightBox>
                    <HeadingText sx={{ marginBottom: '1rem', textAlign: 'center' }} variant="h6">{t('register_text')}</HeadingText>
                    <RegisterForm/>
                </RightBox>
            </MainWrapper>
            <Footer />
        </>
    )
}