import React, {useEffect, useState} from 'react';
import {GameContainer, GameBackgroundVideo, GameButtonsContainer} from "../styles/simulationGame/Game.styled";
import {useDispatch, useSelector} from "react-redux";
import TopBar from "../TopBar.jsx";
import Footer from "../Footer.jsx";
import {Box, Button, Typography} from "@mui/material";
import MultipleChoiceQuestion from "./questions/MultipleChoice.jsx";
import OrderedListQuestion from "./questions/OrderedList.jsx";
import DragTheWordsQuestion from "./questions/DragTheWords.jsx";
import FillInTheBlanksDropdownQuestion from "./questions/FillInTheBlanksDropdown.jsx";
import {useNavigate} from "react-router-dom";
import {
    addBadgesEarned,
    resetState,
    setMissionEnded,
    setQuestionType,
    setTimePlayedInMinutes
} from "../../slices/gameSlice";
import MissionEnd from "./MissionEnd.jsx";
import MissionEndSummary from "./MissionEndSummary.jsx";
import FillInTheBlanksQuestion from "./questions/FillInTheBlanks.jsx";

// Mapping question types to their components
const QUESTION_COMPONENTS = {
    "multiple_choice": MultipleChoiceQuestion,
    "ordered_list": OrderedListQuestion,
    "fill_in_the_blanks": FillInTheBlanksQuestion,
    "fill_in_the_blanks_dropdown": FillInTheBlanksDropdownQuestion,
    "drag_the_words": DragTheWordsQuestion,
};

function millisecondsToMinutesAndSeconds(ms) {
    let minutes = Math.floor(ms / 60000);
    let seconds = ((ms % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
}

function SimulationGame() {
    const { selectedMission } = useSelector(state => state.game);
    const { missionEnded } = useSelector(state => state.game);
    const { questionAnswers } = useSelector(state => state.game);

    const [questionIndex, setQuestionIndex] = useState(0);
    const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);
    const [nextButtonText, setNextButtonText] = useState("Next");

    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if (Object.keys(selectedMission).length === 0) {
            navigate('/simulation-game/')
        }
        setStartTime(Date.now());

        return () => {
            dispatch(resetState())
        }

    }, [])

    if(selectedMission && Object.keys(selectedMission).length === 0) return null

    const showQuestion = () => {
        if(!selectedMission) return null
        const currentQuestion = selectedMission.questions[questionIndex];
        if (allQuestionsAnswered) return <MissionEnd />;

        const QuestionComponent = QUESTION_COMPONENTS[currentQuestion.questionType];
        dispatch(setQuestionType(currentQuestion.questionType)) // set question type on question load
        if (QuestionComponent) {
            return <QuestionComponent
                className="question-component"
                question={currentQuestion}
            />;
        } else {
            return <h1>Unknown question type</h1>;
        }
    };

    const handleNext = () => {
        if(!selectedMission) return null
        if (questionIndex === selectedMission.questions.length - 2) setNextButtonText("Finish")
        if (questionIndex < selectedMission.questions.length - 1) {
            setQuestionIndex(prevIndex => prevIndex + 1);
        } else {
            setAllQuestionsAnswered(true);
            const end = Date.now();
            setEndTime(end);
            const timeSpent = end - startTime;
            const formattedTimeSpent = millisecondsToMinutesAndSeconds(timeSpent);
            dispatch(setTimePlayedInMinutes(formattedTimeSpent));
            dispatch(setMissionEnded(true))
        }
    };

    return (
        <>
            <TopBar isGame={true} />
            <GameBackgroundVideo autoPlay loop muted>
                <source src="public/assets/game/background1.mp4" type="video/mp4" />
            </GameBackgroundVideo>
            <GameContainer>
                {/*<Typography variant="h4" textAlign="center" gutterBottom>Simulation Game</Typography>*/}
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '80%', justifyContent: 'center', color: 'white' }}>
                    {showQuestion()}
                    { !allQuestionsAnswered &&
                        <GameButtonsContainer>
                            <Button sx={{ width: '130px', fontWeight: 'bold' }} variant="contained" color="secondary" onClick={handleNext}>
                                Skip
                            </Button>
                            <Typography fontWeight="bold"> {questionIndex + 1 + '/' + selectedMission.questions.length} </Typography>
                            <Button
                                sx={{ width: '130px', fontWeight: 'bold' }}
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                disabled={!questionAnswers[questionIndex]?.answers.length}
                            >
                                {nextButtonText}
                            </Button>
                        </GameButtonsContainer>
                    }
                </Box>
            </GameContainer>
            {
                missionEnded && <MissionEndSummary />
            }
            <Footer isGame={true} />
        </>
    );
}

export default SimulationGame;
