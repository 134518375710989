import React, {useEffect} from 'react';
import { Typography } from "@mui/material";
import { useState } from "react";
import {
    StyledCard,
    MCRoot,
    CardsContainer,
    StyledCardContent,
    AnswerText
} from '../../styles/simulationGame/MultipleChoiceQuestion.styled'
import { v4 as uuidv4 } from 'uuid';
import {
    addAnswerToQuestion,
    addBadgesEarned,
    addQuestionAnswer,
    removeAnswerFromQuestion,
    removeBadgesEarned
} from "../../../slices/gameSlice";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";

function MultipleChoice({ question }) {
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const dispatch = useDispatch();
    const { t } = useTranslation('ns1');

    const handleAnswerClick = (answer) => {
        if (selectedAnswers.includes(answer)) {
            // Answer deselect
            setSelectedAnswers(prev => prev.filter(i => i !== answer));
            dispatch(removeAnswerFromQuestion({ title: question.question, answer: answer }))
            if(isAnswerCorrect(answer)) dispatch(removeBadgesEarned(1));
        } else {
            // Answer select
            setSelectedAnswers(prev => [...prev, answer]);
            if(isAnswerCorrect(answer)) dispatch(addBadgesEarned(1));
            dispatch(addAnswerToQuestion({ title: question.question, answer: answer }))
        }
    };

    useEffect(() => {
        setSelectedAnswers([]);
        const questionAnswer = {
            question: question.question,
            answers: [],
        }
        dispatch(addQuestionAnswer(questionAnswer))
    }, [question]);

    const isAnswerCorrect = (answer) => {
        return question.correctAnswers.includes(answer);
    }

    return (
        <MCRoot>
            <Typography variant="h6" textAlign="center" color="common.orange" fontWeight="bold" gutterBottom>{t(`question_guidance_${question.questionType}_text`)}</Typography>
            <Typography variant="h6" textAlign="center" color="white" fontWeight="bold">{question.question}</Typography>
            <CardsContainer>
                {question.answers.map((answer, index) => (
                    <StyledCard
                        key={uuidv4()}
                        selected={selectedAnswers.includes(answer)}
                        onClick={() => handleAnswerClick(answer, index)}
                    >
                        <StyledCardContent>
                            <AnswerText selected={selectedAnswers.includes(answer)}>{answer}</AnswerText>
                        </StyledCardContent>
                    </StyledCard>
                ))}
            </CardsContainer>
        </MCRoot>
    );
}

export default MultipleChoice;