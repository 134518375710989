import React, {useState} from 'react'
import TopBar from "../components/TopBar.jsx";
import ImageCarousel from "../components/ImageCarousel.jsx";
import {HeadingText, Text} from "../components/styles/Typography.styled";
import {
    CenteredContainer,
    CenteredContainerAbout, CenteredContainerPartners, CenteredContainerSocialRow,
    FlexContainer,
} from "../components/styles/Containers.styled";
import {styled, useTheme} from "@mui/material/styles";
import { motion } from "framer-motion"
import RecentNews from "../components/RecentNews.jsx";
import {useTranslation} from "react-i18next";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import {TeamSlider} from "../components/TeamSlider.jsx";
import Footer from "../components/Footer.jsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../components/styles/theme";

const carouselSlides = [
    {
        title: "carousel_slide1_title",
        description: "carousel_slide1_description",
        imageUrl: "/public/assets/sliderImage1.jpg",
    },
    {
        title: "carousel_slide2_title",
        description: "carousel_slide2_description",
        imageUrl: "/public/assets/sliderImage2.jpg",
    },
    {
        title: "carousel_slide3_title",
        description: "carousel_slide3_description",
        imageUrl: "/public/assets/sliderImage3.jpg",
    },
];

export const Image = styled('img')({
    width: '100%',
    height: 'auto',
    [theme.breakpoints.down('md')]: {
        height: '60px',
    },
    maxWidth: '100%'
});

const PartnerImage = styled(Image)({
    width: '200px',
    height: 'auto',
    marginBottom: '1rem'
})

export default function Home() {
    return (
        <>
            <TopBar />
                <ImageCarousel slides={carouselSlides} slideChangeTime={5} />
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <AboutTheProject />
            </motion.div>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                <RecentNews />
            </motion.div>
            <SocialMediaRow />
            <Partners />
            <TeamMembers />
            <Footer />
        </>
    )
}

const AboutTheProject = () => {
    const [t, i18n] = useTranslation('ns1');

    return (
        <CenteredContainerAbout sx={{ gap: '0.5rem', textAlign: 'center', mt: 4 }}>
            <HeadingText variant="h5" sx={{ mb: 1 }}>{t('about_the_project_heading')}</HeadingText>
            <Text>{t('about_the_project_text1')}</Text>
            <Image src="/public/assets/aboutTheProject1.jpg" alt="aboutImg1"/>
            <Text>{t('about_the_project_text2')}</Text>
            <Image src="/public/assets/aboutTheProject2.jpg" alt="aboutImg2"/>
            <Text>{t('about_the_project_text3')}</Text>
            <Image src="/public/assets/aboutTheProject3.jpg" alt="aboutImg3"/>
            <Text>{t('about_the_project_text4')}</Text>
        </CenteredContainerAbout>
    )
}

const SocialMediaRow = () => {
    return (
        <CenteredContainerSocialRow>
            <motion.div
                initial={{ scale: 1 }}
                whileHover={{ scale: 1.4 }}
                transition={{ duration: 0.3 }}
            >
                <a href="https://www.facebook.com/DiGiYouthProject">
                    <FacebookIcon sx={{ fontSize: '4rem', color: (theme) => theme.palette.common.white, m: 2 }} />
                </a>
            </motion.div>
            <motion.div
                initial={{ scale: 1 }}
                whileHover={{ scale: 1.4 }}
                transition={{ duration: 0.3 }}
            >
                <a href="https://www.instagram.com/digiyouth_com/">
                    <InstagramIcon sx={{ fontSize: '4rem', color: (theme) => theme.palette.common.white, m: 2 }} />
                </a>
            </motion.div>
            <motion.div
                initial={{ scale: 1 }}
                whileHover={{ scale: 1.4 }}
                transition={{ duration: 0.3 }}
            >
                <a href="https://www.linkedin.com/showcase/digi-youth-project">
                    <LinkedInIcon sx={{ fontSize: '4rem', color: (theme) => theme.palette.common.white, m: 2 }} />
                </a>
            </motion.div>
        </CenteredContainerSocialRow>
    );
};

const PartnerContainer = styled(FlexContainer)(({ theme }) => ({
    textAlign: 'justify',
    // width: '25rem',
    padding: '4rem 2rem',
    [theme.breakpoints.down('md')]: {
        padding: '1rem 1rem',
    },
    height: 'fit-content',
    alignItems: 'center'
}))

const TeamRoot = styled(FlexContainer)(({ theme }) => ({
    padding: '4rem 4rem',
    backgroundImage: 'linear-gradient(to bottom, rgba(35, 110, 245, 0.80), rgba(35, 110, 245, 0.90)), url(/public/assets/sliderImage2.jpg)'
}))

const Partners = () => {
    const [t, i18n] = useTranslation('ns1');

    const theme = useTheme();
    const isMobileLg = useMediaQuery(theme => theme.breakpoints.down('lg'));

    return (
        <>
            <HeadingText variant="h5" sx={{ textAlign: 'center' }}>{t('partners_heading')}</HeadingText>
            <CenteredContainerPartners>
                <FlexContainer flexDirection={isMobileLg ? 'column' : 'row'}>
                    <PartnerContainer>
                        <PartnerImage src="/public/assets/logo_tfn.png" alt="tfn"/>
                        <Text>{t('partners_tfn_text')}</Text>
                    </PartnerContainer>
                    <PartnerContainer>
                        <PartnerImage src="/public/assets/logo_mcc.png" alt="mcc"/>
                        <Text>{t('partners_mcc_text')}</Text>
                    </PartnerContainer>
                    <PartnerContainer>
                        <PartnerImage src="/public/assets/logo_ed.png" alt="ed"/>
                        <Text>{t('partners_ed_text')}</Text>
                    </PartnerContainer>
                </FlexContainer>
                <FlexContainer flexDirection={isMobileLg ? 'column' : 'row'} sx={{ width: '85%' }}>
                    <PartnerContainer sx={{ pt: 0 }}>
                        <PartnerImage src="/public/assets/logo_yasar.png" alt="yasar"/>
                        <Text>{t('partners_yasar_text')}</Text>
                    </PartnerContainer>
                    <PartnerContainer sx={{ pt: 0 }}>
                        <PartnerImage src="/public/assets/logo_higgs.png" alt="higgs"/>
                        <Text>{t('partners_higgs_text')}</Text>
                    </PartnerContainer>
                </FlexContainer>
            </CenteredContainerPartners>
        </>
    )
}

const TeamMembers = () => {
    const [t, i18n] = useTranslation('ns1');

    return (
        <TeamRoot>
            <HeadingText variant="h5" sx={{ mb: 1, textAlign: 'center', color: (theme) => theme.palette.common.white }}>Team</HeadingText>
            <Text sx={{ mb: 2, textAlign: 'center', color: (theme) => theme.palette.common.white }}>{t('team_postheading_text')}</Text>
            <TeamSlider />
        </TeamRoot>
    )
}