import React, {useState} from 'react'
import {CenteredContainer} from "../../components/styles/Containers.styled";
import {HeadingText} from "../../components/styles/Typography.styled";
import {Box} from "@mui/material";
import {TextBox} from "../../components/styles/UserInputs.styled";
import {ButtonStyled} from "../../components/styles/UserButtons.styled";
import {FlexContainer} from "../../components/styles/Containers.styled";

export default function AdminPanelLogin() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleUsernameChange = (event) => setUsername(event.target.value);
    const handlePasswordChange = (event) => setPassword(event.target.value);
    const handleSubmit = (event) => {
        console.log('handle submit');
    }

    return (
        <CenteredContainer>
            <HeadingText variant="h4">Admin panel</HeadingText>
            <HeadingText variant="h5">Login</HeadingText>
            <form onSubmit={handleSubmit}>
                <FlexContainer>
                    <TextBox
                        id="username"
                        label="Username"
                        variant="outlined"
                        value={username}
                        onChange={handleUsernameChange}
                    />
                    <TextBox
                        id="password"
                        label="Password"
                        variant="outlined"
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                    <ButtonStyled variant="contained" type="submit">Login</ButtonStyled>
                </FlexContainer>
            </form>
        </CenteredContainer>
    )
}

