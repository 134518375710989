import { styled } from '@mui/material/styles';
import {Autocomplete, Box, Divider, MenuItem} from "@mui/material";

export const TopBarRoot = styled(Box)(({ theme }) => ({
    height: '50px',
    backgroundColor: theme.palette.common.white,
    width: '100vw',
    position: 'fixed',
    top: '0px',
    left: '0px',
    zIndex: '1000',
}));

export const MenuOption = styled(MenuItem)(({ theme }) => ({
    // due to issue with MUI MenuItem component, we need to use !important (MuiButtonBase overwrites our styles)
    borderRadius: '10px !important',
    '&.active': {
        color: 'gray',
    },
    [theme.breakpoints.down('xl')]: {
        paddingLeft: '8px !important',
        paddingRight: '8px !important',
    }
}))

export const MenuOptionMobile = styled(MenuOption)({
    fontSize: '15px',
})

export const MenuOptionText = styled('span')({
    marginLeft: '8px',
})

export const TopBarMidWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    margin: '0 auto',
    padding: '0 20px',
    textTransform: 'uppercase',
});

export const TopBarLeftWrapper = styled(Box)({
    paddingLeft: '5px',
    width: '80px',
});

export const TopBarRightWrapper = styled(Box)({
    display: 'flex',
});

export const TopBarContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 'inherit',
    [theme.breakpoints.down('lg')]: {
        padding: '0px 5px'
    }
}));

export const TopBarLogo = styled('img')({
    height: '43px',
    width: '90px',
    paddingTop: '5px',
});

export const LanguageSwitch = styled(Autocomplete)(({ theme }) => ({
    width: '70px',
    '& .MuiAutocomplete-inputRoot': {
        padding: '0px !important',
        height: '30px',
    },
}))

export const MenuDivider = styled(Divider)({
    marginTop: '0px !important',
    marginBottom: '0px !important'
})