import React from 'react'
import {UserDetailsHeadingText, UserDetailsPaper, UserDetailsText} from "./styles/UserDetails.styled";
import formatDate from "../utils/formatDate";

export default function UserDetails({ user }) {
    return (
        <UserDetailsPaper elevation={5}>
            <img style={{ width: '250px', paddingBottom: '1rem' }} src={`http://37.9.171.147:5000/public/${user.avatarUrl}`} alt={user.username} />
            <UserDetailsHeadingText>Email:</UserDetailsHeadingText>
            <UserDetailsText>{user.email}</UserDetailsText>
            <UserDetailsHeadingText>Username:</UserDetailsHeadingText>
            <UserDetailsText>{user.username}</UserDetailsText>
            <UserDetailsHeadingText>Role:</UserDetailsHeadingText>
            <UserDetailsText sx={{ textTransform: 'uppercase' }}>{user.role}</UserDetailsText>
            <UserDetailsHeadingText>Country:</UserDetailsHeadingText>
            <UserDetailsText>{user.country}</UserDetailsText>
            <UserDetailsHeadingText>Joined on:</UserDetailsHeadingText>
            <UserDetailsText>{formatDate(user.createdAt)}</UserDetailsText>
            <UserDetailsHeadingText>URL:</UserDetailsHeadingText>
            <UserDetailsText>{user.url ? user.url : 'Not set'}</UserDetailsText>
        </UserDetailsPaper>
    )
}