import React, {useEffect} from 'react'
import TopBar from "../components/TopBar.jsx";
import MissionLobby from "../components/game/MissionLobby.jsx";
import {MissionLobbyContainer} from "../components/styles/simulationGame/MissionLobby.styled";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Footer from "../components/Footer.jsx";

export default function SimulationGameMissionLobby() {

    const { selectedMission } = useSelector(state => state.game)
    const navigate = useNavigate()

    useEffect(() => {
        if (Object.keys(selectedMission).length === 0) {
            navigate('/simulation-game/')
        }
    }, [])

    return (
        <>
            <TopBar/>
            <MissionLobbyContainer>
                <MissionLobby mission={selectedMission}/>
            </MissionLobbyContainer>
            <Footer />
        </>
    )
}