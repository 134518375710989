import React from 'react'
import TopBar from "../components/TopBar.jsx";
import {ManualsRoot, ManualsTextBox} from "../components/styles/ManualsPages.styled";
import {useTranslation} from "react-i18next";
import {Typography} from "@mui/material";
import DynamicTextWithLineBreaks from "../utils/insertLineBreaks.jsx";
import RenderTextIntoParagraphs from "../utils/insertLineBreaks.jsx";
import ShowPdfPreview from "../components/ShowPdfPreview.jsx";
import Footer from "../components/Footer.jsx";

export default function MediaManual() {
    const [t, i18n] = useTranslation('ns1');

    return (
        <>
            <TopBar/>
            <ManualsRoot>
                <Typography variant="h6" align="center" gutterBottom>
                    {t('media_manual_title')}
                </Typography>
                <ManualsTextBox>
                    <RenderTextIntoParagraphs translationKey="media_manual_text" paragraphsBeforeLineBreak={4}/>
                </ManualsTextBox>
                <ShowPdfPreview pdfFiles={['media_manual1.pdf', 'media_manual2.pdf']}/>
            </ManualsRoot>
            <Footer/>
        </>
    )
}