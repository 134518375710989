export async function assignCountryByIp(countries) {
    try {
        const response = await fetch('https://ipapi.co/json/');
        if (!response.ok) {
            throw new Error('Unable to retrieve IP location.');
        }

        const data = await response.json();
        const country = data.country_name;

        const matchingCountry = countries.find(c => c.label === country);
        if (matchingCountry) {
            return matchingCountry;
        } else {
            // Handle case when no match is found
            console.log('No matching country found.');
        }
    } catch (error) {
        console.error('Error:', error.message);
    }
}
