import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../slices/authSlice';
import forumReducer from '../slices/forumSlice';
import resourceSlice from "../slices/resourceSlice";
import gameSlice from "../slices/gameSlice";
import scenarioBuilderSlice from "../slices/scenarioBuilderSlice";

export default configureStore({
    reducer: {
        auth: authReducer,
        forum: forumReducer,
        resource: resourceSlice,
        game: gameSlice,
        builder: scenarioBuilderSlice,
    },
});
