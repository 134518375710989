// import PropTypes from "prop-types";
import React from "react";
import {Box, Button, Typography} from "@mui/material";

const CheckLinks = ({ buttonDetails }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '16px',
                marginTop: '2rem',
            }}
        >
            {buttonDetails.map((button, index) => (
                <Button
                    key={index}
                    variant={button.variant || 'contained'}
                    color={button.color || 'primary'}
                    onClick={button.onClick}
                    sx={{
                        width: '180px',
                    }}
                >
                    <Typography fontWeight="bold">{button.label}</Typography>
                </Button>
            ))}
        </Box>
    );
};

// CheckLinks.propTypes = {
//     buttonDetails: PropTypes.arrayOf(
//         PropTypes.shape({
//             label: PropTypes.string.isRequired,
//             onClick: PropTypes.func,
//             variant: PropTypes.string,
//             color: PropTypes.string,
//         })
//     ).isRequired,
// };

export default CheckLinks;