import styled from 'styled-components'
import {Box} from "@mui/material";

export const ManualsRoot = styled(Box)({
    marginTop: '50px',
    padding: '0px 2rem'
})

export const ManualsTextBox = styled(Box)({

})