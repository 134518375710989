import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardContent, Typography, Button, TextField, Select, MenuItem, CardActions, Box } from '@mui/material';
import { listVCEvents, listVCResources, deleteVCEvent, deleteVCResource } from '../../utils/api/vc';
import { styled } from '@mui/material/styles';
import Footer from "../../components/Footer.jsx";
import TopBar from "../../components/TopBar.jsx";
import { VCBackground } from "../../components/styles/virtualClassroom/VirtualClassroom.styled";
import Pagination from '@mui/material/Pagination';
import {useNavigate} from "react-router-dom";


const StyledCard = styled(Card)({
    margin: '20px',
    padding: '5px',
    width: '300px',
    height: '200px',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

const FilterContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    gap: '20px',
});

const VCListEventsContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '50px 250px 0px 200px',
});

const FilteredItemsContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
});

const StyledSelect = styled(Select)({
    width: '150px',
    '& .MuiSvgIcon-root': {
        color: 'white',
    },
})

const StyledCardActions = styled(CardActions)({
    justifyContent: 'space-between',
})

const StyledButton = styled(Button)({
    width: '100px',
})

const StyledCardContent = styled(CardContent)({
    padding: '10px',
})

const StyledPagination = styled(Pagination)({
    marginBottom: '20px',
})

export default function VCEventResourceList() {
    const [items, setItems] = useState([]);
    const [filter, setFilter] = useState('all');
    const [searchTerm, setSearchTerm] = useState('');
    const { user } = useSelector((state) => state.auth);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const navigate = useNavigate();

    useEffect(() => {
        fetchItems();
    }, [filter]);

    const fetchItems = async () => {
        try {
            const events = await listVCEvents();
            const resources = await listVCResources();
            let combinedItems = [...events.vcEvents, ...resources.vcResources];

            if (filter === 'event') {
                combinedItems = events.vcEvents.filter((event) => event.approved === true);
            } else if (filter === 'resource') {
                combinedItems = resources.vcResources.filter((resource) => resource.approved === true);
            }

            combinedItems
                .sort((a, b) => new Date(b.date) - new Date(a.date))
            console.log(combinedItems);
            setItems(combinedItems.filter((item) => item.approved === true));
        } catch (error) {
            console.error('Error fetching items:', error);
        }
    };

    const handleEdit = (id, type) => {
        navigate(`/interact/watch-and-engage/edit-event/${id}`)
    }

    const handleDelete = async (id, type) => {
        try {
            if (type === 'event') {
                await deleteVCEvent(id);
            } else {
                await deleteVCResource(id);
            }
            fetchItems();
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };

    const filteredItems = items.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <>
            <TopBar isGame={true} />
            <VCBackground>
                <VCListEventsContainer>
                    <FilterContainer>
                        <TextField
                            label="Search"
                            variant="outlined"
                            size="small"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <StyledSelect
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                            size="small"
                        >
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="event">Events</MenuItem>
                            <MenuItem value="resource">Resources</MenuItem>
                        </StyledSelect>
                    </FilterContainer>
                    <FilteredItemsContainer>
                        {currentItems.map((item) => (
                            <StyledCard key={item._id}>
                                <StyledCardContent>
                                    <Typography variant="h5" sx={{ fontSize: '18px' }}>{item.name}</Typography>
                                    <Typography color="textSecondary">{new Date(item.date).toLocaleDateString()}</Typography>
                                    <Typography variant="body2">{item.description}</Typography>
                                </StyledCardContent>
                                {item.ownerId === user._id && (
                                    <StyledCardActions>
                                        <StyledButton variant="contained" size="small" color="primary" onClick={() => handleEdit(item._id)}>Edit</StyledButton>
                                        <StyledButton variant="contained" size="small" color="secondary" onClick={() => handleDelete(item._id, item.eventType ? 'event' : 'resource')}>
                                            Delete
                                        </StyledButton>
                                    </StyledCardActions>
                                )}
                            </StyledCard>
                        ))}
                    </FilteredItemsContainer>
                    <StyledPagination
                        count={Math.ceil(filteredItems.length / itemsPerPage)}
                        page={currentPage}
                        onChange={paginate}
                        color="primary"
                        shape="rounded"
                        size="large"
                        showFirstButton
                        showLastButton
                    />
                </VCListEventsContainer>
            </VCBackground>
            <Footer isGame={true} />
        </>
    );
}