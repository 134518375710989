import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import { useNavigate } from 'react-router-dom';
import {listVCEvents} from "../utils/api/vc"; // Assuming you're using react-router
import './Calendar.css'; // Make sure the path is correct
import dayGridPlugin from '@fullcalendar/daygrid';
import styled from 'styled-components';
import theme from "./styles/theme";


const StyledCalendar = styled('div')({
    '& .fc-event': {
        backgroundColor: '#3f51b5',
    },
    '& .fc-col-header-cell': {
        width: '100px' // Adjust as needed
    },
    '& .fc-event-past': {
        backgroundColor: theme.palette.secondary.main, // Color for past events
    },
    '& .fc-event-upcoming': {
        backgroundColor: theme.palette.primary.main, // Color for past events
    },
    '& .fc-view-harness': {
        height: '300px !important',
    },
    '& .fc-event-past:hover': {
        backgroundColor: theme.palette.secondary.light, // Color for past events
        color: 'black',
    },
    '& .fc-event-upcoming:hover': {
        backgroundColor: theme.palette.primary.light, // Color for past events
        color: 'black',
    },
    width: '1000px',
    height: '300px',
    '@media (max-width: 600px)': {
        display: 'none',
        height: '0px',
        width: '100% !important',
    }
});

const MyCalendar = () => {
    const [events, setEvents] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Replace with your API call to fetch events
        const fetchEvents = async () => {
            try {
                const { vcEvents } = await listVCEvents();
                const filteredEvents = vcEvents.filter(event => event.approved === true);
                const today = new Date();

                const data = filteredEvents.map((event) => {
                    const eventDate = new Date(event.date);
                    let className = 'fc-event-upcoming';
                    if (eventDate < today) {
                        className = 'fc-event-past';
                    }

                    return {
                        id: event._id,
                        title: event.name,
                        date: event.date,
                        classNames: [className]
                    };
                });

                setEvents(data);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, []);

    const handleEventClick = (clickInfo) => {
        navigate(`/interact/watch-and-engage/view-event/${clickInfo.event.id}`);
    };

    return (
        <StyledCalendar>
            <FullCalendar
                plugins={[dayGridPlugin, dayGridPlugin]}
                initialView="dayGridWeek"
                weekends={true}
                events={events}
                eventClick={handleEventClick}
                dayHeaderFormat={{ weekday: 'short', day: 'numeric', month: 'numeric' }} // Adjusted format
            />
        </StyledCalendar>
    );
};

export default MyCalendar;
