// vcApi.js

const BASE_URL = 'http://37.9.171.147:5000/vc';

// VCEvent API Calls
export const createVCEvent = async (vcEvent) => {
    try {
        const response = await fetch(`${BASE_URL}/create-vc-event`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(vcEvent)
        });

        if (!response.ok) {
            throw new Error('Creating VCEvent failed');
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const listVCEvents = async () => {
    try {
        const response = await fetch(`${BASE_URL}/list-vc-events`, {
            method: 'GET'
        });

        if (!response.ok) {
            throw new Error('Listing VCEvents failed');
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const updateVCEvent = async (eventId, vcEvent) => {
    try {
        const response = await fetch(`${BASE_URL}/update-vc-event/${eventId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(vcEvent)
        });

        if (!response.ok) {
            throw new Error('Updating VCEvent failed');
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const deleteVCEvent = async (eventId) => {
    try {
        const response = await fetch(`${BASE_URL}/delete-vc-event/${eventId}`, {
            method: 'DELETE'
        });

        if (!response.ok) {
            throw new Error('Deleting VCEvent failed');
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const approveVCEvent = async (eventId) => {
    try {
        const response = await fetch(`${BASE_URL}/approve-vc-event/${eventId}`, {
            method: 'PUT'
        });

        if (!response.ok) {
            throw new Error('Approving VCEvent failed');
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
};

// VCResource API Calls
export const createVCResource = async (vcResource) => {
    try {
        const response = await fetch(`${BASE_URL}/create-vc-resource`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(vcResource)
        });
        console.log(response, vcResource)

        if (!response.ok) {
            throw new Error('Creating VCResource failed');
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const listVCResources = async () => {
    try {
        const response = await fetch(`${BASE_URL}/list-vc-resources`, {
            method: 'GET'
        });

        if (!response.ok) {
            throw new Error('Listing VCResources failed');
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const updateVCResource = async (resourceId, vcResource) => {
    try {
        const response = await fetch(`${BASE_URL}/update-vc-resource/${resourceId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(vcResource)
        });

        if (!response.ok) {
            throw new Error('Updating VCResource failed');
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const deleteVCResource = async (resourceId) => {
    try {
        const response = await fetch(`${BASE_URL}/delete-vc-resource/${resourceId}`, {
            method: 'DELETE'
        });

        if (!response.ok) {
            throw new Error('Deleting VCResource failed');
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const approveVCResource = async (resourceId) => {
    try {
        const response = await fetch(`${BASE_URL}/approve-vc-resource/${resourceId}`, {
            method: 'PUT'
        });

        if (!response.ok) {
            throw new Error('Approving VCResource failed');
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
};


export const getVCResourceById = async (resourceId) => {
    try {
        const response = await fetch(`${BASE_URL}/get-vc-resource-by-id/${resourceId}`, {
            method: 'GET'
        });

        if (!response.ok) {
            throw new Error('Getting VCResource by ID failed');
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const getVCEventById = async (eventId) => {
    try {
        const response = await fetch(`${BASE_URL}/get-vc-event-by-id/${eventId}`, {
            method: 'GET'
        });

        if (!response.ok) {
            throw new Error('Getting VCEvent by ID failed');
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const joinVCEvent = async (eventId, userId) => {
    try {
        const response = await fetch(`${BASE_URL}/join-vc-event/${eventId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId })
        });

        if (!response.ok) {
            throw new Error('Joining VCEvent failed');
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
}

export const leaveVCEvent = async (eventId, userId) => {
    try {
        const response = await fetch(`${BASE_URL}/leave-vc-event/${eventId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId })
        });

        if (!response.ok) {
            throw new Error('Leaving VCEvent failed');
        }

        return await response.json();
    } catch (error) {
        throw error;
    }
}

export const addMaterial = async (material) => {
    try {
        const response = await fetch(`${BASE_URL}/materials/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(material)
        });
        if (!response.ok) {
            throw new Error('Adding material failed');
        }
        return await response.json();
    } catch (error) {
        throw error;
    }
};

// Remove an existing material
export const removeMaterial = async (id) => {
    try {
        const response = await fetch(`${BASE_URL}/materials/remove/${id}`, {
            method: 'DELETE'
        });
        if (!response.ok) {
            throw new Error('Removing material failed');
        }
        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const listMaterials = async () => {
    try {
        const response = await fetch(`${BASE_URL}/list-materials`, {
            method: 'GET'
        });
        if (!response.ok) {
            throw new Error('Listing materials failed');
        }
        return await response.json();
    } catch (error) {
        throw error;
    }
}

export const getMaterialById = async (id) => {
    try {
        const response = await fetch(`${BASE_URL}/get-material-by-id/${id}`, {
            method: 'GET'
        });
        if (!response.ok) {
            throw new Error('Getting material by ID failed');
        }
        return await response.json();
    } catch (error) {
        throw error;
    }
}