import { styled } from '@mui/material/styles';
import {Button} from "@mui/material";

export const ButtonStyled = styled(Button)(({ theme }) => ({
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '1.1rem',
    padding: '10px 20px',
    borderRadius: '10px',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
    },
    '&:disabled': {
        backgroundColor: theme.palette.primary.light,
    },
    [theme.breakpoints.down('md')]: {
        fontSize: '13px',
        height: '42px',
        padding: '8px',
        width: '130px'
    }
}));