import { createSlice } from '@reduxjs/toolkit'

export const forumSlice = createSlice({
    name: 'forum',
    initialState: {
        forumSelectedThread: null,
        threadsList: [],
    },
    reducers: {
        selectThread: (state, action) => {
            state.forumSelectedThread = action.payload
        },
        addThreadComment: (state, action) => {
            const newComment = action.payload;
            state.forumSelectedThread = {
                ...state.forumSelectedThread,
                comments: state.forumSelectedThread.comments.concat(newComment),
            };

        },
        setForumThreadsList: (state, action) => {
            state.threadsList = action.payload
        },
        addForumThread: (state, action) => {
            const newThread = action.payload;
            state.threadsList = state.threadsList.concat(newThread);
        },
    },
})

export const {
    selectThread,
    addThreadComment,
    setForumThreadsList,
    addForumThread
} = forumSlice.actions
export const getSelectedThread = (state) => state.forumSelectedThread
export const getThreadsList = (state) => state.threadsList
export default forumSlice.reducer
