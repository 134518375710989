import React, { useEffect, useState } from 'react';
import TopBar from "../components/TopBar.jsx";
import UserProfile from "../components/UserProfile.jsx";
import { getUsers } from "../utils/api/auth";
import { v4 as uuidv4 } from 'uuid';
import { Box, Pagination } from '@mui/material';
import { styled } from "@mui/system";
import Footer from "../components/Footer.jsx";

const UserProfileContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '2rem',
});

export default function PublicProfile() {
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(1);
    const itemsPerPage = 5;

    useEffect(() => {
        const fetchUsers = async () => {
            const { users } = await getUsers();
            // Sorting users by createdAt date
            users.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            console.log(users);
            setUsers(users);
        };
        fetchUsers();
    }, []);

    const handlePageChange = (event, value) => {
        setPage(value);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <>
            <TopBar />
            <UserProfileContainer>
                <Box sx={{ margin: '0 auto' }}>
                    {
                        users.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((user) => {
                            return <UserProfile key={uuidv4()} user={user} />;
                        })
                    }
                </Box>
                <Pagination sx={{ marginTop: '2rem' }} count={Math.ceil(users.length / itemsPerPage)} page={page} onChange={handlePageChange} />
            </UserProfileContainer>
            <Footer />
        </>
    );
}
