import React from 'react';
import {Alert, Box, Button, Typography} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { QuestionWrapper, RootContainer } from "../styles/simulationGame/MissionEndSummary.styled";
import { useTranslation } from "react-i18next";

function MissionEndSummary() {
    const dispatch = useDispatch();
    const { selectedMission, badgesEarned, questionAnswers } = useSelector(state => state.game);
    const { t } = useTranslation('ns1');
    const [toggleQuestions, setToggleQuestions] = React.useState(false);

    const getNumberedItem = (item, index) => `${index + 1}. ${item}`;

    return (
            toggleQuestions ? (
                <RootContainer>
                    <Typography variant="h6" textAlign="center" fontWeight={800}>{t('questions_summary_title')}</Typography>
                    {questionAnswers.map((questionAnswer, questionIndex) => (
                        <React.Fragment key={questionIndex}>
                            <Alert sx={{ width: '100%', my: 2 }} severity="info">
                                <Typography sx={{ fontWeight: 'bold' }}>
                                    {t('question')} {getNumberedItem(questionAnswer.question, questionIndex)}
                                </Typography>
                            </Alert>
                            <QuestionWrapper>
                                <Box>
                                    <Typography variant="h6" textAlign="center" sx={{ color: '#FFF', fontWeight: 'bold' }}>
                                        {t('questions_summary_your_answers')}
                                    </Typography>
                                    {questionAnswer.answers.map((answer, answerIndex) => (
                                        <Typography variant="h6" key={answerIndex + answer} textAlign="center">
                                            {getNumberedItem(answer, answerIndex)}
                                        </Typography>
                                    ))}
                                </Box>
                                <Box>
                                    <Typography variant="h6" textAlign="center" sx={{ color: '#FFF', fontWeight: 'bold' }}>
                                        {t('questions_summary_correct_answers')}
                                    </Typography>
                                    { selectedMission?.questions[questionIndex]?.questionType === 'ordered_list' ? (
                                        selectedMission?.questions[questionIndex]?.correctAnswers.map(({ value }, answerIndex) => (
                                            <Typography variant="h6" key={answerIndex} textAlign="center">
                                                {getNumberedItem(value, answerIndex)}
                                            </Typography>
                                        ))
                                    ) : (
                                        selectedMission?.questions[questionIndex]?.correctAnswers.map((correctAnswer, answerIndex) => (
                                            <Typography variant="h6" key={answerIndex} textAlign="center">
                                                {getNumberedItem(correctAnswer, answerIndex)}
                                            </Typography>
                                        ))
                                    )
                                    }
                                </Box>
                            </QuestionWrapper>
                        </React.Fragment>
                    ))}
                </RootContainer>
            ) : (
                    <RootContainer>
                        <Button variant="outlined" color="secondary" onClick={() => setToggleQuestions(true)}>
                            <Typography sx={{ color: 'white' }}>{t('questions_summary_button')}</Typography>
                        </Button>
                    </RootContainer>
                )
    );
}

export default MissionEndSummary;
