import React from 'react'
import {Box, Typography} from "@mui/material";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import {PlayButton} from "../styles/simulationGame/SimulationGamePage.styled";
import {
    DescriptionBox,
    LeftContainer,
    MissionLobbyImage,
    RightContainer
} from "../styles/simulationGame/MissionLobby.styled";
import {useNavigate} from "react-router-dom";

const MissionLobby = ({ mission }) => {
    const navigate = useNavigate();
    const handlePlayClick = () => {
        navigate('/simulation-game/play')
    }

    if(!mission) return null
    return (
        <>
            <LeftContainer>
                <MissionLobbyImage src={`http://37.9.171.147:5000/public/${mission.imagePath}`} alt={mission.title}/>
            </LeftContainer>
            <RightContainer>
                <Box>
                    <Typography variant="h6" sx={{ color: '#FC6A03', textTransform: 'uppercase' }}>Mission {mission.sequentialId}</Typography>
                    <Typography variant="h4">{mission.title}</Typography>
                </Box>
                <DescriptionBox>
                    <Typography variant="body1">{mission.description}</Typography>
                    <PlayButton variant="contained" color="secondary" startIcon={<SportsEsportsIcon/>} onClick={() => handlePlayClick()}>Play</PlayButton>
                </DescriptionBox>
            </RightContainer>
        </>
    )
}

export default MissionLobby