import React, {useEffect} from 'react'
import TopBar from "../components/TopBar.jsx";
import Footer from "../components/Footer.jsx";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {Box, Typography} from "@mui/material";
import {getUserMissions} from "../utils/api/mission";
import {useSelector} from "react-redux";
import Missions from "../components/game/Missions.jsx";
import {AddMissionForm} from "./SimulationGameBuilder.jsx";
import {useNavigate} from "react-router-dom";

const EditMissionWrapper = styled(Box)({
    marginTop: '50px',
})

const EditMissionsTitle = styled(Typography)({

})

export default function SimulationGameMyMissions() {
    const { t } = useTranslation('ns1')
    const { selectedMission } = useSelector((state) => state.game)
    const { isAuthenticated } = useSelector((state) => state.auth)
    const navigate = useNavigate();

    useEffect(() => {
        if(!isAuthenticated){
            return navigate('/login')
        }
    }, []);

    return (
        <>
            <TopBar isGame={true}/>
            <EditMissionWrapper>
                <EditMissionsTitle variant="h5">{t('edit_mission')}</EditMissionsTitle>
                {isAuthenticated && <AddMissionForm editMission={selectedMission} isEditMode={true}/>}
            </EditMissionWrapper>
            <Footer isGame={true}/>
        </>
    )
}