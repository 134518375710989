import React from 'react'
import {useTranslation} from "react-i18next";
import {
    FooterFlexBox,
    FooterFlexContainer,
    FooterLogo,
    FooterRoot,
    FooterText,
    FooterTextHeading,
    FooterBottom,
    FooterTop, FooterTextTop,
} from "./styles/Footer";
import Box from "@mui/material/Box";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import {Link} from "react-router-dom";
import {Divider} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function FooterMobile() {
    const [t, i18n] = useTranslation('ns1');

    return (
        <>
            <FooterTop>
                <FooterLogo src="/public/assets/eu_flag.png" alt="eu_logo" />
                <FooterFlexContainer>
                    <FooterTextTop>{t('footer_top_text1')}</FooterTextTop>
                    <FooterTextTop>{t('footer_top_text2')}</FooterTextTop>
                    <FooterTextTop>{t('footer_top_text3')}</FooterTextTop>
                </FooterFlexContainer>
            </FooterTop>
            <FooterRoot sx={{ height: '100%', paddingBottom: '1rem' }}>
                <FooterFlexBox flexDirection="column">
                    <FooterFlexBox sx={{ justifyContent: 'space-around' }}>
                        <FooterLogo src="/public/assets/logo.png" alt="logo" />
                        <FooterText sx={{ marginBottom: '14px', width: '55%', textAlign: 'right', mt: 1 }}>{t('footer_left_text')}</FooterText>
                    </FooterFlexBox>
                    <FooterFlexBox sx={{ justifyContent: 'space-around', alignItems: 'center', mb: 1 }}>
                        <FacebookIcon onClick={() => window.open('https://www.facebook.com/DiGiYouthProject', '_blank')} sx={{ marginRight: '10px'}} />
                        <InstagramIcon onClick={() => window.open('https://www.instagram.com/digiyouth_com/', '_blank')} />
                        <FooterText sx={{ fontSize: '12px' }}>{t('footer_bottom_left')}</FooterText>
                    </FooterFlexBox>
                </FooterFlexBox>
            </FooterRoot>
            <Divider/>
            <FooterBottom sx={{ flexDirection: 'row', p: 0, justifyContent: 'space-around' }}>
                <FooterText sx={{ textDecoration: 'underline' }}>
                    <Link to="/info/privacy-policy">{t('privacy_policy')}</Link>
                </FooterText>
                <FooterText sx={{ textDecoration: 'underline' }}>
                   <Link to="/info/terms-and-conditions">{t('terms_and_conditions')}</Link>
               </FooterText>
            </FooterBottom>
        </>
    )
}