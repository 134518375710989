import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { getVCResourceById } from "../../utils/api/vc";
import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import styled from 'styled-components';
import TopBar from "../../components/TopBar.jsx";
import Footer from "../../components/Footer.jsx";
import FileIcon from '@mui/icons-material/InsertDriveFileOutlined'; // Import an icon for file

const ResourceContainer = styled(Card)`
    margin: 20px;
    width: 450px;
`;

const ResourceTitle = styled(Typography)`
    margin-bottom: 20px;
`;

export const VCViewResource = () => {
    const { id } = useParams();
    const [vcResource, setVCResource] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { vcResource } = await getVCResourceById(id);
                setVCResource(vcResource);
            } catch (error) {
                console.error('Error fetching resource:', error);
            }
        };
        fetchData();
    }, [id]);

    return (
        <div>
            <TopBar isGame={true}/>
            <Box display="flex" flexDirection="column" alignItems="center">
                {vcResource && vcResource.resources.map((resource, index) => (
                    <ResourceContainer key={index}>
                        <CardActionArea href={resource.url} target="_blank">
                            <CardContent>
                                <FileIcon />
                                <ResourceTitle variant="h5">{resource.name}</ResourceTitle>
                                <Typography variant="body2">{resource.description}</Typography>
                            </CardContent>
                        </CardActionArea>
                    </ResourceContainer>
                ))}
            </Box>
            <Footer isGame={true}/>
        </div>
    );
};

export default VCViewResource;
