import { styled, keyframes } from '@mui/material/styles';
import {Box} from "@mui/material";

export const CarouselRoot = styled(Box)({
    marginTop: '50px',
});

export const SlidesContainer = styled(Box)({

});

export const Slide = styled('img')({

});

export const SliderContent = styled('div')(({ theme }) => ({
    position: 'absolute',
    left: '79%',
    top: '35%',
    height: '180px',
    width: '42%',
    transform: 'translate(-50%, -50%)',
    padding: '20px 40px',
    background: 'rgba(0, 0, 0, 0.6)',
    color: theme.palette.common.white,
}));

export const SliderContentMobile = styled(Box)(({ theme }) => ({
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: '1rem',
    height: '190px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
}))

const progressAnimation = keyframes`
  0% { width: 100%; }
  90% { width: 0; }
  100% { width: 0; }
`;

export const ProgressBarStyled = styled('div')(({ theme, progress, seconds }) => ({
    backgroundColor: theme.palette.primary.main,
    height: '7px',
    // transition: 'all 0.16s'
    transition: 'all 0.16s'
}));
