import React, {useState} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {Alert, Box, Button, MenuItem, TextField} from '@mui/material';
import { countries } from '../utils/countriesList';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {register} from "../utils/api/auth";
import {addResource} from "../utils/api/resources";

const validationSchema = Yup.object().shape({
    resourceTitle: Yup.string().required('Title is required'),
    resourceDescription: Yup.string().required('Description is required'),
    resourceLink: Yup.string().url('Invalid URL').required('Link is required'),
    resourceCountry: Yup.string().required('Country is required'),
    resourceCategory: Yup.string().required('Category is required'),
});

const FormContainer = styled.div`
  max-width: 700px;
  margin: 40px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  //margin-bottom: 20px;
  gap: 3px;
  margin-bottom: 3px;
`;

const StyledField = styled(TextField)`
  //margin-top: 8px;
`;

const StyledSelect = styled(TextField)`
  margin-top: 8px;
`;

const StyledButton = styled(Button)`
  margin-top: 20px !important;
  align-content: flex-end;
`;

const initialValues = {
    resourceTitle: '',
    resourceDescription: '',
    resourceLink: '',
    resourceType: '',
    resourceCountry: 'Bulgaria',
    resourceCategory: '',
};

const categories = [
    { label: 'Digital youth work', value: 'Digital youth work' },
    { label: 'Media in youth work', value: 'Media in youth work' },
    { label: 'Best practice', value: 'Best practice' },
];

const type = [
    { label: 'Tools', value: 'Tools' },
    { label: 'Databases/Online libraries', value: 'Databases/Online libraries' },
    { label: 'Learning opportunities', value: 'Learning opportunities' },
    { label: 'Policies/Publications by public authorities', value: 'Policies/Publications by public authorities' },
    { label: 'Policy recommendations', value: 'Policy recommendations' },
    { label: 'Other', value: 'Other' },
]

const AddResourceForm = () => {
    const [t, i18n] = useTranslation('ns1');
    const [status, setStatus] = useState('')

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            console.log(values);
            const res = await addResource(
                values.resourceTitle,
                values.resourceDescription,
                values.resourceLink,
                values.resourceCountry,
                values.resourceCategory,
                values.resourceType,
                { emptyMapFeature: true }
            )
            if (res.success) {
                setStatus(t('resource_added_pending_review'));
            } else {
                setStatus(t('resource_add_error'));
            }
        },
    });

    return (
        <FormContainer>
            <Title>{t('add_resource_title')}</Title>
            {status && <Alert severity="info" sx={{ pb: 1 }}>{status}</Alert>}
            <form onSubmit={formik.handleSubmit}>
                <FieldWrapper>
                    <label>{t('resource_title')}</label>
                    <StyledField
                        name="resourceTitle"
                        value={formik.values.resourceTitle}
                        onChange={formik.handleChange}
                        error={formik.touched.resourceTitle && Boolean(formik.errors.resourceTitle)}
                        helperText={formik.touched.resourceTitle && formik.errors.resourceTitle}
                        fullWidth
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <label>{t('resource_description')}</label>
                    <StyledField
                        name="resourceDescription"
                        value={formik.values.resourceDescription}
                        onChange={formik.handleChange}
                        error={formik.touched.resourceDescription && Boolean(formik.errors.resourceDescription)}
                        helperText={formik.touched.resourceDescription && formik.errors.resourceDescription}
                        fullWidth
                        multiline
                        rows={4}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <label>{t('resource_link')}</label>
                    <StyledField
                        name="resourceLink"
                        value={formik.values.resourceLink}
                        onChange={formik.handleChange}
                        error={formik.touched.resourceLink && Boolean(formik.errors.resourceLink)}
                        helperText={formik.touched.resourceLink && formik.errors.resourceLink}
                        fullWidth
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <label>{t('resource_country')}</label>
                    <StyledSelect
                        name="resourceCountry"
                        select
                        value={formik.values.resourceCountry} // Update the selected value from formik state
                        onChange={formik.handleChange}
                        error={formik.touched.resourceCountry && Boolean(formik.errors.resourceCountry)}
                        helperText={formik.touched.resourceCountry && formik.errors.resourceCountry}
                        fullWidth
                        variant="outlined"
                    >
                        {countries.map((option) => (
                            <MenuItem key={uuidv4()} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                </FieldWrapper>
                <FieldWrapper>
                    <label>{t('resource_category')}</label>
                    <StyledSelect
                        name="resourceCategory"
                        select
                        value={formik.values.resourceCategory}
                        onChange={formik.handleChange}
                        error={formik.touched.resourceCategory && Boolean(formik.errors.resourceCategory)}
                        helperText={formik.touched.resourceCategory && formik.errors.resourceCategory}
                        fullWidth
                        variant="outlined"
                    >
                        {categories.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                </FieldWrapper>
                <FieldWrapper>
                    <label>{t('resource_type')}</label>
                    <StyledSelect
                        name="resourceType"
                        select
                        value={formik.values.resourceType}
                        onChange={formik.handleChange}
                        error={formik.touched.resourceType && Boolean(formik.errors.resourceType)}
                        helperText={formik.touched.resourceType && formik.errors.resourceType}
                        fullWidth
                        variant="outlined"
                    >
                        {type.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                </FieldWrapper>
                <StyledButton type="submit" variant="contained" color="primary">
                    {t('add_button')}
                </StyledButton>
            </form>
        </FormContainer>
    );
};

export default AddResourceForm;
