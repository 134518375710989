import {styled} from "@mui/system";
import {Box} from "@mui/material";

export const FormRoot = styled(Box)({
    // width: '100%',
})

export const LeftBox = styled(Box)(({ theme }) =>({
    width: '100%',
    padding: '1rem',
    backgroundColor: theme.palette.common.white,
    transition: 'background-color 0.5s ease', // Add a smooth transition effect
    '&:hover': {
        backgroundColor: theme.palette.common.lightgray, // Change the background color on hover
    },
}));

export const RightBox = styled(LeftBox)(({ theme }) => ({
    padding: '2rem',
    [theme.breakpoints.down('md')]: {
        paddingTop: '0rem',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    transition: 'background-color 0.5s ease', // Add a smooth transition effect
    '&:hover': {
        backgroundColor: theme.palette.common.lightgray, // Change the background color on hover
    },
}));

export const MainWrapper = styled(Box)(({ theme }) => ({
    marginTop: '50px',
    display: 'flex',
    // padding: '2rem',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
    },
    color: theme.palette.common.lightblack
}))