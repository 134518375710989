import React from 'react';
import {
    TopBarRoot,
    TopBarMidWrapper,
    TopBarLeftWrapper,
    TopBarRightWrapper,
    TopBarContainer,
    MenuOption,
    TopBarLogo,
    LanguageSwitch
} from './styles/TopBar.styled';
import { bindMenu, bindHover } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import HoverMenu from "material-ui-popup-state/HoverMenu";
import logoImage from '../../public/assets/logo.png';
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { TextField } from "@mui/material";
import { v4 as uuidv4 } from 'uuid';
import {useSelector} from "react-redux";
import {useTheme} from "@mui/material/styles";
import BookIcon from "@mui/icons-material/Book";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import PolicyIcon from "@mui/icons-material/Policy";
import SettingsIcon from "@mui/icons-material/Settings";

const networkMenuItems = [
    { label: "why_to_join", path: '/network/why-to-join' },
    { label: "public_profile", path: '/network/public-profile' },
    { label: "forum", path: '/network/forum' }
];

const manualsMenuItems = [
    { label: "work_manual", path: '/manuals/work-manual' },
    { label: "media_manual", path: '/manuals/media-manual'},
];

const bestPracticesMenuItems = [
    { label: "interactive_map", path: '/best-practices/interactive-map' },
    { label: "list_of_resources", path: '/best-practices/list-of-resources' },
];

const mediaCornerMenuItems = [
    { label: "news", path: '/media-corner/news' },
    { label: "events", path: '/media-corner/events' },
];

const interactMenuItems = [
    { label: "explore", path: '/interact/explore' },
    { label: "watch_and_engage", path: '/interact/watch-and-engage' },
]

const toolsMenuItems = [
    { label: "framework_curriculum", path: '/tools/framework-curriculum', icon: <BookIcon /> },
    { label: "mentorship_programme", path: '/tools/mentorship-programme', icon: <LiveHelpIcon /> },
    { label: "policy_recommendations", path: '/tools/policy-recommendations', icon: <PolicyIcon /> },
    { label: "methodology", path: '/tools/methodology', icon: <SettingsIcon /> },
];

const languageOptions = [
    "en", "bg", "si", "gr", "tr"
];

const disabledLanguageOptions = [
    ""
];

export default function TopBarDesktop({ isGame }){
    const networkPopupState = usePopupState({ variant: 'popover', popupId: 'networkMenu' });
    const interactPopupState = usePopupState({ variant: 'popover', popupId: 'interactMenu' });
    const manualsPopupState = usePopupState({ variant: 'popover', popupId: 'manualsMenu' });
    const bestPracticesPopupState = usePopupState({ variant: 'popover', popupId: 'bestPracticesMenu' });
    const toolsPopupState = usePopupState({ variant: 'popover', popupId: 'toolsMenu' });

    const location = useLocation();
    const [t, i18n] = useTranslation('ns1');
    const { isAuthenticated } = useSelector((state) => state.auth)
    const { user } = useSelector((state) => state.auth)
    const theme = useTheme();

    const renderMenuOption = (item) => {
        return (
            <Link key={uuidv4()} to={item.path}>
                <MenuOption>
                    {t(item.label)}
                </MenuOption>
            </Link>
        )
    }

    return (
        <TopBarRoot
            sx={{
                backgroundColor: isGame ? 'black' : theme.palette.common.white,
                color: isGame ? theme.palette.common.white : theme.palette.common.black,
            }}
        >
        <TopBarContainer>
                <TopBarLeftWrapper>
                    <TopBarLogo src={logoImage} alt="logo" />
                </TopBarLeftWrapper>
                <TopBarMidWrapper>
                    <React.Fragment>
                        <Link to="/">
                            <MenuOption className={location.pathname === '/' ? 'active' : ''}>
                            {t('home')}
                            </MenuOption>
                        </Link>
                        <MenuOption {...bindHover(networkPopupState)} className={location.pathname.startsWith('/network') ? 'active' : ''}>
                            {t('network')}
                        </MenuOption>
                        <HoverMenu {...bindMenu(networkPopupState)}>
                            {networkMenuItems.map((item) => renderMenuOption(item))}
                        </HoverMenu>
                        <MenuOption {...bindHover(interactPopupState)} className={location.pathname.startsWith('/interact') ? 'active' : ''}>
                            {t('interact')}
                        </MenuOption>
                        <HoverMenu {...bindMenu(interactPopupState)}>
                            {interactMenuItems.map((item) => renderMenuOption(item))}
                        </HoverMenu>
                        <MenuOption {...bindHover(manualsPopupState)} className={location.pathname.startsWith('/manuals') ? 'active' : ''}>
                            {t('manuals')}
                        </MenuOption>
                        <HoverMenu {...bindMenu(manualsPopupState)}>
                            {manualsMenuItems.map((item) => renderMenuOption(item))}
                        </HoverMenu>
                        <MenuOption {...bindHover(toolsPopupState)} className={location.pathname.startsWith('/tools') ? 'active' : ''}>
                            {t('tools')}
                        </MenuOption>
                        <HoverMenu {...bindMenu(toolsPopupState)}>
                            {toolsMenuItems.map((item) => renderMenuOption(item))}
                        </HoverMenu>
                        <Link to='/simulation-game'>
                            <MenuOption className={location.pathname.startsWith('/simulation-game') ? 'active' : ''}>
                                {t('simulation_game')}
                            </MenuOption>
                        </Link>
                        <MenuOption {...bindHover(bestPracticesPopupState)} className={location.pathname.startsWith('/best-practices') ? 'active' : ''}>
                            {t('best_practices')}
                        </MenuOption>
                        <HoverMenu {...bindMenu(bestPracticesPopupState)}>
                            {bestPracticesMenuItems.map((item) => renderMenuOption(item))}
                        </HoverMenu>
                        <Link to='/media-corner/news'>
                            <MenuOption className={location.pathname.startsWith('/media-corner/news') ? 'active' : ''}>
                                {t('news')}
                            </MenuOption>
                        </Link>
                        {
                            user.role === 'admin' && (
                                <Link to='/admin'>
                                    <MenuOption className={location.pathname.startsWith('/admin') ? 'active' : ''}>
                                        {t('admin')}
                                    </MenuOption>
                                </Link>
                            )
                        }
                        {
                            isAuthenticated && (
                                <Link to='/profile'>
                                    <MenuOption className={location.pathname.startsWith('/profile') ? 'active' : ''}>
                                        {t('profile')}
                                    </MenuOption>
                                </Link>
                            )
                        }
                    </React.Fragment>
                </TopBarMidWrapper>
                <TopBarRightWrapper>
                    <LanguageSwitch
                        disableClearable
                        options={languageOptions}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        value={i18n.language}
                        getOptionDisabled={(option) => option === disabledLanguageOptions[0]}
                        onChange={(event, newValue) => i18n.changeLanguage(newValue)}
                        renderInput={(params) => <TextField sx={{ textTransform: 'uppercase !important' }}{...params} />}
                    />
                    {
                        isAuthenticated ? (
                            <Link to='/logout'>
                                <MenuOption className={location.pathname.startsWith('/logout') ? 'active' : ''}>
                                    {t('logout')}
                                </MenuOption>
                            </Link>
                        ) : (
                            <Link to='/login'>
                                <MenuOption className={location.pathname.startsWith('/login') ? 'active' : ''}>
                                    {t('login')}
                                </MenuOption>
                            </Link>
                        )
                    }
                </TopBarRightWrapper>
            </TopBarContainer>
        </TopBarRoot>
    )
}