import React, {useEffect, useState} from 'react'
import LeftNavigation from "../../components/admin/LeftNavigation.jsx";
import {AdminRoot} from "../../components/styles/AdminPanel.styled";
import {
    approveVCEvent,
    approveVCResource,
    deleteVCEvent,
    deleteVCResource,
    listVCEvents,
    listVCResources
} from "../../utils/api/vc";
import {HeadingText} from "../../components/styles/Typography.styled";
import Box from "@mui/material/Box";
import {Button, Typography} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import styled from "styled-components";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const StyledPaper = styled(Paper)`
    margin-top: 70px;
    margin-right: 400px;
    margin-left: 400px;
    margin-bottom: 20px;
    padding: 20px;
    width: 300px;
`;


export default function AdminPanelVC() {
    const [events, setEvents] = useState([]);
    const [resources, setResources] = useState([]);
    const [value, setValue] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            listVCEvents().then((res) => {
                setEvents(res.vcEvents);
            }).catch((err) => {
                console.log(err);
            })
            listVCResources().then((res) => {
                setResources(res.vcResources);
            }).catch((err) => {
                console.log(err);
            })
        }
        fetchData()
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleDeleteEvent = (id) => async () => {
        deleteVCEvent(id).then((res) => {
            alert("Event deleted successfully");
        }).catch((err) => {
            console.log(err);
        });
    }

    const handleApproveEvent = (id) => async () => {
        approveVCEvent(id).then((res) => {
            alert("Event approved successfully");
        }).catch((err) => {
            console.log(err);
        });
    }

    const handleDeleteResource = (id) => async () => {
        deleteVCResource(id).then((res) => {
            alert("Resource deleted successfully");
        }).catch((err) => {
            console.log(err);
        });
    }

    const handleApproveResource = (id) => async () => {
        approveVCResource(id).then((res) => {
            alert("Resource approved successfully");
        }).catch((err) => {
            console.log(err);
        });
    }

    const formatDate = (isoDateString) => {
        const date = new Date(isoDateString);
        return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    };

    return (
        <div>
            <LeftNavigation/>
            <AdminRoot>
                <HeadingText variant="h5">Virtual Classroom</HeadingText>
                <Box sx={{ width: '100%', mt: 1 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="List of events" {...a11yProps(0)} />
                            <Tab label="List of resources" {...a11yProps(1)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        {
                            events.map((event) => (
                                <StyledPaper>
                                    <h3>{event.name}</h3>
                                    <Typography>{event.description}</Typography>
                                    <Typography>{formatDate(event.date)}</Typography>
                                    <Typography>{event.eventBody}</Typography>
                                    {event.approved ? <Button variant="outlined" onClick={handleDeleteEvent(event._id)}>Delete</Button> : <Button variant="outlined" onClick={handleApproveEvent(event._id)}>Approve</Button>}
                                </StyledPaper>
                            ))
                        }
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        {
                            resources.map((resource) => (
                                <StyledPaper>
                                    <h3>{resource.name}</h3>
                                    <Typography>{resource.description}</Typography>
                                    <Typography>{resource.resourceLink}</Typography>
                                    {resource.approved ? <Button variant="outlined" onClick={handleDeleteResource(resource._id)}>Delete</Button> : <Button variant="outlined" onClick={handleApproveResource(resource._id)}>Approve</Button>}
                                </StyledPaper>
                            ))
                        }
                    </TabPanel>
                </Box>
            </AdminRoot>
        </div>
    )
}

