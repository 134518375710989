import React, {useEffect, useState} from 'react'
import TopBar from '../components/TopBar.jsx'
import {Box, Button, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import UserDetails from "../components/UserDetails.jsx";
import UpdateUserDetails from "../components/UpdateUserDetails.jsx";
import theme from "../components/styles/theme.js";

import styled from 'styled-components';

const UserProfileWrapper = styled(Box)({
    marginTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
    margin: 'auto',
    maxWidth: '380px',
});

export default function UserProfile() {
    const [t, i18n] = useTranslation('ns1');
    const navigate = useNavigate();
    const { isAuthenticated } = useSelector((state) => state.auth)
    const { user } = useSelector((state) => state.auth)
    const [page, setPage] = useState('userDetails')

    useEffect(() => {
        if(!isAuthenticated){
            navigate('/login')
        }
    }, [])

    return (
        <>
            <TopBar/>
            <UserProfileWrapper>
                <Typography sx={{ marginBottom: 2 }} variant="h4" component="h4">{t('user_profile_title')}</Typography>
                {page === 'userDetails' && <UserDetails user={user} />}
                {page === 'changeUserDetails' && <UpdateUserDetails user={user} setPage={setPage} />}
                <Button
                    variant="contained"
                    onClick={() => page === 'userDetails' ? setPage('changeUserDetails') : setPage('userDetails')}
                >
                    {page === 'userDetails' ? t('change_details_button') : t('go_back_button')}
                </Button>
            </UserProfileWrapper>
        </>
    )
}