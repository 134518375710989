import styled from 'styled-components';
import {Box, TextField} from '@mui/material';
import theme from "../theme";

export const ScenarioBuilderRoot = styled(Box)({
    marginTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    // height: '80vh',
    width: '100%',
    padding: '5rem 0',
    backgroundColor: theme.palette.common.lightblack,
    color: theme.palette.common.white,
})

export const ScenarioBuilderFormContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    margin: '1rem'
})

export const FormikStyledForm = styled('form')({
    width: '700px',
    marginLeft: '2rem',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginLeft: 0,
    },
})

export const ScenarioBuilderWrapper = styled(Box)({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
    },
})

export const BuilderGuideContainer = styled(Box)({
    width: '550px',
    [theme.breakpoints.down('md')]: {
        width: '100%',
        marginBottom: '2rem'
    },
})

export const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        color: theme.palette.common.white,
        '& fieldset': {
            borderColor: theme.palette.common.black,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.common.lightblack,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.common.white,
        },
    },
})