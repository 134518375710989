import React, {useEffect} from 'react';
import {Alert, AlertTitle, Button, MenuItem, TextField, Typography} from "@mui/material";
import { useState } from "react";
import { Box } from "@mui/material"
import TopBar from "../components/TopBar.jsx";
import Footer from "../components/Footer.jsx";
import {
    ScenarioBuilderFormContainer,
    ScenarioBuilderRoot,
    FormikStyledForm,
    ScenarioBuilderWrapper,
    BuilderGuideContainer,
    StyledTextField
} from "../components/styles/simulationGame/ScenarioBuilder.styled";
import {useTranslation} from "react-i18next";
import {FlexContainerColumn, FlexContainerRow} from "../components/styles/Containers.styled";
import * as yup from "yup";
import {useFormik} from "formik";
import {useDispatch, useSelector} from "react-redux";
import {addQuestion, setBuilderMission} from "../slices/scenarioBuilderSlice";
import {FillInTheBlanksQuestion, MultipleChoiceQuestion, FillInTheBlanksDropdownQuestion} from "./SimulationGameBuilderForms.jsx";
import {createQuestions} from "../utils/createQuestions";
import {validateMissionQuestions} from "../utils/game";
import {addMission, updateMission, uploadMissionImage} from "../utils/api/mission";
import OrderedListQuestionForm from "./SimulationBuilderForms/OrderedListQuestionForm.jsx";

function SimulationGameBuilder() {
    const { t } = useTranslation('ns1');

    return (
        <>
            <TopBar isGame={true} />
            <ScenarioBuilderRoot>
                <Typography variant="h5" textAlign="center" >{t('scenario_builder_title')}</Typography>
                <ScenarioBuilderFormContainer>
                    <Typography variant="h6" textAlign="center" gutterBottom>{t('scenario_builder_description')}</Typography>
                    <ScenarioBuilderWrapper>
                        <BuilderGuideContainer>
                            <Typography textAlign="center" variant="h6" fontWeight="bold" gutterBottom>{t('simulation_builder_helptext_caption')}</Typography>
                            <Typography textAlgin="justify" sx={{ mb: 4 }}>{t('simulation_builder_helptext1')}</Typography>
                            <Typography textAlgin="justify">{t('simulation_builder_helptext2')}</Typography>
                        </BuilderGuideContainer>
                        <AddMissionForm />
                    </ScenarioBuilderWrapper>
                </ScenarioBuilderFormContainer>
            </ScenarioBuilderRoot>
            <Footer isGame={true} />
        </>
    )
}

const questionTypeComponentMap = {
    multiple_choice: MultipleChoiceQuestion,
    fill_in_the_blanks: FillInTheBlanksQuestion,
    fill_in_the_blanks_dropdown: FillInTheBlanksDropdownQuestion,
    ordered_list: OrderedListQuestionForm,
};

function getQuestionComponent(type, props) {
    const Component = questionTypeComponentMap[type];
    return Component ? <Component {...props} /> : null;
}

export function AddMissionForm({ editMission, isEditMode }) {
    const { t } = useTranslation('ns1');
    const [status, setStatus] = useState('add_mission_details_form');
    const [questionNumber, setQuestionNumber] = useState(1);
    const [questions, setQuestions] = useState([{ question: '', type: ''}]);
    const { mission } = useSelector((state) => state.builder);
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState('');
    const missionImageRef = React.useRef(null);
    const [avatarImage, setAvatarImage] = useState(null);
    const { user } = useSelector((state) => state.auth);

    const createAddMissionSchema = (questions) => {
        return yup.object({
            mission_name: yup.string().required('Mission name is required'),
            mission_description: yup.string().required('Mission description is required'),
            ...questions.reduce((acc, _, index) => {
                acc[`question_${index}`] = yup.string().required(`Question ${index} is required`);
                acc[`question_type_${index}`] = yup.string().required(`Question type ${index} is required`);
                return acc;
            }, {}),
        });
    };

    useEffect(() => {
        if(isEditMode) {
            const mapValues = (sourceArray, targetObject) => {
                sourceArray.forEach((item, index) => {
                    targetObject[`question_${index}`] = item.question;
                    targetObject[`question_type_${index}`] = item.type;
                });
            };
            const questions = editMission.questions.map(question => ({ question: question.question, type: question.questionType }))
            console.log(questions)
            console.log(formik.initialValues)
            mapValues(questions, formik.initialValues);
            setQuestions(questions)
            setQuestionNumber(questions.length)
            console.log(formik.initialValues)
        }
    }, [editMission]);

    const renderTextField = (fieldName, formik, label) => {
        return (
            <StyledTextField
                id={fieldName}
                name={fieldName}
                label={label || 'Question name'}
                // key={fieldName}
                multiline={fieldName === 'mission_description'}
                sx={{ width: '50%' }}
                value={formik.values[fieldName]}
                onChange={formik.handleChange}
                error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
                helperText={formik.touched[fieldName] && formik.errors[fieldName]}
            />
        );
    };

    const renderSelectField = (fieldName, formik, label, options) => {
        return (
            <StyledTextField
                id={fieldName}
                name={fieldName}
                label={label}
                select
                value={formik.values[fieldName]}
                sx={{ width: '50%' }}
                onChange={(e) => formik.setFieldValue(fieldName, e.target.value)}
                error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
                helperText={formik.touched[fieldName] && formik.errors[fieldName]}
            >
                {options.map((option, index) => (
                    <MenuItem key={fieldName + index} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </StyledTextField>
        );
    };

    const formik = useFormik({
        initialValues: {
            mission_name: isEditMode ? editMission.title : '',
            mission_description: isEditMode ? editMission.description : '',
            ...questions.reduce((acc, _, index) => {
                acc[`question_${index}`] = '';
                acc[`question_type_${index}`] = '';
                return acc;
            }, {}),
        },
        validationSchema: createAddMissionSchema(questions),
        onSubmit: async (values) => {
            setStatus('question_details_form')
            renderQuestionDetailsForm(values)
            // ADD MISSION TO REDUX
            const emptyQuestionsMission = {
                sequentialId: 1, // TBR
                title: values.mission_name,
                description: values.mission_description,
                imagePath: avatarImage,
                authorId: user._id,
                authorName: user.username,
                questions: []
            }
            dispatch(setBuilderMission(emptyQuestionsMission));
            // ADD QUESTIONS TO MISSION IN REDUX
            const userEntries = Object.entries(values)
            const userQuestions = createQuestions(userEntries)
            userQuestions.forEach(question => dispatch(addQuestion(question)))
        },
    });
    console.log(formik.values)

    const renderAddQuestionFields = () => {
        const fields = [];
        for (let i = 0; i < questionNumber; i++) {
            fields.push(
                <FlexContainerRow key={i}>
                    {renderTextField(`question_${i}`, formik)}
                    {renderSelectField(`question_type_${i}`, formik, 'Question type', [
                        { label: 'Multiple Choice', value: 'multiple_choice' },
                        { label: 'Fill in the blanks', value: 'fill_in_the_blanks' },
                        { label: 'Dropdown in the blanks', value: 'fill_in_the_blanks_dropdown' },
                        { label: 'Ordered list', value: 'ordered_list' },
                        { label: undefined, value: undefined } // fix warning about uncontrolled input
                    ])}
                </FlexContainerRow>
            );
        }

        return fields;
    }

    const addQuestionHandler = () => {
        setQuestions([...questions, { question: '', type: '' }]);
        setQuestionNumber((prevState) => prevState + 1)
    };

    const renderQuestionDetailsForm = (values) => {
        const questions = [];
        for (let i = 0; i < questionNumber; i++) {
            questions.push({
                question: values[`question_${i}`],
                type: values[`question_type_${i}`],
            });
        }
        if(isEditMode) {
            return questions.map((question, index) => {
                return getQuestionComponent(question.type, { key: `question_${index}`, question, formik, edit: isEditMode, editInitialValues: editMission.questions[index]})
            });
        } else {
            return questions.map((question, index) => {
                return getQuestionComponent(question.type, { key: `question_${index}`, question, formik })
            });
        }
    };

    const handleSubmitMission = () => {
        console.log(mission)
        const hasIncompleteQuestions = validateMissionQuestions(mission)
        if (hasIncompleteQuestions) {
            setErrorMessage('There are one or more questions that aren\'t completed yet!')
        } else {
            if(isEditMode) {
                const updatedMission = { ...mission, imagePath: editMission.imagePath }; // so mission image wont be changed
                updateMission(editMission._id, updatedMission).then(res => {
                    if (res.success) {
                        setStatus('success')
                    } else {
                        setErrorMessage(res.message)
                    }
                })
            } else {
                addMission(mission).then(res => {
                    if (res.success) {
                        setStatus('success')
                    } else {
                        setErrorMessage(res.message)
                    }
                })
            }
        }
    }

    const handleUploadButtonClick = () => {
        missionImageRef.current.click()
    }

    const handleMissionImageUpload = (e) => {
        const image = e.target.files[0]
        console.log(image)
        uploadMissionImage(image).then(res => {
            const { imagePath } = res
            setAvatarImage(imagePath)
        }).catch(err => {
            alert('ERROR WHILE UPLOADING IMAGE!!!')
            alert(err)
        })
    }

    const renderFormContent = () => {
        switch (status) {
            case 'add_mission_details_form':
                return (
                    <FormikStyledForm onSubmit={formik.handleSubmit}>
                        <FlexContainerColumn>
                            {(avatarImage && !isEditMode) &&
                                <img src={`http://37.9.171.147:5000/public/${avatarImage}`} alt="mission" height="300px" />
                            }
                            { !isEditMode &&
                                <>
                                    <Button variant="contained" color="primary" onClick={() => handleUploadButtonClick()}>
                                        <Typography fontWeight="bold">Upload Mission Image</Typography>
                                    </Button>
                                    <Typography variant='caption' textAlign='justify'>{t('mission_image_upload_copyright_text')}</Typography>
                                </>
                            }
                            <TextField
                                sx={{ display: 'none' }}
                                type={"file"}
                                inputProps={{ accept: "image/png, image/jpeg" }}
                                inputRef={missionImageRef}
                                onChange={handleMissionImageUpload}
                            />
                            <FlexContainerRow sx={{ p: 0 }}>
                                {renderTextField('mission_name', formik, 'Mission name')}
                                {renderTextField('mission_description', formik, 'Mission description')}
                            </FlexContainerRow>
                        </FlexContainerColumn>
                        <Typography variant="h6" textAlign="center" >{t('mission_questions_list')}</Typography>

                        {renderAddQuestionFields()}
                        <FlexContainerRow>
                            <Button sx={{ mt: 1 }} color="primary" variant="outlined" fullWidth onClick={() => addQuestionHandler()}>
                                {t('add_question_type_button')}
                            </Button>
                            <Button sx={{ mt: 1 }} color="primary" variant="contained" fullWidth onClick={() => formik.submitForm()}>
                                {t('add_questions_button')}
                            </Button>
                        </FlexContainerRow>
                    </FormikStyledForm>
                );
            case 'question_details_form':
                return (
                    <Box>
                        <Box>
                            {renderQuestionDetailsForm(formik.values)}
                        </Box>
                        <Button sx={{ mt: 1 }} color="secondary" variant="contained" fullWidth onClick={() => handleSubmitMission()}>
                            Submit mission
                        </Button>
                        {
                            errorMessage && (
                                <Alert sx={{ mt: 2 }} severity="error">
                                    <AlertTitle sx={{ fontWeight: 'bold' }}>Error</AlertTitle>
                                    {errorMessage}
                                </Alert>
                            )
                        }
                    </Box>
                )
            case 'success':
                return (
                    <Alert sx={{ width: '600px', margin: '25px auto', height: '80px' }} severity="success">
                        { isEditMode ?
                            <>
                                <AlertTitle> Mission has been updated successfully!</AlertTitle>
                                It will be now reviewed by a moderator and later updated in the game.
                            </>
                            :
                            <>
                                <AlertTitle> Mission has been added successfully!</AlertTitle>
                                It will be now reviewed by a moderator and later added to the game.
                            </>
                        }
                    </Alert>
                );
            case 'error':
                return (
                    <Box sx={{ width: '75%', height: '80px' }}>
                        <Alert sx={{ margin: '25px auto' }} severity="error">
                            <AlertTitle sx={{ fontWeight: 'bold' }}> {t('register_error_title')} </AlertTitle>
                            {errorMessage}
                        </Alert>
                        <Button sx={{ mt: 1 }} color="secondary" variant="contained" fullWidth type="submit">
                            Go back
                        </Button>
                    </Box>
                );
            default:
                return null;
        }
    }

    return <>{renderFormContent()}</>;
}

export default SimulationGameBuilder;