import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
export const FooterRoot = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.common.lightblack,
    color: theme.palette.common.white,
    height: '300px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
        padding: '0rem',
    },
    padding: '2rem 4rem',
}));
export const FooterText = styled(Typography)({
    fontSize: '15px',
})

export const FooterTextTop = styled(FooterText)({
    fontSize: '13px',
})
export const FooterTextHeading = styled(FooterText)({
    fontSize: '16px',
    marginBottom: '1rem',
    fontWeight: 'bold'
})
export const FooterLogo = styled('img')({
    width: '100px',
    height: '100px',
    objectFit: 'contain',
})
export const FooterFlexBox = styled(Box)({
    display: 'flex',
})

export const FooterFlexContainer = styled(FooterFlexBox)(({ theme }) => ({
    flexDirection: 'column',
    paddingRight: '2rem',
    gap: '1.2px',
    [theme.breakpoints.down('md')]: {
        gap: '8px'
    },
    minWidth: 'fit-content'
}))

export const FooterBottom = styled(FooterRoot)({
      height: 'fit-content',
    padding: '1rem 4rem',
})

export const FooterTop = styled(FooterFlexBox)(({ theme }) => ({
    justifyContent: 'center',
    height: '120px',
    backgroundColor: theme.palette.common.lightgray,
    alignItems: 'center',
    gap: '1rem',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        height: '100%',
        padding: '0rem 1rem 1rem 1rem',
        textAlign: 'center',
        gap: '0px',
    }
}))