import React, {useEffect, useState} from 'react'
import TopBar from "../components/TopBar.jsx";
import {
    GameDashboardContainer, GameDescriptionWrapper, GameMissionsContainer,
    GameRootContainer, MainButtonsContainer,
    PlayButton, ScenarioBuilderButton,
    StatCard,
    StatCardsContainer
} from "../components/styles/simulationGame/SimulationGamePage.styled";
import {Box, Button, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Missions from "../components/game/Missions.jsx";
import Footer from "../components/Footer.jsx";
import {getUsers} from "../utils/api/auth";
import {useNavigate} from "react-router-dom";
import {getAllUsersStats} from "../utils/api/game";
import {getAllMissions} from "../utils/api/mission";

const missionsArray = [
    {
        sequentialId: 1,
        title: "MARIA AND STORYTELLING IN DIGITAL YOUTH WORK",
        description: "Maria is 17 years old and has approached you, a youth worker in a local youth center, with a proposal to cooperate on the organization of an online group, with which to meet and exchange with young people from around her country and the EU as a whole.",
        imagePath: 'public/assets/game/mission1.png',
        questions: [
            {
                id: 1,
                question: "What is the first thing you will do?",
                questionType: "multiple_choice",
                answers: [
                    'I will call somebody to check whats up',
                    'I will do literally absolutely nothing',
                    'I will do whatever it takes to make it happen',
                    'I will do whatever it takes to make it happen2',
                    'I will do whatever it takes to make it happen3',
                    'I will do whatever it takes to make it happen4',
                ],
                correctAnswers: [
                    'I will do whatever it takes to make it happen',
                    'I will do literally absolutely nothing',
                ]
            },
            {
                id: 2,
                question: "The sun is {0} and the moon is {1}. Today's weather is {2}. The weather tomorrow will be {3}. We are trying to find a {4} solution to the {5} problem.",
                questionType: "fill_in_the_blanks",
                correctAnswers: ['hot', 'cold', 'rainy', 'rainy', 'practical', 'real-life']
            },
        ]
    },
    {
        sequentialId: 2,
        title: "GEORGE AND SIMULATIONS AS DIGITAL LEARNING TOOLS",
        description: "George is a young immigrant who has been struggling quite a lot to develop the appropriate skills and find the best job for him. Now he is very happy with his career choice as a youth worker, helping other young people who are dealing with the same challenges.",
        imagePath: 'public/assets/game/mission2.png',
        questions: [
            {
                id: 1,
                question: "The sun is {0} and the moon is {1}.",
                questionType: "fill_in_the_blanks",
                correctAnswers: ['hot', 'cold']
            },
            {
                id: 2,
                question: "What is the second thing you will do?",
                questionType: "multiple_choice",
                answers: [
                    'I will ask',
                    'I will try',
                    'I will do',
                ],
                correctAnswers: [
                    'I will ask',
                    'I will do',
                ]
            }
        ]
    },
    {
        sequentialId: 3,
        title: "MARIA AND STORYTELLING IN DIGITAL YOUTH WORK",
        description: "Maria is 17 years old and has approached you, a youth worker in a local youth center, with a proposal to cooperate on the organization of an online group, with which to meet and exchange with young people from around her country and the EU as a whole.",
        imagePath: 'public/assets/game/mission2.png',
        questions: [
            {
                id: 1,
                question: "What is the first thing you will do?",
                questionType: "multiple_choice",
                answers: [
                    'I will ask',
                    'I will try',
                    'I will do',
                ],
                correctAnswers: [
                    'I will ask',
                    'I will try',
                ]
            },
            {
                id: 2,
                question: "What is the second thing you will do?",
                questionType: "multiple_choice",
                answers: [
                    'I will ask',
                    'I will try',
                    'I will do',
                ],
                correctAnswers: [
                    'I will ask',
                    'I will do',
                ]
            }
        ]
    }
]

export default function SimulationGame() {
    const { t } = useTranslation('ns1');
    const navigate = useNavigate()
    const [players, setPlayers] = useState(0)
    const [awards, setAwards] = useState(0)
    const [timePlayed, setTimePlayed] = useState(0)
    const [missions, setMissions] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            const { users } = await getUsers()
            const { totalStats } = await getAllUsersStats()
            setAwards(totalStats.badges)
            setTimePlayed(Math.round(totalStats.timePlayed))
            setPlayers(users.length)
        }
        const fetchMissions = async () => {
            const { missions } = await getAllMissions()
            // filter missions by missionApproved
            const approvedMissions = missions.filter(mission => mission.missionApproved)
            setMissions(approvedMissions)
        }
        fetchData()
        fetchMissions()
    }, [])

    const handleScenarioBuilderClick = () => {
        navigate('/simulation-game/builder')
    }

    const handlePlayClick = () => {
        navigate('/simulation-game/dashboard')
    }

    const handleMyMissionsClick = () => {
        navigate('/simulation-game/my-missions')
    }

    return (
        <>
            <TopBar isGame={true}/>
            <GameRootContainer>
                <GameDashboardContainer imgpath={'public/assets/bg/3.png'}>
                    <Typography variant="h5" component="h5" gutterBottom textAlign="center" fontWeight="900">
                        {t('simulation_game_dashboard_title')}
                    </Typography>
                    <GameDescriptionWrapper>
                        <Typography gutterBottom textAlign="center">
                            {t('simulation_game_dashboard_description')}
                        </Typography>
                        <Typography textAlign="center">
                            {t('simulation_game_dashboard_description2')}
                        </Typography>
                    </GameDescriptionWrapper>
                    <MainButtonsContainer>
                        <PlayButton color="secondary" variant="outlined" onClick={() => handleScenarioBuilderClick()}>
                            <Typography fontWeight="bold">{t('simulation_game_scenario_builder_text')}</Typography>
                        </PlayButton>
                        <PlayButton color="secondary" variant="contained" onClick={() => handlePlayClick()}>
                            <Typography fontWeight="bold">{t('simulation_game_dashboard_text')}</Typography>
                        </PlayButton>
                        <PlayButton color="secondary" variant="outlined" onClick={() => handleMyMissionsClick()}>
                            <Typography fontWeight="bold">{t('simulation_game_my_missions')}</Typography>
                        </PlayButton>
                    </MainButtonsContainer>
                    <StatCardsContainer>
                        <StatCard backgroundColor="#236ef5" elevation={5}>
                            <SportsEsportsIcon sx={{ width: '90px', height: '90px'}}/>
                            <Typography variant="h5" component="h5" gutterBottom textAlign="center" fontWeight="bold">
                                {t('simulation_game_players')}
                            </Typography>
                            <Typography fontWeight="bold" variant="h4" gutterBottom>{players}</Typography>
                        </StatCard>
                        <StatCard backgroundColor="#FC6A03" elevation={5}>
                            <MilitaryTechIcon sx={{ width: '90px', height: '90px'}}/>
                            <Typography variant="h5" component="h5" gutterBottom textAlign="center" fontWeight="bold">
                                {t('simulation_game_awards')}
                            </Typography>
                            <Typography fontWeight="bold" variant="h4" gutterBottom>{awards}</Typography>
                        </StatCard>
                        <StatCard backgroundColor="#00d5c0" elevation={5}>
                            <AccessTimeIcon sx={{ width: '90px', height: '90px'}}/>
                            <Typography variant="h5" component="h5" gutterBottom textAlign="center" fontWeight="bold">
                                {t('simulation_game_time_played')}
                            </Typography>
                            <Typography fontWeight="bold" variant="h4" gutterBottom>{timePlayed}</Typography>
                        </StatCard>
                    </StatCardsContainer>
                </GameDashboardContainer>
                <GameMissionsContainer>
                    <Typography variant="h4" sx={{ color: 'white', margin: '2rem', textTransform: 'uppercase', fontWeight: 'bold' }} component="h5" gutterBottom textAlign="center">
                        {t('simulation_game_missions_title')}
                    </Typography>
                    <Missions missions={missions} componentType='approved'/>
                </GameMissionsContainer>
            </GameRootContainer>
            <Footer isGame={true}/>
        </>
    )
}