import React, { useEffect, useRef } from 'react';
import { Feature, Map, Overlay, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Point from 'ol/geom/Point';
import { defaults as defaultInteractions } from 'ol/interaction';
import { useDispatch } from "react-redux";
import { setCoordinates } from "../slices/resourceSlice";
import { Icon, Style } from "ol/style";
import theme from "../components/styles/theme";
import { Box } from "@mui/material";
import styled from "styled-components";

function ResourcesMap({ adminMode = false, features }) {
    let pointFeatureSet = false;
    const mapElement = useRef();
    const mapRef = useRef();
    const dispatch = useDispatch();

    const vectorSource = new VectorSource();
    const vectorLayer = new VectorLayer({
        source: vectorSource,
    });

    const osmLayer = new TileLayer({
        preload: Infinity,
        source: new OSM(),
    });

    const popupElement = document.createElement('div');
    const popup = new Overlay({
        element: popupElement,
        positioning: 'bottom-center',
        stopEvent: false,
        offset: [0, -50],
    });

    const handleMapClick = function (evt) {
        if(pointFeatureSet) return;
        if(!adminMode) return;
        const coordinate = evt.coordinate;
        dispatch(setCoordinates(coordinate));
        const pointFeature = new Feature({
            geometry: new Point(coordinate),
        });

        const iconStyle = new Style({
            image: new Icon({
                anchor: [0.5, 46],
                anchorXUnits: 'fraction',
                anchorYUnits: 'pixels',
                src: 'https://openlayers.org/en/latest/examples/data/icon.png'
            })
        });


        pointFeatureSet = true;
        pointFeature.setStyle(iconStyle);
        vectorSource.addFeature(pointFeature);
    };

    const featureFactory = (features) => {
        const featuresArray = features.map(feature => {
            if (!feature.emptyMapFeature) {
                return createMapFeature(feature);
            }
            return null;
        }).filter(Boolean);

        vectorSource.addFeatures(featuresArray);
    };

    const createMapFeature = (feature) => {
        const { geometry, properties } = feature;
        const { coordinates } = geometry;

        const pointFeature = new Feature({
            geometry: new Point(coordinates),
        });

        const iconStyle = new Style({
            image: new Icon({
                anchor: [0.5, 46],
                anchorXUnits: 'fraction',
                anchorYUnits: 'pixels',
                src: properties.src || 'https://openlayers.org/en/latest/examples/data/icon.png',
            }),
        });

        pointFeature.setStyle(iconStyle);
        pointFeature.set('popupProperties', properties);

        return pointFeature;
    };

    useEffect(() => {
        if (!mapRef.current && features.length > 0) {
            const initialMap = new Map({
                target: mapElement.current,
                interactions: defaultInteractions({
                    mouseWheelZoom: true,
                }),
                layers: [osmLayer, vectorLayer],
                view: new View({
                    center: [0, 0],
                    zoom: 2,
                }),
                controls: [],
            });

            mapRef.current = initialMap;
            mapRef.current.addOverlay(popup);

            if (adminMode) {
                mapRef.current.on('click', handleMapClick);
            }

            featureFactory(features);

            // Attach event handlers
            mapRef.current.on('click', handleFeatureClick);
            mapRef.current.on('pointermove', handlePointerMove);
            mapRef.current.on('movestart', handleMoveStart);

            return () => {
                // Detach event handlers
                if (mapRef.current) {
                    mapRef.current.un('click', handleMapClick);
                    mapRef.current.un('click', handleFeatureClick);
                    mapRef.current.un('pointermove', handlePointerMove);
                    mapRef.current.un('movestart', handleMoveStart);
                }
            };
        }
    }, [features]);

    const handleFeatureClick = function (evt) {
        const feature = mapRef.current.forEachFeatureAtPixel(evt.pixel, function (feature) {
            return feature;
        });

        if (feature) {
            const { category, description, link, title, type } = feature?.values_?.popupProperties;
            const colors = [theme.palette.primary.main, theme.palette.secondary.main];
            const randomBackground = colors[Math.floor(Math.random() * colors.length)];
            const htmlContent = `<div style="padding: 1rem; max-width: 300px; background-color: ${randomBackground}; color: white">
        <div style="font-size: 18px; font-weight: bold;">${title}</div>
        <div style="font-size: 14px;">${description}</div>
        <div>Type: ${type}</div>
        <div>Category: ${category}</div>
        <div><a href="${link}" target="_blank">See more</a></div>
      </div>`;
            popupElement.innerHTML = htmlContent;
            popup.setPosition(evt.coordinate);
        }
    };

    const handlePointerMove = function (e) {
        const pixel = mapRef.current.getEventPixel(e.originalEvent);
        const hit = mapRef.current.hasFeatureAtPixel(pixel);
        mapRef.current.getTarget().style.cursor = hit ? 'pointer' : '';
    };

    const handleMoveStart = () => {
        popupElement.innerHTML = '';
    };

    const StyledBox = styled(Box)({
        height: '80vh',
        [theme.breakpoints.down('md')]: {
            height: '70vh',
        }
    })

    return (
        <StyledBox style={{ marginTop: '50px', width: '100%' }} ref={mapElement} className="map-container"></StyledBox>
    );
}

export default ResourcesMap;
