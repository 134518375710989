import styled from 'styled-components';
import { Box, Typography } from '@mui/material'

export const VCBackground = styled(Box)({
    background: 'url(/public/assets/bg/6.png)',
    color: 'white',
    '& .MuiSelect-select': {
        color: 'white'
    },
    '& .MuiInputBase-input': {
        color: 'white'
    },
    '& .MuiFormLabel-root': {
        color: 'white'
    },
    '& fieldset': {
        borderColor: 'white'
    },
    '& .MuiButtonBase-root': {
        color: 'white'
    },
})

export const VCRoot = styled(Box)({
    marginTop: '50px',
    display: 'flex',
    // minHeight: 'calc(100vh - 50px)',
    width: '600px',
    margin: '50px auto',
    flexDirection: 'column',
    // padding: '20px 250px 50px 250px'
})

export const VCTitle = styled(Typography)({
    fontSize: '2rem',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '1rem',
})

export const VCFlexBox = styled(Box)({
    display: 'flex',
})

export const VCFlexButtonsContainer = styled(VCFlexBox)({
    justifyContent: 'space-between',
    marginBottom: '1rem',
    marginTop: '1rem',
})