import React from "react";
import { BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./pages/Home.jsx";
import { ThemeProvider } from '@mui/material/styles';
import theme from './components/styles/theme.js';
import { Provider } from "react-redux";
import store from "./../src/app/store.js";

import SimulationGamePage from './pages/SimulationGame.jsx';
import WhyToJoinPage from './pages/WhyToJoin.jsx';
import PublicProfilePage from './pages/PublicProfile.jsx';
import ForumPage from './pages/Forum.jsx';
import WorkManualPage from './pages/WorkManual.jsx';
import MediaManualPage from './pages/MediaManual.jsx';
import InteractiveMapPage from './pages/InteractiveMap.jsx';
import ListOfResourcesPage from './pages/ListOfResources.jsx';
import NewsPage, {NewsDetails as NewsDetailsPage} from './pages/News.jsx';
import EventsPage from './pages/Events.jsx';
import AdminPanelLoginPage from './pages/AdminPanel/AdminPanelLogin.jsx';
import {CssBaseline} from "@mui/material";
import AdminPanelPage from "./pages/AdminPanel/AdminPanelHome.jsx";
import AdminPanelTranslationsPage from "./pages/AdminPanel/AdminPanelTranslations.jsx";
import AdminPanelResourcesPage from "./pages/AdminPanel/AdminPanelResources.jsx";
import AdminPanelGamePage from "./pages/AdminPanel/AdminPanelGame.jsx";
import AdminPanelNewsPage from "./pages/AdminPanel/AdminPanelNews.jsx";
import AdminPanelTeamPage from "./pages/AdminPanel/AdminPanelTeam.jsx";
import LoginPage from "./pages/LoginPage.jsx";
import LogoutPage from "./pages/LogoutPage.jsx";
import ProfilePage from "./pages/UserProfile.jsx";
import SimulationGameMissionLobbyPage from "./pages/SimulationGameMissionLobby.jsx";
import SimulationGamePlayComponentPage from "./components/game/SimulationGame.jsx";
import {ErrorBoundary} from "./components/ErrorBoundary.jsx";
import SimulationGameBuilderPage from "./pages/SimulationGameBuilder.jsx";
import SimulationGameDashboardPage from "./pages/SimulationGameDashboard.jsx";
import SimulationGameMyMissionsPage from "./pages/SimulationGameMyMissions.jsx";
import SimulationGameEditMissionPage from "./pages/SimulationGameEditMission.jsx";
import VirtualClassroomExplorePage from "./pages/VirtualClassroom/VCExplore.jsx";
import VirtualClassroomWatchAndEngagePage from "./pages/VirtualClassroom/VCWatchAndEngage.jsx";
import TermsAndConditionsPage from "./pages/TermsAndConditions.jsx";
import PrivacyPolicyPage from "./pages/PrivacyPolicy.jsx";
import ScrollToTop from "./components/ScrollToTop.jsx";
import ProtectedRoute from "./components/ProtectedRoute.jsx";
import FrameworkCurriculumPage from "./pages/Tools/FrameworkCurriculum.jsx";
import MentorshipProgrammePage from "./pages/Tools/MentorshipProgramme.jsx";
import PolicyRecommendationsPage from "./pages/Tools/PolicyRecommendations.jsx";
import MethodologyPage from "./pages/Tools/Methodology.jsx";
import VCCreateEventPage from "./pages/VirtualClassroom/VCCreateEvent.jsx";
import VCJoinEventPage from "./pages/VirtualClassroom/VCJoinEvent.jsx";
import VCListEditEventsPage from "./pages/VirtualClassroom/VCListEvents.jsx";
import VCEditEventComponent from "./pages/VirtualClassroom/VCEditComponent.jsx";
import {VCViewResource} from "./pages/VirtualClassroom/VCViewResource.jsx";
import {VCViewEvent} from "./pages/VirtualClassroom/VCViewEvent.jsx";
import AdminVCPage from "./pages/AdminPanel/AdminPanelVC.jsx";
import VCPastEventsAndResourcesList from "./pages/VirtualClassroom/VCPastEventsAndUserResourcesList.jsx";
import VCMaterialsList from "./pages/VirtualClassroom/VCMaterialsList.jsx";
import AdminPanelMaterialsPage from "./pages/AdminPanel/AdminPanelVCMaterials.jsx";
import VCViewMaterial from "./pages/VirtualClassroom/VCViewMaterial.jsx";
import VCDigitheca from "./pages/VirtualClassroom/VCDigitheca.jsx";

const App = () => {

    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <ErrorBoundary>
                        <CssBaseline />
                        <ScrollToTop />
                        <Routes>
                            <Route path="/" element={<HomePage />} />

                            <Route path="/login" element={<LoginPage />} />

                            <Route path="/network/why-to-join" element={<WhyToJoinPage />} />
                            <Route path="/network/public-profile" element={<PublicProfilePage />} />
                            <Route path="/network/forum" element={<ForumPage />} />

                            <Route path="/manuals/work-manual" element={<WorkManualPage />} />
                            <Route path="/manuals/media-manual" element={<MediaManualPage />} />

                            <Route path="/simulation-game" element={<SimulationGamePage />} />
                            <Route path="/simulation-game/lobby" element={<SimulationGameMissionLobbyPage />} />
                            <Route path="/simulation-game/play" element={<SimulationGamePlayComponentPage />} />
                            <Route path="/simulation-game/builder" element={<SimulationGameBuilderPage />} />
                            <Route path="/simulation-game/dashboard" element={<SimulationGameDashboardPage />} />
                            <Route path="/simulation-game/my-missions" element={<SimulationGameMyMissionsPage />} />
                            <Route path="/simulation-game/edit-mission" element={<SimulationGameEditMissionPage />} />

                            <Route path="/best-practices/interactive-map" element={<InteractiveMapPage />} />
                            <Route path="/best-practices/list-of-resources" element={<ListOfResourcesPage />} />

                            <Route path="/media-corner/news" element={<NewsPage />} />
                            <Route path="/media-corner/news/:urlPath" element={<NewsDetailsPage />} />
                            <Route path="/media-corner/events" element={<EventsPage />} />

                            <Route path="/admin" element={
                                <ProtectedRoute>
                                    <AdminPanelPage />
                                </ProtectedRoute>
                            }/>
                            <Route path="/admin/auth" element={
                                <ProtectedRoute>
                                    <AdminPanelLoginPage />
                                </ProtectedRoute>
                            }/>
                            <Route path="/admin/translations" element={<AdminPanelTranslationsPage/>} />
                            <Route path="/admin/resources" element={<AdminPanelResourcesPage />} />
                            <Route path="/admin/game" element={<AdminPanelGamePage />} />
                            <Route path="/admin/news" element={<AdminPanelNewsPage />} />
                            <Route path="/admin/team" element={<AdminPanelTeamPage />} />
                            <Route path="/admin/virtual-classroom" element={<AdminVCPage />} />
                            <Route path="/admin/materials" element={<AdminPanelMaterialsPage />} />

                            <Route path="/interact/explore" element={<VirtualClassroomExplorePage />} />
                            <Route path="/interact/watch-and-engage" element={<VirtualClassroomWatchAndEngagePage />} />

                            <Route path="/interact/watch-and-engage/create-event" element={<VCCreateEventPage />} />
                            <Route path="/interact/watch-and-engage/list-edit-events" element={<VCListEditEventsPage />} />
                            <Route path="/interact/watch-and-engage/edit-event/:id" element={<VCEditEventComponent />} />
                            <Route path="/interact/watch-and-engage/view-event/:id" element={<VCViewEvent />} />
                            <Route path="/interact/watch-and-engage/view-resource/:id" element={<VCViewResource />} />
                            <Route path="/interact/watch-and-engage/past-events-and-user-resources" element={<VCPastEventsAndResourcesList />} />
                            <Route path="/interact/explore/" element={<VCMaterialsList />} />
                            <Route path="/interact/explore/view-material/:id" element={<VCViewMaterial />} />
                            <Route path="/interact/explore/digitheca" element={<VCDigitheca />} />

                            <Route path="/info/terms-and-conditions" element={<TermsAndConditionsPage />} />
                            <Route path="/info/privacy-policy" element={<PrivacyPolicyPage />} />

                            <Route path="/tools/framework-curriculum" element={<FrameworkCurriculumPage />} />
                            <Route path="/tools/mentorship-programme" element={<MentorshipProgrammePage />} />
                            <Route path="/tools/policy-recommendations" element={<PolicyRecommendationsPage />} />
                            <Route path="/tools/methodology" element={<MethodologyPage />} />

                            <Route path="/logout" element={<LogoutPage />} />
                            <Route path="/profile" element={<ProfilePage />} />
                        </Routes>
                    </ErrorBoundary>
                </Provider>
            </ThemeProvider>
        </BrowserRouter>
    )
};

export default App;