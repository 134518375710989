import { createSlice } from '@reduxjs/toolkit'

export const gameSlice = createSlice({
    name: 'game',
    initialState: {
        selectedMission: {},
        badgesEarned: 0,
        timePlayed: 0, // set in minutes!
        missionEnded: false,
        questionAnswers: [],
        questionType: '',
    },
    reducers: {
        selectMission: (state, action) => {
            state.selectedMission = action.payload
        },
        addBadgesEarned: (state, action) => {
            state.badgesEarned += action.payload
        },
        removeBadgesEarned: (state, action) => {
            state.badgesEarned -= action.payload
        },
        setBadgesEarned: (state, action) => {
            state.badgesEarned = action.payload
        },
        setMissionEnded: (state, action) => {
            state.missionEnded = action.payload
        },
        addQuestionAnswer: (state, action) => {
            state.questionAnswers.push(action.payload)
        },
        addAnswerToQuestion: (state, action) => {
            switch (state.questionType) {
                case 'fill_in_the_blanks':
                    const fillQuestionObj = state.questionAnswers.find(question => question.question === action.payload.title);

                    if (fillQuestionObj) {
                        const newAnswers = [...fillQuestionObj.answers];

                        // Here, action.payload.answer is an array, so we loop through it to update the answers
                        action.payload.answer.forEach((answer, index) => {
                            newAnswers[index] = answer;
                        });

                        // Update the answers array in state without mutating the original state
                        const questionIndex = state.questionAnswers.findIndex(question => question.question === action.payload.title);
                        return {
                            ...state,
                            questionAnswers: [
                                ...state.questionAnswers.slice(0, questionIndex),
                                {
                                    ...fillQuestionObj,
                                    answers: newAnswers
                                },
                                ...state.questionAnswers.slice(questionIndex + 1)
                            ]
                        };
                    }
                    return state;
                default:
                    console.log('default')
                    console.log(action.payload)
                    const questionObj = state.questionAnswers.find(question => question.question === action.payload.title);
                    console.log(questionObj)
                    if (questionObj) {
                        const answerExists = questionObj.answers.find(answer => answer === action.payload.answer);
                        if (answerExists) return
                        questionObj.answers.push(action.payload.answer);
                    }
                    break;
            }
        },
        removeAnswerFromQuestion: (state, action) => {
            const questionObj = state.questionAnswers.find(question => question.question === action.payload.title);
            if (questionObj) {
                const answerExists = questionObj.answers.find(answer => answer === action.payload.answer);
                if (answerExists) {
                    const answerIndex = questionObj.answers.indexOf(action.payload.answer);
                    questionObj.answers.splice(answerIndex, 1);
                }
            }
        },
        setTimePlayedInMinutes: (state, action) => {
            state.timePlayed = action.payload
        },
        setQuestionType: (state, action) => {
            state.questionType = action.payload
        },
        resetState: (state) => {
            return {
                selectedMission: {},
                badgesEarned: 0,
                timePlayed: 0,
                missionEnded: false,
                questionAnswers: [],
            }
        }
    },
})

export const {
    selectMission,
    addBadgesEarned,
    removeBadgesEarned,
    setBadgesEarned,
    setMissionEnded,
    addQuestionAnswer,
    addAnswerToQuestion,
    removeAnswerFromQuestion,
    setTimePlayedInMinutes,
    resetState,
    setQuestionType,
} = gameSlice.actions
export default gameSlice.reducer
