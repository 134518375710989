import { Box } from '@mui/material'
import styled from 'styled-components'
import theme from '../theme'

export const RootContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem 4rem',
    [theme.breakpoints.down('md')]: {
        padding: '2rem 1rem',
    },
    margin: '0 auto',
    backgroundColor: theme.palette.primary.main,
})

export const QuestionWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
})